import React, {useEffect, useState} from "react";
import {getServerAsyncRuns} from "../../dao";
import {handleErrorLoading, parseDateTimeSec, setBrowserTabText} from "../../helpers/Functions";
import Loader from "../../components/overlays/Loader";
import ErrorAlertFullscreen from "../../components/overlays/ErrorAlertFullscreen";
import PageContainer from "../../layout/PageContainer";
import Table from "react-bootstrap/Table";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import NoYesIndicator from "../../components/visual/NoYesIndicator";
import FormField from "../../components/form/FormField";
import Button from "react-bootstrap/Button";
import {isMobileDevice} from "../../helpers/Device";


const ServerAsyncRuns = () => {
  const [data, setData] = useState([]);
  const [date, setDate] = useState((new Date()).toISOString().split('T')[0]);
  const [errorRemote, setErrorRemote] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    getServerAsyncRuns(date)
      .then((response) => {
        if (!response.ok) {
          setErrorRemote(handleErrorLoading(response));
          return [];
        }
        return response.json();
      })
      .then((response) => {
        setData(response);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setErrorRemote("Připojení neúspěšné...");
      })
  }, [date]);

  if (errorRemote !== "")
    return <ErrorAlertFullscreen error={errorRemote} />;
  if (loading)
    return <Loader />;


  setBrowserTabText('Běžící procesy');
  return (
    <PageContainer>
      <Row>
        <Col md>
          <h1>Běžící procesy</h1>
        </Col>
        <Col md>
          <Row>
            <Col>
              <FormField label={""} type={"date"} value={date} setValue={setDate} controlId={"dateShown"}/>
            </Col>
            <Col xs="auto">
              <Button variant="outline-primary" onClick={() => setDate((new Date()).toISOString().split('T')[0])}>Dnes</Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <EmailQueueTable />
    </PageContainer>
  )

  function EmailQueueTable() {
    const rows = [];

    for (const i in data?.data) {
      const element = data?.data[i];
      rows.push(
        <tr key={i}>
          <td>{element?.id}</td>
          <td>{element?.run_type}{isMobileDevice() && <><br/><i>{element?.target}</i></>}</td>
          {!isMobileDevice() && <>
            <td>{element?.target}</td>
            <td className="align-center"><NoYesIndicator condition={!element?.running} allBlack={true}/></td>
          </>}
          <td>{parseDateTimeSec(element?.started, false)}</td>
          <td>{parseDateTimeSec(element?.ended, false)}</td>
          <td>{element?.duration}</td>
        </tr>);

    }

    if (rows.length > 0) {
      return (
        <>
          <Table size="sm" striped bordered hover responsive>
            <thead>
            <tr>
              <th>ID</th>
              <th>T</th>
              {!isMobileDevice() && <>
                <th>Cíl</th>
                <th title="completed">C</th>
              </>}
              <th>S</th>
              <th>E</th>
              <th>[mm:ss.SSS]</th>
            </tr>
            </thead>
            <tbody>
            {rows}
            </tbody>
          </Table>
          <h4>Typové kódy:</h4>
          <p className="mb-0">12 - sync akcí 1/den</p>
          <p className="mb-0">13 - sync akcí 1/hod</p>
          <p className="mb-0">21 - POST předběžných přihl.</p>
          <p className="mb-0">22 - POST čekajících přihl.</p>
          <p className="mb-0">31 - aktualizace rankingu</p>
          <p className="mb-0">41 - import plateb z časového rozsahu</p>
          <p className="mb-0">42 - import plateb od časové značky</p>
          <p className="mb-0">52 - přepočet všech členů</p>
          <p className="mb-0">53 - přepočet vybraných členů</p>
          <p className="mb-0">61 - resend uvítacího emailu</p>
          <p className="mb-0">81 - data jednoho účtu do Orisu</p>
          <p className="mb-0">82 - data všech účtů do Orisu</p>
          <p className="mb-0">91 - historie jednoho účtu z Orisu</p>
          <p className="mb-0">92 - historie všech účtů z Orisu</p>
        </>
      );
    }
    return (<p><i>Žádné procesy zaznamenány</i></p>);
  }
}

export default ServerAsyncRuns;