import React, {useEffect, useState} from "react";
import PageContainer from "../../layout/PageContainer";
import {getPostDashboard} from "../../dao";
import {
  handleErrorLoading,
  parseDateBreakYear, parseDateTime,
  parseTime,
  replaceTabsWithSpaces,
  setBrowserTabText,
} from "../../helpers/Functions";
import ErrorAlertFullscreen from "../../components/overlays/ErrorAlertFullscreen";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faBell,
  faBoxArchive,
  faPencil,
  faThumbtack
} from "@fortawesome/free-solid-svg-icons";
import FormCheckbox from "../../components/form/FormCheckbox";
import LoadingIcon from "../../components/overlays/LoadingIcon";
import WarningCard from "../../components/overlays/WarningCard";
import PageSwitch from "../../components/visual/PageSwitch";
import {Badge, Card} from "react-bootstrap";
import {labels} from "../../themeLabels";
import {faCalendarDays} from "@fortawesome/free-regular-svg-icons";
import Modal from "react-bootstrap/Modal";


const PostDashboard = () => {
  const [data, setData] = useState({posts: []});
  const [errorRemote, setErrorRemote] = useState("");
  const [loading, setLoading] = useState(true);
  const [archived, setArchived] = useState(false);
  const [page, setPage] = useState(1);
  const [postDetail, setPostDetail] = useState(null);

  useEffect(() => {
    setLoading(true);
    getPostDashboard(archived)
      .then((response) => {
        if (!response.ok) {
          setErrorRemote(handleErrorLoading(response));
          return {posts: [], unseen_posts: 0};
        }
        return response.json()
      })
      .then((response) => {
        setData(response);
        localStorage.setItem('unseenPosts', response?.unseen_posts);
        setLoading(false);
        setPage(1);
      })
      .catch((err) => {
        console.log(err);
        setErrorRemote("Připojení neúspěšné...");
      })
  }, [archived]);

  if (errorRemote !== "")
    return <ErrorAlertFullscreen error={errorRemote} />;
  const postsPerPage = 10;

  const UnseenBadge = () => {
    if (data?.unseen_posts || data?.unseen_posts === 0) {
      if (data?.unseen_posts > 0)
        return <Badge pill bg="warning">{data?.unseen_posts}</Badge>
      else
        return <Badge pill bg="secondary">0</Badge>;
    }
  }

  setBrowserTabText('Klubová nástěnka');
  return (
    <PageContainer>
      <Row className="align-items-center mb-2">
        <Col xs="auto">
          <h1>Klubová nástěnka <UnseenBadge /></h1>
        </Col>
        {labels.Theme === "PGP" && <Col>
          <Row>
            <Col className="text-end">
              <Button href={labels.UrlCalendar} className="tapis-95 text-nowrap"><FontAwesomeIcon icon={faCalendarDays}/> <b>{labels.homeSection}</b></Button>
            </Col>
          </Row>
        </Col>}
      </Row>
      {data.staff && <Row className="mb-2">
        <Col>
          <FormCheckbox type={"switch"} label={"Archivované"} value={archived} setValue={setArchived}/>
        </Col>
        <Col className="text-end">
          <Button href={"/klub/nastenka/pridat"} variant="outline-primary">+ Přidat</Button>
        </Col>
      </Row>}
      <PostsSection/>
      <Modal show={postDetail != null} onHide={()=> setPostDetail(null)}>
        <Modal.Header closeButton>
          <Modal.Title>{postDetail?.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="post-dashboard" dangerouslySetInnerHTML={{__html: replaceTabsWithSpaces(postDetail?.content)}}/>
          <hr/>
          <Row>
            <Col xs="auto">
              <i>{parseDateTime(postDetail?.published)}</i>
            </Col>
            <Col>
              <Row>
                <Col className="text-end text-nowrap tapis-80">
                  <i>{postDetail?.can_edit && <a href={`/klub/prispevek/${postDetail?.id}/upravit`}><FontAwesomeIcon icon={faPencil} className="link-like"/></a>} {postDetail?.created_by?.reg_number} – {postDetail?.created_by?.full_name}</i>
                </Col>
              </Row>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </PageContainer>
  )

  function PostsSection() {
    if (loading)
      return <LoadingIcon />;
    if (data?.posts.length === 0)
      return <WarningCard text={"Žádné příspěvky nebyly přidány"} />;
    return (<>
      <PageSwitch page={page} setPage={setPage} rowsPerPage={postsPerPage} rowCount={data.posts.length} />
      <div className="post-container">
        <PostRows />
      </div>
      <PageSwitch page={page} setPage={setPage} rowsPerPage={postsPerPage} rowCount={data.posts.length} />
    </>)

    function PostRows() {
      const rows = [];
      const postsPage = data.posts.slice((page - 1) * postsPerPage, page * postsPerPage)
      for (const i in postsPage) {
        const post = postsPage[i];
        const PostTitleIcons = ({condition, icon}) => {
          if (condition)
            return <>{icon}&nbsp;</>;
          return <></>;
        }
        const PostTitle = () => {
          return <a href={"/klub/prispevek/" + post.id} className="hide-link">
            <h3 className="mb-0">
              <PostTitleIcons condition={post?.is_unseen} icon={<FontAwesomeIcon icon={faBell} color="red" title="Nový příspěvek"/>}/>
              <PostTitleIcons condition={post.pinned} icon={<FontAwesomeIcon icon={faThumbtack}/>}/>
              <PostTitleIcons condition={post.archived} icon={<FontAwesomeIcon icon={faBoxArchive}/>}/>
              {post.title}
            </h3>
          </a>;
        }
        rows.push(<div className="post-card-div" key={post.id}>
          <Card className="post-card">
            <Card.Body>
              <div className="post-content mb-1">
                <Row className="text-center align-content-center bg-primary ms-0 me-0 mt-0 mb-3 p-2">
                  <a href={"/klub/prispevek/" + post.id} className="hide-link tapis-85 p-1" style={{display: 'block', color: 'white'}}>
                    <b>{parseDateBreakYear(post.published)}</b> | <b>{parseTime(post.published.split('T')[1])}</b>
                  </a>
                </Row>
                <Row className="mb-3">
                  <Col xs="auto"><PostTitle /></Col>
                  <Col>
                    <Row>
                      <Col className="text-end text-nowrap tapis-80">
                        <i>{post.can_edit && <a href={`/klub/prispevek/${post.id}/upravit`}><FontAwesomeIcon icon={faPencil} className="link-like"/></a>} {post.created_by.reg_number} – {post.created_by.full_name}</i>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="ps-3 pe-3" onClick={() => setPostDetail(post)}>
                  <div className="post-dashboard" dangerouslySetInnerHTML={{__html: replaceTabsWithSpaces(post.content)}}/>
                </Row>
              </div>
            </Card.Body>
          </Card>
        </div>)
      }
      return rows;
    }
  }
}

export default PostDashboard;