import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {handleErrorLoading, parseDateTime, setBrowserTabText} from "../../helpers/Functions";
import PageContainer from "../../layout/PageContainer";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {getEmailSummary} from "../../dao";
import {labels} from "../../themeLabels";
import Table from "react-bootstrap/Table";
import Loader from "../../components/overlays/Loader";
import ErrorAlertFullscreen from "../../components/overlays/ErrorAlertFullscreen";
import {faEye} from "@fortawesome/free-solid-svg-icons";
import WarningCard from "../../components/overlays/WarningCard";
import PageHeading from "../../layout/PageHeading";


const EmailSummary = () => {
  const {eventId} = useParams();
  const [data, setData] = useState({});
  const [errorRemote, setErrorRemote] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getEmailSummary(eventId)
      .then((response) => {
        if (!response.ok) {
          setErrorRemote(handleErrorLoading(response));
          return {};
        }
        return response.json();
      })
      .then((response) => {
        setData(response);
        setLoading(false);
      })
  }, [eventId]);

  if (errorRemote !== "")
    return <ErrorAlertFullscreen error={errorRemote} />;
  if (loading)
    return <Loader />;

  if (!eventId)
    setBrowserTabText('Klubové emaily');
  else
    setBrowserTabText('Emaily | ' + data?.event?.title);
  return (
    <PageContainer>
      <PageHeading heading={!eventId ? "Klubové emaily" : "Emaily: " + data?.event?.title} label={!eventId ? "Admin" : "Admin akce"}
                   link={!eventId ? `/admin` : `/akce/${eventId}/admin`} />
      <Row className="mb-2">
        <Col className="text-end">
          <Button href={!eventId ? `/admin/emaily/napsat` : `/akce/${eventId}/emaily/napsat`}>Napsat</Button>
        </Col>
      </Row>
      <EmailTable />
    </PageContainer>
  );

  function EmailTable() {
    let rows = [];
    for (const i in data?.emails) {
      const email = data?.emails[i];
      rows.push(<tr key={i}>
        <td>{parseDateTime(email?.created)}</td>
        <td>{email?.subject}</td>
        <td>{email?.recipients}</td>
        <td className="td-w-icon"><a href={!eventId ? `/admin/emaily/${email?.id}` : `/akce/${eventId}/emaily/${email?.id}`}><FontAwesomeIcon icon={faEye} /></a></td>
      </tr>);
    }

    if (rows.length === 0)
      return <WarningCard text={"Žádné emaily"} marginTop={"2%"} />

    return <Table bordered striped hover={labels.CondElemTableHover} responsive>
      <thead>
      <tr>
        <th/>
        <th>Předmět</th>
        <th>Příjemci</th>
        <th/>
      </tr>
      </thead>
      <tbody>
      {rows}
      </tbody>
    </Table>
  }
}

export default EmailSummary;