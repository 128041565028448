import PostEditor from "../../components/parts/PostEditor";
import React, {useEffect, useState} from 'react';
import PageContainer from "../../layout/PageContainer";
import {useNavigate, useParams} from "react-router-dom";
import {handleErrorLoading, handleErrorSubmit, replaceTabsWithSpaces, setBrowserTabText} from "../../helpers/Functions";
import FormField from "../../components/form/FormField";
import {deletePost, getPost, postPost, putPost} from "../../dao";
import ErrorAlert from "../../components/overlays/ErrorAlert";
import SuccessAlert from "../../components/overlays/SuccessAlert";
import Loader from "../../components/overlays/Loader";
import ErrorAlertFullscreen from "../../components/overlays/ErrorAlertFullscreen";
import Row from "react-bootstrap/Row";
import FormCheckbox from "../../components/form/FormCheckbox";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faQuestionCircle, faTrash} from "@fortawesome/free-solid-svg-icons";
import Modal from "react-bootstrap/Modal";


const PostAddEdit = () => {
  const { postId } = useParams();
  const [postTitle, setPostTitle] = useState("");
  const [postContent, setPostContent] = useState("");
  const [postArchived, setPostArchived] = useState(false);
  const [postPinned, setPostPinned] = useState(false);
  const [error, setError] = useState("");
  const [errorRemote, setErrorRemote] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (postId != null) {
      getPost(postId)
        .then((response) => {
          if (!response.ok) {
            setErrorRemote(handleErrorLoading(response));
            return [];
          }
          return response.json()
        })
        .then((response) => {
          setPostTitle(response?.title);
          setPostContent(replaceTabsWithSpaces(response?.content));
          setPostPinned(response?.pinned);
          setPostArchived(response?.archived);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setErrorRemote("Připojení neúspěšné...");
        })
    } else {
      setLoading(false);
    }
  }, [postId]);

  const handleSavePost = (content) => {
    setProcessing(true);
    setPostContent(content);
    if (postId == null) {
      postPost(postTitle, content, postPinned, postArchived)
        .then((response) => {
          if(response.ok)
            setSuccess("Příspěvek vytvořen!");
          else
            setError(handleErrorSubmit(response, "Nezdařilo se vytvořit"));
          setProcessing(false);
        })
    } else {
      putPost(postId, postTitle, content, postPinned, postArchived)
        .then((response) => {
          if(response.ok)
            setSuccess("Příspěvek upraven");
          else
            setError(handleErrorSubmit(response, "Nezdařilo se upravit"));
          setProcessing(false);
        })
    }
  };

  const handleDeletePost = () => {
    setConfirmDelete(false);
    deletePost(postId)
      .then((response) => {
        if(response.ok)
          setSuccess("Příspěvek smazán");
        else
          setError(handleErrorSubmit(response, "Nezdařilo se smazat"));
        setProcessing(false);
      })
  }

  if (errorRemote !== "")
    return <ErrorAlertFullscreen error={errorRemote} />;
  if (loading)
    return <Loader />;


  if (postId == null)
    setBrowserTabText('Přidat příspěvek');
  else
    setBrowserTabText('Upravit příspěvek: ' + postTitle);
  return (
    <PageContainer>
      <h1>{postId == null ? "Přidat příspěvek" : "Upravit příspěvek: " + postTitle}</h1>
      <Row className="align-items-end">
        <Col>
          <FormField label={"Titulek"} value={postTitle} setValue={setPostTitle} valueLimit={256} digits={"slash"} controlId={"postTitle"}/>
        </Col>
        {postId != null && <Col xs="auto" className="mb-3">
          <Button variant="secondary" onClick={() => setConfirmDelete(true)}><FontAwesomeIcon icon={faTrash}/></Button>
        </Col>}
      </Row>
      <Row>
        <Col>
          <FormCheckbox label={"Připnuto"} value={postPinned} setValue={setPostPinned} controlId={"postPinned"}/>
        </Col>
        <Col>
          <FormCheckbox label={"Archivováno"} value={postArchived} setValue={setPostArchived} controlId={"postArchived"}/>
        </Col>
      </Row>
      <p className="mb-1">Obsah:</p>
      <PostEditor initialContent={postContent} onSave={handleSavePost} buttonText={postId == null ? "Publikovat" : "Uložit"} buttonDisabled={processing} />
      <Modal show={confirmDelete} onHide={() => setConfirmDelete(false)}>
        <Modal.Header closeButton>
          <FontAwesomeIcon icon={faQuestionCircle} size='3x' bounce/> &nbsp;
          <Modal.Title>Skutečně smazat příspěvek?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Tato akce je nevratná.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setConfirmDelete(false)}>Zavřít</Button>
          <Button variant="primary" onClick={handleDeletePost}>Smazat</Button>
        </Modal.Footer>
      </Modal>
      <ErrorAlert error={error} handleClose={()=> setError("")}/>
      <SuccessAlert title={success} handleClose={()=> navigate("/klub/nastenka")}/>
    </PageContainer>
  );
}

export default PostAddEdit;