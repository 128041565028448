import Button from "react-bootstrap/Button";
import Form from 'react-bootstrap/Form';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
  editEvent,
  getQuestionsEdit,
  postPutQuestion,
  putMoveQuestion,
  deleteQuestion,
} from "../../dao";
import React, { useState, useEffect } from 'react';
import {
  faArrowsUpDown,
  faCircleDot, faFont,
  faPencil,
  faQuestionCircle, faShuffle,
  faSquareCheck,
  faTrash, faTriangleExclamation
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ErrorAlert from "../../components/overlays/ErrorAlert";
import SuccessAlert from "../../components/overlays/SuccessAlert";
import {handleErrorLoading, handleErrorSubmit, IndicesArray} from "../../helpers/Functions";
import FormField from "../../components/form/FormField";
import FormSelectDict from "../../components/form/FormSelectDict";
import {
  PunchingOptions, QuestionTypeOptions,
  TernaryOptionsTransport
} from "../../helpers/Constants";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import ErrorAlertFullscreen from "../../components/overlays/ErrorAlertFullscreen";
import useUserData from "../../helpers/Auth";
import {Link} from "react-router-dom";
import FormCoordinates from "../../components/form/FormCoordinates";
import MapPositionSelect from "../../components/parts/MapPositionSelect";
import FormCheckbox from "../../components/form/FormCheckbox";
import Alert from "react-bootstrap/Alert";
import FormFieldCategory from "../../components/form/FormFieldCategory";
import CategoryEditor from "../../components/parts/CategoryEditor";
import FormSelectArray from "../../components/form/FormSelectArray";
import EventClubOffersEdit from "../../components/parts/EventClubOffersEdit";
import EventDangerZone from "../../components/parts/EventDangerZone";
import {labels} from "../../themeLabels";

const EditCamp = ({eventId, event}) => {
  const { userData, setUserData } = useUserData();
  const [questionData, setQuestionData] = useState({});
  const [error, setError] = useState("");
  const [errorRemote, setErrorRemote] = useState("");
  const [success, setSuccess] = useState("");
  const [showMap, setShowMap] = useState(false);
  const [editCategories, setEditCategories] = useState(false);
  const [invertEventVariant, setInvertEventVariant] = useState(false);

  const [title, setTitle] = useState(event.title);
  const [date, setDate] = useState(event.date);
  const [dateTo, setDateTo] = useState(event.date_to);
  const [place, setPlace] = useState(event.place);

  const [info, setInfo] = useState(event.info);
  const [eventInfo, setEventInfo] = useState(event.event_info);
  const [eventWarning, setEventWarning] = useState(event.event_warning);

  const [classData, setClassData] = useState(event?.class_data);
  const [termFirst, setTermFirst] = useState(event.term_first);
  const [allowLate, setAllowLate] = useState(event.allow_late);
  const [allowAnonymous, setAllowAnonymous] = useState(event?.allow_anonym);
  const [visible, setVisible] = useState(event.visible);
  const [punching, setPunching] = useState(event.punching);

  const [GPSlong, setGPSlong] = useState(event?.GPS_longitude !== undefined ? event?.GPS_longitude : '');
  const [GPSlat, setGPSlat] = useState(event?.GPS_latitude !== undefined ? event?.GPS_latitude : '');
  const [transport, setTransport] = useState(event.transport);
  const [transportInfo, setTransportInfo] = useState(event.transport_info);
  const [highlighted, setHighlighted] = useState(String(event.highlighted));

  const [questionToEditID, setQuestionToEditID] = useState(null);
  const [questionToEdit, setQuestionToEdit] = useState(null);
  const [questionToMove, setQuestionToMove] = useState(null);
  const [questionToRemove, setQuestionToRemove] = useState(null);

  const [questionIndex, setQuestionIndex] = useState(0);
  const [questionTitle, setQuestionTitle] = useState("");
  const [questionNote, setQuestionNote] = useState("");
  const [questionRequired, setQuestionRequired] = useState(false);
  const [questionOtherChoice, setQuestionOtherChoice] = useState(false);
  const [questionChoices, setQuestionChoices] = useState("");
  const [questionType, setQuestionType] = useState("0");

  useEffect(() => {
    loadQuestions();
  }, []);

  function loadQuestions(){
    getQuestionsEdit(eventId)
      .then((response) => {
        if (response.ok)
          return response.json();
        setErrorRemote(handleErrorLoading(response));
      })
      .then((response) => {
        setQuestionData(response);
      });
  }

  let handleSubmit = async (e) => {
    e.preventDefault();
    const formData = {
      "title": title,
      "date": date,
      "date_to": dateTo,
      "place" : place,
      "info": info,
      "event_info": eventInfo,
      "event_warning": eventWarning,
      "punching": punching,
      "transport": transport,
      "transport_info": transportInfo,
      "visible": visible,
      "term_first": termFirst,
      "allow_late" : allowLate,
      "allow_anonym": allowAnonymous,
      "highlighted": highlighted,
      "class_data": classData,
      "GPS_longitude": GPSlong,
      "GPS_latitude": GPSlat
    }
    const res = await editEvent(eventId, formData);
    if(res.ok)
      setSuccess("Akce upravena");
    else
      setError(handleErrorSubmit(res, "Nepodařilo se upravit"));
  };

  const isCampEvent = event?.discipline === "SO";

  if (errorRemote !== "")
    return <ErrorAlertFullscreen error={errorRemote} />;

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md>
            <FormField label={"Název"} value={title} setValue={setTitle} valueLimit={512} controlId={"title"}/>
          </Col>
          {((isCampEvent && !invertEventVariant) || (!isCampEvent && invertEventVariant)) ? <>
            <Col xs md={2}>
              <FormField label={"Datum od"} type={"date"} value={date} setValue={setDate} controlId={"dateFrom"}/>
            </Col>
            <Col xs md={2}>
              <FormField label={"Datum do"} type={"date"} value={dateTo} setValue={setDateTo} controlId={"dateTo"}/>
            </Col>
          </> : <>
            <Col md>
              <FormField label={"Datum"} type={"date"} value={date} setValue={setDate} controlId={"date"}/>
            </Col>
          </>}
          <Col md>
            <Row className="align-items-end">
              <Col>
                <FormField label={"Termín přihlášek"} type={"datetime-local"} value={termFirst} setValue={setTermFirst} controlId={"termFirst"}/>
              </Col>
              <Col xs="auto">
                <Button variant="outline-secondary" className="mb-3" title="Soustředění<->Jiná" onClick={() => setInvertEventVariant(!invertEventVariant)}>
                  <FontAwesomeIcon icon={faShuffle}/>
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col lg>
            <FormField label={"Místo"} value={place} setValue={setPlace} valueLimit={512} controlId={"place"}/>
          </Col>
          <Col lg>
            <FormCoordinates GPSlat={GPSlat} GPSlong={GPSlong} setGPSlat={setGPSlat} setGPSlong={setGPSlong} setShowMap={setShowMap}/>
          </Col>
          <Col lg>
            <FormSelectArray label={"Admin - vybraná otázka"} value={highlighted} setValue={setHighlighted} options={IndicesArray(questionData.count)}
                             help={<FontAwesomeIcon icon={faQuestionCircle} title="Otázka s tímto číslem bude zobrazena v sekci Administrace akce"/>}
                             controlId={"highlighted"}/>
          </Col>
        </Row>
        <Row>
          <Col md>
            <FormCheckbox label={"Viditelná pro běžné uživatele"} value={visible} setValue={setVisible} controlId={"visible"}
                          tooltip={"Viditelnou akci vidí v kalendáři všichni uživatelé, skrytou pouze uživatelé s právem na úpravy akcí po přepnutí filtru Zrušené a skryté na Ano"}/>
          </Col>
          <Col md>
            <FormCheckbox label={"Jednorázové přihlášky možné"} value={allowAnonymous} setValue={setAllowAnonymous} controlId={"allowAnonymous"}
                          tooltip={"Na akci se mohou speciálním formulářem přihlašovat i lidé bez přístupu do systému, po vyplnění obdrží email s odkazem pro úpravy"}/>
          </Col>
          <Col md>
            {userData.administrate && <FormCheckbox label={"Přihlášky trenérů po termínu"} value={allowLate} setValue={setAllowLate} controlId={"allowLate"}
                                                    tooltip={"Lidé s právem přihlašovat všechny mohou přidávat a měnit přihlášky i po skončení termínu"}/>}
          </Col>
          <hr/>
        </Row>
        <Row>
          <Col lg>
            <FormField label={"Info text"} value={info} setValue={setInfo} valueLimit={2048} controlId={"info"}
                       help={"Text umístěný na stránce akce v kartě Informace"} />
          </Col>
          <Col lg>
            <FormField label={"Info hláška"} value={eventInfo} setValue={setEventInfo} valueLimit={2048} controlId={"eventInfo"}
                       help={"Text umístěný nahoře na stránce akce na modrém podkladu (styl informace)"} />
          </Col>
          <Col lg>
            <FormField label={"Varovná hláška"} value={eventWarning} setValue={setEventWarning} valueLimit={2048} controlId={"eventWarning"}
                       help={"Text umístěný nahoře na stránce akce na žlutém podkladu (styl varování)"} />
          </Col>
        </Row>
        {((isCampEvent && !invertEventVariant) || (!isCampEvent && invertEventVariant)) && <Row>
          <Col md={3}>
            <FormSelectDict label={"Ražení"} value={punching} setValue={setPunching} options={PunchingOptions} controlId={"punching"}/>
          </Col>
          <Col>
            <FormFieldCategory classData={classData} setEditClasses={setEditCategories} controlId={"categoryEdit"}/>
          </Col>
        </Row>}
        <Row>
          <hr/>
          <Col md={4}>
            <FormSelectDict label={"Klubová doprava"} value={transport} setValue={setTransport} options={TernaryOptionsTransport} controlId={"transport"}/>
          </Col>
          <Col md>
            <FormField label={"Info o dopravě"} value={transportInfo} setValue={setTransportInfo} valueLimit={128} controlId={"transportInfo"}/>
          </Col>
        </Row>
        <Row>
        <Col className="text-end mb-3">
            <Button variant="primary" type="submit"><b>{labels.ButtonEventUpdate}</b></Button>
          </Col>
          <hr className="mb-1"/>
          <hr className="mt-1"/>
        </Row>
      </Form>
      <Row className="align-items-center">
        <Col xs="auto">
          <h3>Formulář pro účastníky</h3>
        </Col>
        <Col className="mb-3 text-end">
          <Link to={`/akce/${eventId}/kopirovat`}><Button variant="outline-secondary">Zkopírovat formulář</Button></Link>
        </Col>
        <Col xs="auto" className="mb-3">
          <Button variant="secondary" onClick={() => {
            setQuestionToEdit({});
            setQuestionToEditID(-1);
            setQuestionTitle("");
            setQuestionNote("");
            setQuestionRequired(!questionData?.submissions > 0);
            setQuestionType("0");
            setQuestionOtherChoice(false);
            setQuestionChoices("");
          }}>+ Přidat otázku</Button>
        </Col>
      </Row>
      {questionData?.submissions > 0 &&
        <Alert variant="warning"><FontAwesomeIcon icon={faTriangleExclamation}/> <i>Akce již má odeslaný alespoň jeden přihláškový formulář, některá nastavení
          otázek je proto zakázáno editovat</i></Alert>}
      <QuestionTable/>
      <Row>
        <hr/>
        <EventClubOffersEdit eventId={eventId}/>
      </Row>
      {userData?.administrate && <Row>
        <EventDangerZone event={event} />
      </Row>}
      <CategoryEditor classData={classData} setClassData={setClassData} editClasses={editCategories} setEditClasses={setEditCategories}/>
      <Modal show={questionToRemove != null} onHide={() => setQuestionToRemove(null)}>
        <Modal.Header closeButton>
          <FontAwesomeIcon icon={faQuestionCircle} size='3x' bounce/> &nbsp;
          <Modal.Title>Smazat otázku?</Modal.Title>
        </Modal.Header>
        <Modal.Body><b>#{questionToRemove?.index}</b> {questionToRemove?.title}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setQuestionToRemove(null)}>Zavřít</Button>
          <Button variant="primary" onClick={() => removeQuestion(questionToRemove.id)}>Smazat</Button>
        </Modal.Footer>
      </Modal>
      <Modal show={questionToMove != null} onHide={() => setQuestionToMove(null)}>
        <Modal.Header closeButton>
          <FontAwesomeIcon icon={faQuestionCircle} size='3x' bounce/> &nbsp;
          <Modal.Title>Přesunout otázku?</Modal.Title>
        </Modal.Header>
        <Modal.Body><b>#{questionToMove?.index}</b> {questionToMove?.title}
          <hr/>
          <FormSelectArray label={"Nová pozice"} value={questionIndex} setValue={setQuestionIndex} options={IndicesArray(questionData.count)}
                           controlId={"qIndex"}/>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setQuestionToMove(null)}>Zavřít</Button>
          <Button variant="primary" onClick={() => moveQuestion(questionToMove.id)}>Přesunout</Button>
        </Modal.Footer>
      </Modal>
      <Modal show={questionToEditID != null} onHide={() => setQuestionToEditID(null)}>
        <Modal.Header closeButton>
          <Modal.Title>{questionToEditID === -1 ? "Přidat" : "Editovat"} otázku:</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <FormField label={"Otázka"} value={questionTitle} setValue={setQuestionTitle} valueLimit={256} controlId={"qTitle"}/>
            <FormField label={"Poznámka k otázce"} value={questionNote} setValue={setQuestionNote} valueLimit={256} controlId={"qNote"}/>
            <Row>
              <Col>
                <FormCheckbox label={"Povinná"} value={questionRequired} setValue={setQuestionRequired}
                              disabled={questionData?.submissions > 0 && !questionToEdit?.required} controlId={"qRequired"}/>
                {questionType !== "0" && <FormCheckbox label={"Odpověď Jiné"} value={questionOtherChoice} setValue={setQuestionOtherChoice}
                                                       disabled={questionData?.submissions > 0 && !questionToEdit?.other_choice} controlId={"qRequired"}/>}
              </Col>
              <Col>
                <FormSelectDict label={"Typ"} options={QuestionTypeOptions} value={questionType} setValue={setQuestionType} controlId={"qType"}/>
              </Col>
            </Row>
            {questionType !== "0" &&
              <FormField label={"Možnosti oddělené středníkem ; (přejmenování Old>New)"} value={questionChoices} setValue={setQuestionChoices} valueLimit={1024}
                         controlId={"qChoices"}/>}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setQuestionToEditID(null)}>Zavřít</Button>
          <Button variant="primary" onClick={() => addEditQuestion(questionToEditID)}>{questionToEditID === -1 ? "Přidat" : "Změnit"}</Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showMap} onHide={() => setShowMap(false)}>
        <Modal.Header closeButton>
          <Modal.Body>Vyber místo kliknutím do mapy</Modal.Body>
        </Modal.Header>
        <MapPositionSelect GPSlat={GPSlat} GPSlong={GPSlong} setGPSlat={setGPSlat} setGPSlong={setGPSlong} setShowMap={setShowMap}/>
      </Modal>
      <ErrorAlert error={error} handleClose={() => setError("")}/>
      <SuccessAlert title={success} handleClose={() => setSuccess("")}/>
    </>
  );

  function addEditQuestion(id) {
    const method = id === -1 ? "POST" : "PUT";
    const resource = id === -1 ? "event=" + eventId : "id=" + id;
    postPutQuestion(method, resource, questionTitle, questionNote, questionRequired, questionType === "2", questionOtherChoice, questionChoices)
      .then((res) => {
        if (res.ok) {
          setSuccess(id === -1 ? "Přidáno" : "Upraveno");
          loadQuestions();
        } else {
          setError(handleErrorSubmit(res, "Nezdařilo se " + (id === -1 ? "přidat" : "upravit")));
        }
      })
    setQuestionToEditID(null);
  }

  function moveQuestion(id){
    putMoveQuestion(id, questionIndex)
      .then((res) => {
        if (res.ok) {
          setSuccess("Přesunuto na #" + questionIndex);
          loadQuestions();
        } else {
          setError(handleErrorSubmit(res, "Nezdařilo se přesunout"));
        }
      })
    setQuestionToMove(null);
  }

  function removeQuestion(id){
    deleteQuestion(id)
      .then((res) => {
        if (res.ok) {
          setSuccess("Smazáno");
          loadQuestions();
        } else {
          setError(handleErrorSubmit(res, "Nezdařilo se smazat"));
        }
      })
    setQuestionToRemove(null);
  }

  function QuestionTable(){
    if(questionData.data === undefined){
      return(<p><i>Žádné otázky</i></p>)
    }
    if(questionData.data.length < 1){
      return(<p><i>Žádné otázky</i></p>)
    }
    const rows = [];
    const QuestionTypeMarker = (question) => {
      if (!question.closed_ended)
        return <FontAwesomeIcon icon={faFont} />
      if (!question.multichoice)
        return <FontAwesomeIcon icon={faCircleDot} />
      else
        return <FontAwesomeIcon icon={faSquareCheck} />
    }
    questionData.data.forEach((question)=>{
      rows.push(<tr key={question.id}>
        <td>{question.index}</td>
        <td>{question.title}{question.required && <b style={{color: "red"}}>*</b>}<br/>
          {question.closed_ended ? <i>{question.choices.data}</i> : <> </>}</td>
        <td>{QuestionTypeMarker(question)} {question.closed_ended && <>({question.choices.count}{question.other_choice && "+1"})</>}</td>
        <td className="text-center">
          <FontAwesomeIcon icon={faArrowsUpDown} onClick={() => {
            setQuestionToMove(question);
            setQuestionIndex(question.index);
          }} className="link-like"/>
        </td>
        <td className="text-center">
          <FontAwesomeIcon icon={faPencil} onClick={() => {
            setQuestionToEdit(question);
            setQuestionToEditID(question.id);
            setQuestionTitle(question.title);
            setQuestionNote(question.note);
            setQuestionRequired(question.required);
            setQuestionType(String((question.closed_ended ? 1 : 0) + (question.multichoice ? 1 : 0)));
            setQuestionOtherChoice(question.other_choice);
            setQuestionChoices(question.choices.data);
          }} className="link-like"/>
        </td>
        <td className="text-center">
          <FontAwesomeIcon icon={faTrash} onClick={() => setQuestionToRemove(question)} className="link-like"/>
        </td>
      </tr>);
    });

    return (
      <Table striped hover responsive>
        <thead>
        <tr>
          <th>#</th>
          <th>Otázka</th>
          <th>Typ</th>
          <th></th>
          <th></th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        {rows}
        </tbody>
      </Table>
    );
  }
};

export default EditCamp;