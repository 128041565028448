import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {handleErrorLoading, handleErrorSubmit, setBrowserTabText} from "../../helpers/Functions";
import PageContainer from "../../layout/PageContainer";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormField from "../../components/form/FormField";
import Button from "react-bootstrap/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PostEditor from "../../components/parts/PostEditor";
import ErrorAlert from "../../components/overlays/ErrorAlert";
import SuccessAlert from "../../components/overlays/SuccessAlert";
import {Card, Form} from "react-bootstrap";
import {getEmailDetail, getEmailRecipients, postSendEmail} from "../../dao";
import CondElem from "../../components/parts/CondElem";
import {labels} from "../../themeLabels";
import Table from "react-bootstrap/Table";
import {faSquareCheck} from "@fortawesome/free-regular-svg-icons";
import Loader from "../../components/overlays/Loader";
import ErrorAlertFullscreen from "../../components/overlays/ErrorAlertFullscreen";
import {faEnvelopesBulk, faPencil} from "@fortawesome/free-solid-svg-icons";
import PageHeading from "../../layout/PageHeading";


const EmailDetail = () => {
  const {emailId} = useParams();
  const {eventId} = useParams();
  const [data, setData] = useState({});
  const [recipientsData, setRecipientsData] = useState({});
  const [recipients, setRecipients] = useState([]);
  const [emailSubject, setEmailSubject] = useState("");
  const [emailBody, setEmailBody] = useState("");
  const [writeMode, setWriteMode] = useState(!emailId);
  const [error, setError] = useState("");
  const [errorRemote, setErrorRemote] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (emailId) {
      getEmailDetail(emailId)
        .then((response) => {
          if (!response.ok) {
            setErrorRemote(handleErrorLoading(response));
            return {};
          }
          return response.json();
        })
        .then((response) => {
          setData(response);
          setEmailSubject(response.subject);
          setEmailBody(response.body);
        })
    }
    getEmailRecipients(eventId, emailId)
      .then((response) => {
        if (!response.ok) {
          setErrorRemote(handleErrorLoading(response));
          return {};
        }
        return response.json();
      })
      .then((response) => {
        setRecipientsData(response);
        setRecipients(response?.recipients);
        setLoading(false);
      })
  }, [eventId, emailId]);

  const handleSaveEmail = (content) => {
    setEmailBody(content);
    selectAllOrNone();
    setWriteMode(false);
  }

  const handleSendEmail = () => {
    setProcessing(true);
    const body = {
      "subject": emailSubject,
      "body": emailBody,
      "recipients": recipientsIds(),
      "event": eventId
    }

    postSendEmail(emailId, body)
      .then((res) => {
        if (res.ok) {
          setSuccess(emailId ? "Email odeslán" : "Email uložen a odeslán");
        } else {
          setError(handleErrorSubmit(res, "Operace se nezdařila"));
        }
        setProcessing(false);
      })
  }

  if (errorRemote !== "")
    return <ErrorAlertFullscreen error={errorRemote} />;
  if (loading)
    return <Loader />;


  if (!eventId)
    setBrowserTabText(!emailId ? 'Napsat klubový email' : `Klubový email | ${data?.subject}`);
  else
    setBrowserTabText(!emailId ? `Napsat email | ${recipientsData?.event?.title}` : `Email | ${data?.subject} | ${recipientsData?.event?.title}`);
  return (
    <PageContainer>
      <PageHeading heading={!emailId ? "Napsat " + (!eventId ? "klubový email" : "email: " + recipientsData?.event?.title) : (!eventId ? "Klubový email" : "Email: " + recipientsData?.event?.title)}
                   label={"Emaily"} link={!eventId ? `/admin/emaily` : `/akce/${eventId}/emaily`} />
      {(!emailId && !writeMode) && <Row>
        <Col className="text-end">
          <Button variant="outline-primary" onClick={() => {setWriteMode(true)}}><FontAwesomeIcon icon={faPencil}/> Upravit text</Button>
        </Col>
      </Row>}
      <FormField label={"Předmět"} value={emailSubject} setValue={setEmailSubject} valueLimit={512} readOnly={!writeMode} controlId={"emailSubject"}/>
      {writeMode ? <div>
        <p className="mb-1">Obsah:</p>
        <PostEditor initialContent={emailBody} onSave={handleSaveEmail} buttonText={"Dále"} allowIndent={false} />
      </div> : <div>
        <p className="mb-1">Obsah:</p>
        <Card>
          <Card.Body>
            <div className="no-bottom-margin" dangerouslySetInnerHTML={{__html: emailBody}} />
          </Card.Body>
        </Card>
        <hr/>
        <Row className="align-items-baseline">
          <Col>
            <p className="mb-0"><b>Výběr příjemců: (vybráno {countSelectedRecipients()}/{recipients.length})</b></p>
            {emailId && <p className="mb-0"><i>Počet již odeslaných mailů: {data?.recipients}</i></p>}
          </Col>
          <Col xs="auto" className="mb-3">
            <Button onClick={handleSendEmail} disabled={processing || countSelectedRecipients() === 0}><b>{emailId ? "Odeslat" : "Uložit & odeslat"}</b></Button>
          </Col>
        </Row>
        <RecipientTable />
      </div>}
      <ErrorAlert error={error} handleClose={()=> setError("")}/>
      <SuccessAlert title={success} handleClose={()=> {
        if (eventId)
          navigate("/akce/" + eventId + "/emaily");
        else
          navigate("/admin/emaily");
      }}/>
    </PageContainer>
  );

  function RecipientTable() {
    let rows = [];
    for (const i in recipients) {
      const person = recipients[i];
      rows.push(<tr key={i}>
        <td align="center">
          <Form.Check checked={person?.selected} onChange={(e) => handlePersonEdit(person.id, e.target.checked)}/>
        </td>
        {emailId && <td className="td-w-icon">{person?.emails > 0 ? person.emails : ""}</td>}
        <td><CondElem href={`/ucet/${person.id}`} linked={labels.CondClubAdminPageTableLinks}>{person.reg_number}</CondElem></td>
        <td><CondElem href={`/ucet/${person.id}/nastaveni/`} linked={labels.CondClubAdminPageTableLinks} hidden={true}>{person.full_name}</CondElem></td>
        <td>{person.group}</td>
      </tr>);
    }

    return <Table bordered striped hover={labels.CondElemTableHover} responsive>
      <thead>
      <tr>
        <th className="align-center pointing"><FontAwesomeIcon icon={faSquareCheck} onClick={selectAllOrNone}/></th>
        {emailId && <th title="Počet odeslání" className="td-w-icon"><FontAwesomeIcon icon={faEnvelopesBulk}/></th>}
        <th><CondElem showIcon={labels.CondClubAdminPageTableLinks}>Reg.</CondElem></th>
        <th>Jméno</th>
        <th title="Skupina">Sk.</th>
      </tr>
      </thead>
      <tbody>
      {rows}
      </tbody>
    </Table>
  }

  function selectAllOrNone() {
    const valueToSet = countSelectedRecipients() !== recipients.length;  // when all are selected, then false, otherwise true
    let tempPeople = JSON.parse(JSON.stringify(recipients));
    for (let i = 0; i < tempPeople.length; i++){
      tempPeople[i].selected = valueToSet;
    }
    setRecipients(tempPeople);
  }

  function countSelectedRecipients() {
    return recipients.filter((r) => {return r?.selected}).length
  }

  function handlePersonEdit(id, selected){
    let tempPeople = JSON.parse(JSON.stringify(recipients));
    for(let i = 0; i < tempPeople.length; i++){
      if(tempPeople[i].id === id){
        tempPeople[i].selected = selected;
      }
    }
    setRecipients(tempPeople);
  }

  function recipientsIds() {
    const ids = [];
    for (const i in recipients) {
      if (recipients[i]?.selected)
        ids.push(recipients[i].id);
    }
    return ids;
  }
}

export default EmailDetail;