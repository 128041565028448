import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {editEditors, getEditors} from "../../dao";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faQuestionCircle,
  faTrash
} from "@fortawesome/free-solid-svg-icons";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";
import ErrorAlert from "../../components/overlays/ErrorAlert";
import SuccessAlert from "../../components/overlays/SuccessAlert";
import {handleErrorLoading, handleErrorSubmit, setBrowserTabText} from "../../helpers/Functions";
import ErrorAlertFullscreen from "../../components/overlays/ErrorAlertFullscreen";
import Loader from "../../components/overlays/Loader";
import PageContainer from "../../layout/PageContainer";
import PageHeading from "../../layout/PageHeading";
import SearchBar from "../../components/form/SearchBar";
import {labels} from "../../themeLabels";


const EventEditors =() => {
  const { eventId } = useParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [errorRemote, setErrorRemote] = useState("");
  const [success, setSuccess] = useState("");
  const [addEditors, setAddEditors] = useState(false);
  const [editorToRemove, setEditorToRemove] = useState(null);

  useEffect(() => {
    setLoading(true);
    getEditors(eventId)
      .then((response) => {
        if (!response.ok) {
          setErrorRemote(handleErrorLoading(response))
          return []
        }
        return response.json()})
      .then((response) => {
        setData(response);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setErrorRemote("Připojení neúspěšné...");
      })
  }, [eventId]);

  function addEditor(userId) {
    sendData(userId, "PUT");
    setAddEditors(false);
  }

  function removeEditor() {
    sendData(editorToRemove.id, "DELETE");
    setEditorToRemove(null);
  }

  function sendData(user, method) {
    editEditors(eventId, user, method)
      .then((res) => {
        if (res.ok) {
          setSuccess("Uloženo");
          res.json().then((r) => setData(r))
        } else {
          setError(handleErrorSubmit(res, "Nezdařilo se uložit"));
        }
      });
  }

  if (errorRemote !== "")
    return <ErrorAlertFullscreen error={errorRemote} />;
  if (loading)
    return <Loader loading={loading} />;

  setBrowserTabText('Editoři | ' + data?.event?.title);
  return (
    <PageContainer>
      <PageHeading heading={"Editoři: " + data?.event?.title} link={"/akce/"+eventId+"/admin"} label={"Admin akce"} />
      <br/>
      <EditorsContent />
      <AddEditor
        show={addEditors}
        onHide={() => {setAddEditors(false)}}
        title={"Vybrat uživatele jako editora"}
        people={data?.options}
        button={"Přidat editora"}
      />
      <Modal show={editorToRemove != null} onHide={()=> {setEditorToRemove(null)}}>
        <Modal.Header closeButton>
          <FontAwesomeIcon icon={faQuestionCircle} size='3x' bounce /> &nbsp;
          <Modal.Title>Skutečně odebrat editora?</Modal.Title>
          <Modal.Body>{editorToRemove?.reg_number}<br/>{editorToRemove?.full_name}</Modal.Body>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=> {
            setEditorToRemove(null);
          }}>Zavřít</Button>
          <Button variant="primary" onClick={()=> {
            removeEditor();
          }}>Odebrat</Button>
        </Modal.Footer>
      </Modal>
      <ErrorAlert error={error} handleClose={()=>setError("")}/>
      <SuccessAlert title={success} handleClose={()=>setSuccess("")}/>
    </PageContainer>
  );

  function EditorsContent() {
    return (<>
      <Row>
        <Col className="col-7">
          <h3>Ručně nastavení editoři</h3>
        </Col>
        <Col className="col text-end">
          <Button variant="primary" onClick={() => setAddEditors(true)}>
            <FontAwesomeIcon icon={faPlus}/> Přidat
          </Button>
        </Col>
      </Row>
      <EditorsTable/>
      <br/>
      <h3>Editoři všech akcí</h3>
      <StaffTable/>
    </>)

    function EditorsTable() {
      if (data.editors.length < 1) {
        return(<><p><i>Žádní uživatelé nebyli přidáni jako editoři</i></p><br/></>)
      }
      const rows = [];
      data.editors.forEach((user)=>{
        rows.push(<tr key={user.id}>
          <td>{user.reg_number}</td>
          <td>{user.full_name}</td>
          <td onClick={()=>setEditorToRemove(user)} className="text-center pointing">
            <FontAwesomeIcon icon={faTrash} />
          </td>
        </tr>);
      });

      return (
        <Table striped bordered hover={labels.CondElemTableHover} responsive>
          <thead>
          <tr>
            <th>Reg.</th>
            <th>Jméno</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          {rows}
          </tbody>
        </Table>
      );
    }

    function StaffTable(){
      const rows = [];
      data.staff.forEach((user)=>{
        rows.push(<tr key={user.id}>
          <td>{user.reg_number}</td>
          <td>{user.full_name}</td>
        </tr>);
      });
      return (
        <Table striped bordered hover={labels.CondElemTableHover} responsive>
          <thead>
          <tr>
            <th>Reg.</th>
            <th>Jméno</th>
          </tr>
          </thead>
          <tbody>
          {rows}
          </tbody>
        </Table>
      );
    }
  }

  function AddEditor(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {props.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PeopleTable people={props.people} button={props.button}/>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Zavřít</Button>
        </Modal.Footer>
      </Modal>
    );

    function PeopleTable(props){
      const [filteredPeople, setFilteredPeople] = useState(props.people);

      if(props.people?.length > 0){
        const rows = [];
        for(var i in filteredPeople){
          const person = filteredPeople[i];
          rows.push(
            <tr key={person.id}>
              <td>{person.reg_number}</td>
              <td>{person.full_name}</td>
              <td>
                <Button onClick={()=> {addEditor(person.id)}}>{props.button}</Button>
              </td>
            </tr>
          );
        }
        return(
          <>
            <SearchBar data={props.people} setFilteredPeople={setFilteredPeople} />
            <Table>
              <tbody>
              {rows}
              </tbody>
            </Table>
          </>
        )
      }
      return(<p>Bohužel nemůžeš přidat další editory.</p>);
    }
  }
}

export default EventEditors