import Form from "react-bootstrap/Form";
import React, {useState} from "react";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {AccountTypeOptions} from "../../helpers/Constants";
import FormSelectDict from "./FormSelectDict";
import ModalContentAccountType from "../parts/ModalContentAccountType";
import {faPencil, faQuestionCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import NoYesIndicator from "../visual/NoYesIndicator";
import {labels} from "../../themeLabels";


export default function FormFieldAccountType ({data, value="", setValue, disabled=false, className="mb-3", controlId}) {
  const [showModal, setShowModal] = useState(false);
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [valueSelected, setValueSelected] = useState(data?.account);
  const valuePrevious = data?.account;

  function isSelectable() {
    return ErrorLog().length === 0;
  }

  function NoteLogList() {
    const rows = [];
    if (["A", "E", "F"].includes(valuePrevious) && ["B", "C", "D"].includes(valueSelected)) {
      if (data?.properties?.guardian)
        rows.push(<li key="n1">účet má nadřazený účet, v případě změny bude nadřazení zrušeno</li>)
    }
    if (!["E"].includes(valuePrevious) && ["E"].includes(valueSelected))
      rows.push(<li key="n2">ověř že došlo k nastavení práv na přihlašování od CORE správce v Orisu</li>)
    return <ul className="mb-0">{rows}</ul>;
  }

  function ErrorLog() {
    const rows = [];
    if (["A", "E", "F"].includes(valuePrevious) && ["B", "C", "D"].includes(valueSelected)) {
      if (data?.properties?.wards)
        rows.push(<li key="e1">účet má přidružené účty</li>)
    }
    if (["A", "B", "E", "F"].includes(valuePrevious) && ["C", "D"].includes(valueSelected)) {
      if (data?.properties?.debt > 0)
        rows.push(<li key="e2">účet má dluh {data?.properties?.debt} Kč</li>)
    }
    if (["C", "D", "E", "F"].includes(valuePrevious) && ["A", "B"].includes(valueSelected)) {
      if (!data?.reg_number.includes(labels.HomeClub))
        rows.push(<li key="e3">účet není členem dle registračního čísla</li>)
    }
    return rows;
  }

  const valueShown = (valuePrevious === value ? AccountTypeOptions[valuePrevious] : valuePrevious + " -> " + AccountTypeOptions[value]);
  return (
    <Form.Group className={className} controlId={controlId}>
      <Form.Label column={false}>Typ účtu: </Form.Label>
      <InputGroup>
        <Form.Control style={{fontSize: "0.9rem"}} value={valueShown} disabled={disabled} readOnly={true} />
        <Button style={{ width: '22%' }} disabled={disabled} onClick={() => setShowModal(true)}><FontAwesomeIcon icon={faPencil}/></Button>
      </InputGroup>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Změnit typ účtu</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormSelectDict label={"Aktuální typ účtu"} value={valuePrevious} disabled={true} options={AccountTypeOptions}
                          controlId={"accountSelectPrev"} />
          <FormSelectDict label={"Nový typ účtu (dluh " + data?.properties?.debt + " Kč)"} value={valueSelected} setValue={setValueSelected} options={AccountTypeOptions}
                          help={<FontAwesomeIcon className="pointing" icon={faQuestionCircle} onClick={() => setShowHelpModal(true)}/>} controlId={"accountSelect"} />
          <hr/>
          <div style={{minHeight: "100px"}}>
            <p className="mb-2">Změna možná: <NoYesIndicator condition={isSelectable()} colored={true}/></p>
            <NoteLogList />
            {!isSelectable() && <ul className="mb-0"><ErrorLog/></ul>}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>Zavřít</Button>
          <Button variant="primary" disabled={!isSelectable()} onClick={() => {
            setValue(valueSelected);
            setShowModal(false);
          }}>Vybrat</Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showHelpModal} onHide={()=>setShowHelpModal(false)}>
        <ModalContentAccountType />
      </Modal>
    </Form.Group>
  )
}