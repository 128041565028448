import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {getUserOptionHistory} from "../../dao";
import {handleErrorLoading, setBrowserTabText} from "../../helpers/Functions";
import Loader from "../../components/overlays/Loader";
import ErrorAlertFullscreen from "../../components/overlays/ErrorAlertFullscreen";
import PageContainer from "../../layout/PageContainer";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClockRotateLeft} from "@fortawesome/free-solid-svg-icons";
import Table from "react-bootstrap/Table";
import {labels} from "../../themeLabels";


const MembershipHistory = () => {
  const { user } = useParams();
  const [loading, setLoading] = useState(true);
  const [errorRemote, setErrorRemote] = useState("");
  const [data, setData] = useState({});

  useEffect(() => {
    getUserOptionHistory(user)
      .then((response) => {
        if (!response.ok) {
          setErrorRemote(handleErrorLoading(response));
          return [];
        }
        return response.json()
      })
      .then((response) => {
        setData(response);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setErrorRemote("Připojení neúspěšné...");
      })
  }, [user]);

  if (errorRemote !== "")
    return <ErrorAlertFullscreen error={errorRemote} />;
  if (loading)
    return <Loader />;

  setBrowserTabText(user == null ? 'Historie členství' : 'Historie členství | ' + data?.user?.full_name);
  return (
    <PageContainer>
      {user == null ? <h1><FontAwesomeIcon icon={faClockRotateLeft} /> Historie členství</h1> : <h1><FontAwesomeIcon icon={faClockRotateLeft} /> Historie členství: {data?.user?.full_name}</h1> }
      <br/>
      <TableSection />
    </PageContainer>
  )

  function TableSection() {
    function ContentTable({data, noContent=""}) {
      const rows = [];
      for (const i in data) {
        const element = data[i];
        rows.push(
          <tr key={i}>
            <td className="td-w-15">{element.year}</td>
            <td className="td-w-15">{element.short}</td>
            <td>{element.title}</td>
          </tr>);
      }

      if (rows.length > 0) {
        return (
          <Table striped bordered hover={labels.CondElemTableHover} responsive>
            <thead>
            <tr>
              <th className="td-w-15">Rok</th>
              <th className="td-w-15">Zkr.</th>
              <th>Název</th>
            </tr>
            </thead>
            <tbody>
            {rows}
            </tbody>
          </Table>
        );
      }
      return (<p><i>{noContent}</i></p>);
    }

    return <div>
      <h3>Členství</h3>
      <ContentTable data={data.memberships} noContent="Žádné záznamy o členství"/>
      <h3>Skupiny</h3>
      <ContentTable data={data.groups} noContent="Žádné záznamy o skupinách"/>
    </div>
  }
}

export default MembershipHistory;