import { Outlet } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Container from "react-bootstrap/Container";
import useUserData from '../helpers/Auth';
import useColormode from "../helpers/Colormode";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSun,
  faMoon,
  faTree,
  faUserGroup,
  faUser,
  faGear,
  faRightFromBracket,
  faCoins,
  faMedal,
  faPeopleArrows,
  faMoneyBillTransfer, faChartSimple, faRankingStar, faSignHanging, faGears
} from '@fortawesome/free-solid-svg-icons';
import {faCalendarDays} from "@fortawesome/free-regular-svg-icons"
import LogoSvg from "../components/visual/LogoSvg";
import {Badge} from "react-bootstrap";
import React from "react";
import { labels } from '../themeLabels';
import {Theme} from "../helpers/Constants";

const PageNav = () => {
  const { userData, setUserData } = useUserData();
  const { colormode, switchColormode, initColorMode } = useColormode();

  function logout() {
    setUserData(null);
    window.location.reload();
  }
  initColorMode();
  function ModeButton() {
    return colormode === "dark" ? <FontAwesomeIcon icon={faSun} onClick={switchMode} /> : <FontAwesomeIcon icon={faMoon} onClick={switchMode} />;

    function switchMode() {
      switchColormode();
    }
  }

  const MenuItem = ({ icon, text }) => {
    if (!text) {
      return <></>;
    }
    return (
      <>
        <div style={{ display: 'flex', alignItems: 'center', fontSize: "1rem" }}>
          <span style={{ marginRight: '4px' }}>{icon}</span>
          <span>{text}</span>
        </div>
      </>
    );
  }
  const MenuIcon = ({icon, color=""}) => (
    <FontAwesomeIcon icon={icon} color={color} width="18px"/>
  );

  let billingColor = "";
  const billDifference = localStorage.getItem('billDifference');
  if (billDifference != null) {
    if (billDifference > 0)
      billingColor = "red";
  }

  const DropdownMenu = () => (
    <NavDropdown title={<div style={{ display: "inline-block"}}><MenuItem icon={<MenuIcon icon={faUser} color={billingColor}/>} text={<b>{userData?.full_name}</b>}/></div>}
                 id="account-dropdown" className="d-flex">
      {(!labels.CondMyRacesOnNav) && <NavDropdown.Item href={`/ucet/zavody`}><MenuItem icon={<MenuIcon icon={faMedal}/>} text={labels.myRacesSection}/></NavDropdown.Item>}
      {labels.CondShowUserBilling && <NavDropdown.Item href="/ucet/vyuctovani"><MenuItem icon={<MenuIcon icon={faCoins} color={billingColor}/>} text={"Vyúčtování"}/></NavDropdown.Item>}
      <NavDropdown.Item href="/ucet/nastaveni"><MenuItem icon={<MenuIcon icon={faGear}/>} text={"Nastavení"}/></NavDropdown.Item>
      <NavDropdown.Item href="/ucet/statistiky"><MenuItem icon={<MenuIcon icon={faChartSimple}/>} text={"Statistiky"}/></NavDropdown.Item>
      <NavDropdown.Item href="/ucet/opravneni"><MenuItem icon={<MenuIcon icon={faPeopleArrows}/>} text={"Oprávnění"}/></NavDropdown.Item>
      <NavDropdown.Divider />
      <NavDropdown.Item onClick={logout}><MenuItem icon={<MenuIcon icon={faRightFromBracket}/>} text={"Odhlásit se"}/></NavDropdown.Item>
    </NavDropdown>
  )

  let unseenPostsColor = "";
  let unseenPostsBadge = <></>;
  const unseenPosts = localStorage.getItem('unseenPosts');
  if (unseenPosts != null) {
    if (unseenPosts > 0) {
      unseenPostsBadge = <Badge style={{fontSize: "0.9rem"}} pill bg="warning">{unseenPosts}</Badge>
      unseenPostsColor = "orange";
    }
  }

  return (
    <>
      <Navbar bg={colormode} data-bs-theme={colormode} expand="lg" fixed="top">
        <Container>
          <Navbar.Brand href="/">
            <LogoSvg zoom={1} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav" >
            <Nav style={{ width: "100%" }}>
              <Nav.Link href={labels.UrlCalendar}><MenuItem icon={Theme === "TAP" ? <MenuIcon icon={faTree}/> : <MenuIcon icon={faCalendarDays}/> } text={labels.homeSection}/></Nav.Link>
              {userData?.view_users ?
                <>
                  <Nav.Link href={`/klub`}><MenuItem icon={<MenuIcon icon={faUserGroup} color={unseenPostsColor}/>} text={labels.clubSection}/></Nav.Link>
                  <Nav.Link href={`/admin`}><MenuItem icon={<MenuIcon icon={faGears}/>} text={<>Administrace</>}/></Nav.Link>
                </> : <>
                  <Nav.Link href={`/klub`}><MenuItem icon={<MenuIcon icon={faUserGroup}/>} text={labels.clubSection}/></Nav.Link>
                  <Nav.Link href={labels.UrlDashboard}><MenuItem icon={<MenuIcon icon={faSignHanging}/>} text={<>Nástěnka {unseenPostsBadge}</>}/></Nav.Link>
                </>
              }
              {userData?.use_billing && (<Nav.Link href={`/ucetnictvi`}><MenuItem icon={<MenuIcon icon={faMoneyBillTransfer}/>} text={"Účetnictví"}/></Nav.Link>)}

              <Nav.Link className="me-auto mt-auto"><ModeButton /></Nav.Link>
              <Nav.Link href={`/zebricky`}><MenuItem icon={<MenuIcon icon={faRankingStar}/>} text={labels.cupsSection}/></Nav.Link>
              {labels.CondMyRacesOnNav && <Nav.Link href={`/ucet/zavody`}><MenuItem icon={<MenuIcon icon={faMedal}/>} text={labels.myRacesSection}/></Nav.Link>}
            </Nav>
            <DropdownMenu/>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Outlet />
    </>
  );
};

export default PageNav;