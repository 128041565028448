import React, { useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useParams } from "react-router-dom";
import Loader from "../../components/overlays/Loader";
import {editTransport, getEventApplyRights, getTransportEditInfo} from "../../dao";
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import { useState } from "react";
import {faCarSide, faCircleInfo, faFloppyDisk, faPencil, faPeopleGroup, faRotate} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Button from "react-bootstrap/esm/Button";
import {handleErrorLoading, handleErrorSubmit, parseTime, setBrowserTabText} from "../../helpers/Functions";
import ErrorAlertFullscreen from "../../components/overlays/ErrorAlertFullscreen";
import {Badge, Form} from "react-bootstrap";
import PageContainer from "../../layout/PageContainer";
import ErrorAlert from "../../components/overlays/ErrorAlert";
import SuccessAlert from "../../components/overlays/SuccessAlert";
import PageHeading from "../../layout/PageHeading";
import EventDate from "../../components/parts/EventDate";
import SelectUserModal from "../../components/parts/SelectUserModal";
import {labels} from "../../themeLabels";
import Modal from "react-bootstrap/Modal";
import VehicleIcon from "../../components/visual/VehicleIcon";
import FormCheckbox from "../../components/form/FormCheckbox";

export default function TransportAdmin() {
  const { eventId } = useParams();
  const [data, setData] = useState([]);
  const [rightData, setRightData] = useState({count:-1, rights: []});
  const [visibleToUsers, setVisibleToUsers] = useState(false);
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [errorRemote, setErrorRemote] = useState("");
  const [isDirty, setIsDirty] = useState(false);
  const [selectUsersShow, setSelectUsersShow] = useState(false);
  const [editVehicle, setEditVehicle] = useState(null);

  useEffect(() => {
    setLoading(true);
    getTransportEditInfo(eventId)
      .then((response) => {
        if (!response.ok) {
          setErrorRemote(handleErrorLoading(response));
          return [];
        }
        return response.json()
      })
      .then((response) => {
        setData(response);
        setVisibleToUsers(response?.transport_handled);
        setLoading(false);
      })
    getEventApplyRights(eventId)
      .then((response) => {
        if (!response.ok) {
          setErrorRemote(handleErrorLoading(response));
          return {count:-1, rights: []}
        }
        return response.json()
      })
      .then((response) => {
        setRightData(response);
      })
  }, [eventId]);

  async function saveChanges() {
    let outVehicles = JSON.parse(JSON.stringify(data.vehicles));
    outVehicles.forEach((vehicle, vehicleId) => {
      vehicle.crew.forEach((passanger, passangerId)=>{
        outVehicles[vehicleId].crew[passangerId] = passanger.id;
      });
    });
    setProcessing(true);
    const res = await editTransport(eventId, outVehicles, visibleToUsers);
    if(res.ok){
      setSuccess("Rozřazení uloženo");
      res.json().then((response) => setData(response));
    }else{
      setError(handleErrorSubmit(res, "Nepodařilo se uložit rozdělení"));
    }
    setProcessing(false);
  }

  function handleOnDragEnd(result) {
    setIsDirty(true);
    let tempData = JSON.parse(JSON.stringify(data));
    if (result.destination != null) {
      if(isVehicleAvailable(result.destination.droppableId)){
        const removedPerson = removePerson(result.draggableId, result.source.droppableId, tempData);
        tempData = addPerson(removedPerson, result.destination.droppableId, tempData);
      }
    }
    setData(tempData);
  }

  function isVehicleAvailable(id){
    if(id === 'people'){
      return true;
    }else{
      let res = false;
      data.vehicles.forEach((vehicle) => {
        if (vehicle.id.toString() === id) {
          res = !(vehicle.capacity <= vehicle.crew.length);
          if(!vehicle.used || vehicle?.delete){
            res = false;
          }
        }
      });
      return res;
    }
  }

  function removePerson(personId, location, tempData) {
    let res = {};
    if (location === 'people') {
      tempData.unassigned.forEach((person, index) => {
        if (person.id.toString() === personId) {
          res = (tempData.unassigned.splice(index, 1)[0]);
        }
      });
    } else {
      tempData.vehicles.forEach((vehicle) => {
        if (vehicle.id.toString() === location) {
          vehicle.crew.forEach((person, index) => {
            if (person.id.toString() === personId) {
              res = vehicle.crew.splice(index, 1)[0];
            }
          });
        }
      });
    }
    return res;
  }
  function addPerson(person, location, tempData) {
    if (location === 'people') {
      tempData.unassigned.push(person);
      tempData.unassigned.sort(function(a, b)
      {
        const x1 = a.start_time; const y1 = b.start_time;
        return ((x1 < y1) ? -1 : ((x1 > y1) ? 1 : 0));
      });
    } else {
      tempData.vehicles.forEach((vehicle) => {
        if (vehicle.id.toString() === location) {
          vehicle.crew.push(person);
        }
      });
    }
    return tempData;
  }

  function freeSpacesSum() {
    let sum = 0;
    data.vehicles.forEach((vehicle) => {
      if (vehicle.used && !vehicle?.delete) {
        sum += (vehicle.capacity - vehicle.crew.length);
      }
    });
    return sum;
  }

  function setVisible(value) {
    setIsDirty(true);
    setVisibleToUsers(value);
  }

  if (errorRemote !== "")
    return <ErrorAlertFullscreen error={errorRemote} />;
  if (loading)
    return <Loader />;

  setBrowserTabText('Rozdělení dopravy | ' + data?.event?.title);
  return (<PageContainer>
    <PageHeading heading={"Rozdělení dopravy: " + data?.event?.title} label={"Doprava"} link={"/akce/"+eventId+"/doprava/detail"} />
    <p><EventDate event={data?.event} /></p>
    <Row>
      <Col xs="auto" className="pe-3">
        <Button disabled={!isDirty || processing} onClick={saveChanges}>
          {processing ? <FontAwesomeIcon icon={faRotate} spin/> : <FontAwesomeIcon icon={faFloppyDisk}/>} Uložit změny
        </Button>
      </Col>
      <Col xs="auto" className="pe-2">
        <FormCheckbox label={labels.transportHandleSwitch} type={"switch"} value={visibleToUsers} setValue={setVisible} tooltip={labels.transportHandleTooltip}
                      className={`mt-2 ${(!visibleToUsers && !labels.CondMyTransportSelf) ? 'pulse' : ''}`} controlId={"visibleSwitch"} />
      </Col>
      <Col xs="auto" className="mb-2"><Button variant="link" onClick={() => setSelectUsersShow(true)}>{data.event.type === 0 && !data.event?.is_relay ? labels.LinkEntryOthersRemote : labels.LinkEntryOthersLocal}</Button></Col>
    </Row>
    <br />
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <h3><FontAwesomeIcon icon={faPeopleGroup} /> Zájemci ({data?.unassigned.length})</h3>
      <Droppable droppableId="people">
        {(provided) =>
          (<Row {...provided.droppableProps}
                ref={provided.innerRef} className="ms-1 me-1"><Unassigned people={data?.unassigned} />
            {provided.placeholder}
          </Row>)}
      </Droppable>
      <br/>
      <Row>
        <Col xs="auto">
          <h3><FontAwesomeIcon icon={faCarSide} /> Vozidla ({freeSpacesSum()})</h3>
        </Col>
        <Col xs="auto">
          <Button className="mb-2" onClick={addVehicle}>+ <FontAwesomeIcon icon={faCarSide} /></Button>
        </Col>
      </Row>
      <Row>
        {VehicleList()}
      </Row>
    </DragDropContext>
    <Modal show={editVehicle != null} onHide={() => setEditVehicle(null)}>
      <Modal.Header closeButton>
        <Modal.Title>Upravit vozidlo:</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3" controlId="vehicletitle">
            <Form.Label column={false}>Název:</Form.Label>
            <Form.Control name="title" type="text" value={editVehicle?.title}
                          onChange={(e) => handleDetailChange(editVehicle?.id, e.target.name, e.target.value)}/>
          </Form.Group>
          <Form.Group className="mb-3" controlId="vehicleinfo">
            <Form.Label column={false}>Info:</Form.Label>
            <Form.Control name="info" type="text" value={editVehicle?.info}
                          onChange={(e) => handleDetailChange(editVehicle?.id, e.target.name, e.target.value)}/>
          </Form.Group>
          <Form.Group className="mb-3" controlId="vehiclecapacity">
            <Form.Label column={false}>Kapacita:</Form.Label>
            <Form.Control name="capacity" type="number" value={editVehicle?.capacity}
                          onChange={(e) => handleDetailChange(editVehicle?.id, e.target.name, e.target.value)}/>
          </Form.Group>
          <hr/>
          {!editVehicle?.driver &&
            <Form.Group className="mb-3" controlId="vehicledelete">
              <Form.Check name="delete" label="Smazat při uložení" value={editVehicle?.delete} defaultChecked={editVehicle?.delete}
                          onChange={() => handleDeleteChange(editVehicle?.id)}/>
            </Form.Group>
          }
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={()=>setEditVehicle(null)}>Hotovo</Button>
      </Modal.Footer>
    </Modal>
    <SelectUserModal
      id={eventId} eventType={data?.event?.type}
      show={selectUsersShow}
      onHide={() => {setSelectUsersShow(false)}}
      title="Vybrat uživatele pro přihlášení"
      people={rightData.rights}
      loading={rightData.count === -1}
    />
    <ErrorAlert error={error} handleClose={()=>setError("")}/>
    <SuccessAlert title={success} handleClose={()=>setSuccess("")}/>
  </PageContainer>)

  function addVehicle(){
    setIsDirty(true);
    let tempData = JSON.parse(JSON.stringify(data));
    let vehicleId = -1;
    tempData.vehicles.forEach((vehicle)=>{
      if(vehicleId >= vehicle.id){
        vehicleId = vehicle.id - 1;
      }
    });
    const vehicle = {
      capacity: 4,
      crew: [],
      driver: null,
      id: vehicleId,
      if_needed_only: true,
      info: "",
      title: "Nové vozidlo",
      used: true
    };
    tempData.vehicles.push(vehicle);
    setData(tempData);
    setEditVehicle(vehicle);
  }

  function handleDetailChange(vehicleId, detail, value){
    setIsDirty(true);
    let tempData = JSON.parse(JSON.stringify(data));
    tempData.vehicles.forEach(vehicle =>{
      if(vehicle.id === vehicleId){
        vehicle[detail] = value;
        setEditVehicle(vehicle);
      }
    });
    setData(tempData);
  }

  function handleUsedChange(vehicleId){
    setIsDirty(true);
    let tempData = JSON.parse(JSON.stringify(data));
    tempData.vehicles.forEach((vehicle, vehiclePos) => {
      if(vehicleId === vehicle.id){
        const used = vehicle.used;
        tempData.vehicles[vehiclePos].used = !vehicle.used;
        if(used) {
          vehicle.crew.forEach((person)=> {
            tempData.unassigned.push(person);
          });
          tempData.vehicles[vehiclePos].crew = [];
          if (vehicle.driver != null)
            tempData.unassigned.push(vehicle.driver);
          else {
            if (vehicleId < 0)  // remove vehicles with negative ids - newly created
              tempData.vehicles.splice(vehiclePos, 1);
            else  // existing vehicles are set to delete
              tempData.vehicles[vehiclePos].delete = true;
          }
        }else{
          if (vehicle.driver != null)
            tempData = removeDriver(vehicle.driver.id, tempData);
        }
      }
    });
    setData(tempData);
  }

  function removeDriver(driverId, tempData){
    tempData.unassigned.forEach((person, personPos)=>{
      if(person.id === driverId){
        tempData.unassigned.splice(personPos, 1);
        return tempData;
      }
    });
    tempData.vehicles.forEach((vehicle, vehiclePos) =>{
      vehicle.crew.forEach((person, personPos)=>{
        if(person.id === driverId){
          tempData.vehicles[vehiclePos].crew.splice(personPos, 1);
        }
      });
    });
    return tempData;
  }

  function handleDeleteChange(vehicleId){
    setIsDirty(true);
    let tempData = JSON.parse(JSON.stringify(data));
    tempData.vehicles.forEach((vehicle, vehiclePos) => {
      if(vehicleId === vehicle.id) {
        if (vehicle.driver == null) {
          const markedToDelete = !(vehicle?.delete);
          tempData.vehicles[vehiclePos].delete = markedToDelete;
          if(vehicle.used && markedToDelete) {
            vehicle.crew.forEach((person)=> {
              tempData.unassigned.push(person);
            });
            tempData.vehicles[vehiclePos].crew = [];
          }
        }
      }
    });
    setData(tempData);
  }

  function VehicleList() {
    const res = [];
    data?.vehicles?.forEach(vehicle => {
      let vehicleStyle = {maxWidth: "340px"};
      if (!vehicle.used)
        vehicleStyle = {color: "grey", maxWidth: "340px"};
      if (vehicle?.delete)
        vehicleStyle = {color: "red", maxWidth: "340px"};
      res.push(
        <Col xs="auto"  key={vehicle.id + ""} >
          <Card className="mb-3" style={vehicleStyle}>
            <Card.Body>
              <Row className="align-items-baseline mb-2">
                <Col>
                  <b><VehicleIcon title={vehicle.title} />&nbsp;{vehicle.title}</b>
                </Col>
                {vehicle.if_needed_only &&
                  <Col xs="auto">
                    <Form.Check type="switch" id="visible-switch" defaultChecked={vehicle.used} title="Potřebné?"
                                value={vehicle.used} disabled={vehicle?.delete} onChange={() => handleUsedChange(vehicle.id)}/>
                  </Col>}
                <Col xs="auto">
                  <FontAwesomeIcon icon={faPencil} className="pointing" onClick={() => {if (vehicle.used) setEditVehicle(vehicle)}}/>
                </Col>
              </Row>
              {vehicle.driver != null &&
                <ListGroup>
                  <ListGroup.Item disabled={!vehicle.used}>
                    <Row className="align-items-baseline">
                      <Col xs={7} style={{whiteSpace: "nowrap"}}>{vehicle.driver?.full_name}</Col>
                      {vehicle.driver?.start_time != null &&
                        <Col className="text-end"><Badge style={{fontSize: "12px"}} bg="primary">{parseTime(vehicle.driver?.start_time)}</Badge></Col>}
                    </Row>
                  </ListGroup.Item>
                </ListGroup>
              }
              <p className="mb-1 mt-1"><FontAwesomeIcon icon={faCircleInfo} color="gray" /> {vehicle.info}</p>
              <ListGroup variant="flush" style={{whiteSpace: "nowrap"}}>
                {vehicle?.delete ?
                  <p className="mt-4 mb-2"><b>Vozidlo bude smazáno</b></p> :
                  <Droppable droppableId={vehicle.id + ""} key={vehicle.id + ""}>
                    {(provided) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        <Crew people={vehicle.crew}/>
                        {provided.placeholder}
                        <p className="mt-4 mb-2">Zbývající místa: {vehicle.capacity - vehicle.crew.length}</p>
                      </div>
                    )}
                  </Droppable>
                }
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>);
    });
    return (res);
  }

  function Crew(props) {
    const crew = [];
    props.people?.forEach((person, index) => {
      crew.push(
        <Draggable key={person.id + ""} draggableId={person.id + ""} index={index}>
          {(provided) => (
            <ListGroup.Item ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
              <Row className="align-items-baseline">
                <Col xs={7} style={{whiteSpace: "nowrap"}}>{person.full_name}</Col>
                {person.start_time != null && <Col className="text-end"><Badge style={{fontSize: "12px"}} bg="primary">{parseTime(person.start_time)}</Badge></Col>}
              </Row>
            </ListGroup.Item>
          )}
        </Draggable>
      );
    });
    if(crew.length < 1){
      return (<Card className="mt-2"><i></i></Card>)
    }
    return crew;
  }

  function Unassigned(props) {
    const crew = [];
    props.people?.forEach((person, index) => {
      crew.push(
        <Col xs="auto" className="ps-1 pe-1" key={person.id + ""}>
          <Draggable key={person.id + ""} draggableId={person.id + ""} index={index}>
            {(provided) => (
              <Card className="mt-2" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                <div className="m-2">{person.full_name} {person.start_time != null && <Badge style={{fontSize: "12px"}} bg="primary">{parseTime(person.start_time)}</Badge>}</div>
              </Card>
            )}
          </Draggable>
        </Col>
      );
    });
    return crew;
  }
}