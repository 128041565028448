import Loader from "../../components/overlays/Loader";
import ErrorAlertFullscreen from "../../components/overlays/ErrorAlertFullscreen";
import {handleErrorLoading, handleErrorSubmit, setBrowserTabText} from "../../helpers/Functions";
import PageContainer from "../../layout/PageContainer";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faChartSimple,
  faClockRotateLeft,
  faCog,
  faLock,
  faPencil,
  faPersonWalkingDashedLineArrowRight,
  faTrash
} from "@fortawesome/free-solid-svg-icons";
import Form from "react-bootstrap/Form";
import FormField from "../../components/form/FormField";
import ErrorAlert from "../../components/overlays/ErrorAlert";
import SuccessAlert from "../../components/overlays/SuccessAlert";
import Table from "react-bootstrap/Table";
import React, {useEffect, useState} from "react";
import {deleteUserGroup, getMembersAdmin, getUserGroups, moveUsersToGroup, postPutUserGroup} from "../../dao";
import WarningCard from "../../components/overlays/WarningCard";
import LoadingIcon from "../../components/overlays/LoadingIcon";
import CondElem from "../../components/parts/CondElem";
import {labels} from "../../themeLabels";
import {Link} from "react-router-dom";
import SearchBar from "../../components/form/SearchBar";
import FormSelectDict from "../../components/form/FormSelectDict";
import {faSquareCheck} from "@fortawesome/free-regular-svg-icons";
import LoadingOverlay from "../../components/overlays/LoadingOverlay";


const UserGroups = () => {
  const [groupData, setGroupData] = useState({});
  const [memberData, setMemberData] = useState({members: [], logged: {}});
  const [error, setError] = useState("");
  const [errorRemote, setErrorRemote] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(true);
  const [loadingData, setLoadingData] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [usersSelected, setUsersSelected] = useState([]);
  const [groupToChange, setGroupToChange] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showMoveModal, setShowMoveModal] = useState(false);
  const [title, setTitle] = useState("");
  const [short, setShort] = useState("");
  const [newGroup, setNewGroup] = useState("");


  useEffect(() => {
    getUserGroups()
      .then((response) => {
        if (!response.ok) {
          setErrorRemote(handleErrorLoading(response));
          return {};
        }
        return response.json();
      })
      .then((response) => {
        setGroupData(response);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setErrorRemote("Připojení neúspěšné...");
      });
    getMembersAdmin("#", "*")
      .then((response) => {
        if (!response.ok) {
          setErrorRemote(handleErrorLoading(response));
          return {members: [], logged: {}}
        }
        return response.json()
      })
      .then((response) => {
        setMemberData(response);
        setLoadingData(false);
      })
      .catch((err) => {
        console.log(err);
        setErrorRemote("Připojení neúspěšné...");
      })
  }, []);

  const handleSubmit = () => {
    setShowEditModal(false);
    const formData = {
      "title" : title,
      "short": short,
    };
    postPutUserGroup(groupToChange, formData)
      .then((res) => {
        if (res.ok) {
          setSuccess(groupToChange ? "Upraveno" : "Přidáno");
          res.json().then((response) => setGroupData(response));
        } else {
          setError(handleErrorSubmit(res, "Operace se nezdařila"));
        }
      })
  }

  const handleDelete = () => {
    setShowDeleteModal(false);
    deleteUserGroup(groupToChange)
      .then((res) => {
        if (res.ok) {
          setSuccess("Smazáno");
          res.json().then((response) => setGroupData(response));
        } else {
          setError(handleErrorSubmit(res, "Operace se nezdařila"));
        }
      })
  }

  const handleMove = () => {
    setShowMoveModal(false);
    setProcessing(true);
    moveUsersToGroup(newGroup, usersSelected)
      .then((res) => {
        if (res.ok) {
          setSuccess("Upraveno");
          setUsersSelected([]);
          res.json().then((response) => setMemberData(response));
        } else {
          setError(handleErrorSubmit(res, "Operace se nezdařila"));
        }
        setProcessing(false);
      })
  }

  if (errorRemote !== "")
    return <ErrorAlertFullscreen error={errorRemote} />;
  if (loading)
    return <Loader />;

  setBrowserTabText('Správa uživatelských skupin');
  return (
    <PageContainer>
      <h1>Správa uživatelských skupin</h1>
      <Row>
        <Col className="mb-3 text-end">
          <Button onClick={() => {
            setGroupToChange(null);
            setTitle("");
            setShort("");
            setShowEditModal(true);
          }}>+ Přidat</Button>
        </Col>
      </Row>
      <UserGroupsTable/>
      <hr/>
      <MemberTable />
      <LoadingOverlay loading={processing} />
      <Modal show={showEditModal} onHide={()=>setShowEditModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{groupToChange ? "Upravit" : "Přidat"} skupinu</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <FormField label={"Zkratka"} value={short} setValue={setShort} valueLimit={2} isInvalid={short.length === 0} digits="none" inline={true} controlId={"short"} />
            <FormField label={"Název"} value={title} setValue={setTitle} valueLimit={32} isInvalid={title.length === 0} digits="none" inline={true} controlId={"title"} />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowEditModal(false)}>
            Zavřít
          </Button>
          <Button variant="primary" disabled={title.length === 0 || short.length === 0} onClick={handleSubmit}>
            {groupToChange ? "Upravit" : "Přidat"}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showDeleteModal} onHide={()=>setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Smazat skupinu?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <FormField label={"Zkratka"} value={short} readOnly={true} digits="none" inline={true} controlId={"short"} />
            <FormField label={"Název"} value={title} readOnly={true} digits="none" inline={true} controlId={"title"} />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Zavřít
          </Button>
          <Button variant="primary" onClick={handleDelete}>
            Smazat
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showMoveModal} onHide={()=>setShowMoveModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Hromadný přesun členů</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table>
            <tbody>
            {usersSelected.map((userId) => {for (const i in memberData.members) {
              const m = memberData.members[i];
              if (m.id === userId) return <tr key={i}>
                <td>{m.reg_number} -&nbsp;</td>
                <td>{m.full_name}&nbsp;</td>
                <td>{m.group ? m.group : "??"} <FontAwesomeIcon icon={faArrowRight}/> {newGroup ? newGroup : "??"}</td>
              </tr>
            }
            return <></>;
            })}
            </tbody>
          </table>
          <hr/>
          <FormSelectDict label={"Nová skupina"} value={newGroup} setValue={setNewGroup} controlId={"newGroup"}
                          options={groupData?.groups.reduce((acc, group) => {acc[group.short] = group?.title; return acc;},  {'': '--'})} />
          <i>Změna skupiny se projeví odebráním uživatelů z whitelistu skupin a také v záznamu o členství v roce <b>{new Date().getFullYear()}</b>.</i>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowMoveModal(false)}>
            Zavřít
          </Button>
          <Button variant="primary" onClick={handleMove}>
            Přesunout
          </Button>
        </Modal.Footer>
      </Modal>
      <ErrorAlert error={error} handleClose={() => setError("")}/>
      <SuccessAlert title={success} handleClose={()=>setSuccess("")}/>
    </PageContainer>
  );

  function UserGroupsTable() {
    const rows = [];
    for (const i in groupData?.groups) {
      const group = groupData.groups[i];
      rows.push(<tr key={i}>
        <td>{group.short}</td>
        <td>{group.title}</td>
        <td className="align-center">{group.profiles}</td>
        <td className="td-w-icon"><FontAwesomeIcon icon={faPencil} className="link-like" onClick={() => {
          setGroupToChange(group.id);
          setTitle(group.title);
          setShort(group.short);
          setShowEditModal(true);
        }}/></td>
        <td className="td-w-icon">
          {group.profiles === 0 ? <FontAwesomeIcon icon={faTrash} className="link-like" onClick={() => {
            setGroupToChange(group.id);
            setTitle(group.title);
            setShort(group.short);
            setShowDeleteModal(true);
          }}/> : <FontAwesomeIcon icon={faTrash} color="gray"/>}
        </td>
      </tr>);
    }

    if (rows.length === 0)
      return <WarningCard text="Žádné skupiny"/>;

    return (
      <Table striped bordered hover={labels.CondElemTableHover} responsive>
        <thead>
        <tr>
          <th></th>
          <th>Název</th>
          <th>Poč.</th>
          <th></th>
          <th/>
        </tr>
        </thead>
        <tbody>
        {rows}
        </tbody>
      </Table>
    );
  }

  function MemberTable() {
    const [filteredPeople, setFilteredPeople] = useState(memberData.members);

    function changeSelection(id) {
      const tempUsers = JSON.parse(JSON.stringify(usersSelected))
      if (tempUsers.includes(id)) {
        const index = tempUsers.indexOf(id);
        tempUsers.splice(index, 1);
        setUsersSelected(tempUsers);
      } else {
        tempUsers.push(id);
        setUsersSelected(tempUsers);
      }
    }

    if (loadingData)
      return <LoadingIcon />;

    const TableRow = ({element}) => {
      return (
        <tr>
          <td align="center">
            <Form.Check defaultChecked={usersSelected.includes(element.id)} value={usersSelected.includes(element.id)}
                        onChange={() => changeSelection(element.id)}/>
          </td>
          <td><CondElem href={`/ucet/${element.id}`}
                        linked={memberData.logged?.perms?.view_users && labels.CondClubAdminPageTableLinks}>{element.reg_number}</CondElem></td>
          <td><CondElem href={`/ucet/${element.id}/nastaveni/`} linked={labels.CondClubAdminPageTableLinks} hidden={true}
                        showIcon={!element?.activated && element.account !== "D"}
                        suffixIcon={<FontAwesomeIcon color="red" icon={faLock}/>}>{element.full_name}</CondElem></td>
          <td>{element.account}</td>
          <td>{element.group}</td>
          <td className="align-center td-w-icon">
            <Link to={`/ucet/${element.id}/clenstvi/`}><FontAwesomeIcon icon={faClockRotateLeft} title="Historie členství a skupin" size="lg"/></Link>
          </td>
          <td className="align-center td-w-icon"><Link to={`/ucet/${element.id}/nastaveni/`}><FontAwesomeIcon icon={faCog} size="lg"/></Link></td>
        </tr>
      )
    }

    const rows = [];
    for (let i in filteredPeople) {
      const obj = filteredPeople[i];
      rows.push(<TableRow element={obj} key={i}/>);
    }

    return (<div>
      <Row className="align-items-baseline">
        <Col>
          <Row>
            <Col className="pe-0" xs="auto"><b>Hromadná změna:</b></Col>
            <Col xs="auto"><b>(vybráno {usersSelected.length}/{memberData.members.length})</b></Col>
          </Row>
        </Col>
        <Col xs="auto" className="mb-2">
          <Button variant="secondary" disabled={usersSelected.length === 0} onClick={() => setShowMoveModal(true)}>
            <FontAwesomeIcon icon={faPersonWalkingDashedLineArrowRight}/> Hromadný přesun
          </Button>
        </Col>
      </Row>
      <SearchBar data={memberData.members} setFilteredPeople={setFilteredPeople}/>
      <Table striped bordered hover={labels.CondElemTableHover} responsive>
        <thead>
        <tr>
          <th className="align-center pointing"><FontAwesomeIcon icon={faSquareCheck} onClick={() => {
            if (usersSelected.length !== filteredPeople.length) {
              const users = [];
              for (const i in filteredPeople)
                users.push(filteredPeople[i].id)
              setUsersSelected(users);
            } else {
              setUsersSelected([]);
            }
          }}/></th>
          <th><CondElem showIcon={memberData.logged?.perms?.view_users && labels.CondClubAdminPageTableLinks}
                        suffixIcon={<FontAwesomeIcon icon={faChartSimple}/>}>Reg.</CondElem></th>
          <th>Jméno</th>
          <th title="Typ účtu">T</th>
          <th title="Skupina">Sk.</th>
          <th title="Historie členství a skupin">His.</th>
          <th title="Nastavení">Nast.</th>
        </tr>
        </thead>
        <tbody>
        {rows}
        </tbody>
      </Table>
      <p><i>Filtru odpovídá <b>{rows.length}</b> záznamů</i></p>
    </div>)
  }
}

export default UserGroups;