import Button from "react-bootstrap/Button";
import Form from 'react-bootstrap/Form';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {getUserDetail, editUser, setCsrfToken, changeAccountLock, getFieldOptions, validateEditUser} from "../../dao";
import React, {useState} from "react";
import { useEffect } from "react";
import Alert from 'react-bootstrap/Alert';
import {Link, useParams} from "react-router-dom";
import SuccessAlert from "../../components/overlays/SuccessAlert";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGavel, faLock, faLockOpen, faQuestionCircle} from "@fortawesome/free-solid-svg-icons";
import ErrorAlertFullscreen from "../../components/overlays/ErrorAlertFullscreen";
import {handleErrorLoading, handleErrorSubmit, setBrowserTabText} from "../../helpers/Functions";
import {RegionCustomizeOptions} from "../../helpers/Constants";
import PageContainer from "../../layout/PageContainer";
import {isMobileDevice} from "../../helpers/Device";
import FormField from "../../components/form/FormField";
import FormSelectDict from "../../components/form/FormSelectDict";
import FormCheckbox from "../../components/form/FormCheckbox";
import Loader from "../../components/overlays/Loader";
import Modal from "react-bootstrap/Modal";
import ModalContentAccount from "../../components/parts/ModalContentAccount";
import {labels} from "../../themeLabels";
import FormFieldPassword from "../../components/form/FormFieldPassword";
import FormFieldAccountType from "../../components/form/FormFieldAccountType";
import LoadingOverlay from "../../components/overlays/LoadingOverlay";

const Settings = () => {
  const { user } = useParams();
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState("");
  const [errorRemote, setErrorRemote] = useState("");
  const [success, setSuccess] = useState("");

  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [regNumber, setRegNumber] = useState("");
  const [accountType, setAccountType] = useState("");
  const [role, setRole] = useState("");
  const [membership, setMembership] = useState("");
  const [group, setGroup] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [officialName, setOfficialName] = useState("");
  const [officialSurname, setOfficialSurname] = useState("");
  const [birthdate, setBirthdate] = useState(undefined);
  const [personalNumber, setPersonalNumber] = useState("");
  const [nationality, setNationality] = useState("");

  const [mail1, setMail1] = useState("");
  const [mail2, setMail2] = useState("");
  const [phone, setPhone] = useState("");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [country, setCountry] = useState("");

  const [iofId, setIofId] = useState(0);
  const [siCards, setSiCards] = useState("");
  const [levelColors, setLevelColors] = useState(false);
  const [includedRegion, setIncludedRegion] = useState("");
  const [watched, setWatched] = useState("");
  const [data, setData] = useState({});
  const [fieldOptions, setFieldOptions] = useState({roles: {}, memberships: {}, groups: {}});
  const [generalHelp, setGeneralHelp] = useState(false);
  const [confirmLockChange, setConfirmLockChange] = useState(false);

  useEffect(() => {
    setLoading(true);
    getFieldOptions()
      .then((response) => {
        if (!response.ok) {
          setErrorRemote(handleErrorLoading(response))
          return {roles: {}, memberships: {}, groups: {}}
        }
        return response.json();
      })
      .then((response) => {
        response.memberships = {'': '--', ...response.memberships}
        response.groups = {'': '--', ...response.groups}
        setFieldOptions(response);
      })
    getUserDetail(user)
      .then((response) => {
        if (response.ok)
          return response.json();
        setErrorRemote(handleErrorLoading(response));
      })
      .then((response) => {
        setName(response?.name);
        setSurname(response?.surname);
        setRegNumber(response?.reg_number);
        setIofId(response?.iof_id);
        if(response?.iof_id == null)
          setIofId(0);
        setSiCards(response?.si_cards);
        setLevelColors(response?.level_colors);
        setIncludedRegion(response?.included_region);
        setWatched(response?.watched);
        if (response?.extended) {
          setBirthdate(response?.birthdate);
          setPersonalNumber(response?.birth_number);
          setNationality(response?.nationality);

          setUsername(response?.username);
          setPhone(response?.phone);
          setMail1(response?.mail1);
          setMail2(response?.mail2);
          setStreet(response?.street);
          setCity(response?.city);
          setZipCode(response?.zip_code);
          setCountry(response?.country);
          if (response?.superuser) {
            setAccountType(response?.account);
            setRole(response?.role);
            setMembership(response?.membership);
            setGroup(response?.group);
            setOfficialName(response?.official_name);
            setOfficialSurname(response?.official_surname);
          }
        }
        setData(response);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setErrorRemote("Připojení neúspěšné...");
      })
  }, []);

  function canBeSubmitted() {
    if (data?.extended) {
      if (name === "" || surname === "" || password !== password2)
        return false;
      if (regNumber === "" && ["A", "B", "E", "F"].includes(accountType))
        return false;
      if (username === "" && ["A", "E", "F"].includes(accountType))
        return false;
      if (mail1 === "" && ["A", "B", "E", "F"].includes(accountType))
        return false;
    }
    return (!processing);
  }

  let handleSubmit = async (e) => {
    e.preventDefault();
    if(password !== password2){
      setError("Hesla se neshodují");
      return;
    }
    let body = {
      "iof_id": iofId,
      "si_cards": siCards,
      "level_colors": levelColors,
      "included_region": includedRegion,
      "watched": watched,
    };
    if (data?.extended) {
      body = Object.assign({}, body, {
        "username": username,
        "password": password,
        "mail1": mail1,
        "mail2": mail2,
        "phone": phone,
        "street": street,
        "city": city,
        "zip_code": zipCode,
        "country": country,
      })
      if (data?.superuser) {
        body = Object.assign({}, body, {
          "name": name,
          "surname": surname,
          "official_name": officialName,
          "official_surname": officialSurname,
          "reg_number": regNumber,
          "account": accountType,
          "role": role,
          "membership": membership,
          "group": group,
          "birthdate": birthdate,
          "birth_number": personalNumber,
          "nationality": nationality,
        })
      }
    }
    setProcessing(true);
    validateEditUser(user, {"username": username, "reg_number": regNumber, "account": accountType})
      .then((response) => {
        if (response.ok) {
          editUser(user, body)
            .then((response) => {
              if (response.ok) {
                const cookie = response.headers.get("X-Csrftoken");
                if (cookie != null)
                  setCsrfToken(cookie);
                setError("");
                setSuccess("Uloženo");
              } else {
                setError(handleErrorSubmit(response, "Nezdařilo se uložit"));
              }
              setProcessing(false);
            })
        } else {
          setError(handleErrorSubmit(response, "Registrační číslo není validní", "Uživatelské jméno"));
          setProcessing(false);
        }
      })
  };

  function handleChangeAccountLock() {
    setConfirmLockChange(false)
    changeAccountLock(user, data?.activated ? 'lock' : 'unlock')
      .then((response) => {
        if (response.ok) {
          setSuccess(data?.activated ? "Účet uzamčen" : 'Účet odemčen');
          response.json().then((response) => setData(response));
        } else {
          setError(handleErrorSubmit(response, "Nezdařilo se upravit"));
        }
      })
  }

  const FormError = () => {
    if(error !== ""){
      return (<Alert variant="danger">{error}</Alert>);
    }
    return null;
  }

  if (errorRemote !== "")
    return <ErrorAlertFullscreen error={errorRemote} />;
  if (loading)
    return <Loader />;

  const SystemData = () => {
    return (<>
      <h4>Údaje {labels.SystemShortName}u</h4>
      {data.superuser && <>
        <Row>
          <Col md xs={6}>
            <FormField label={"Jméno " + labels.SystemShortName} value={name} setValue={setName} valueLimit={64} digits={"none"} isInvalid={name === ""} controlId={"name"}
                       help={"Jméno, se kterým je uživatel zobrazen v " + labels.SystemShortName + "u, na záznam v Orisu nemá vliv"}/>
          </Col>
          <Col md xs={6}>
            <FormField label={"Příjmení " + labels.SystemShortName} value={surname} setValue={setSurname} valueLimit={64} digits={"none"} isInvalid={surname === ""} controlId={"surname"}
                       help={"Příjmení, se kterým je uživatel zobrazen v " + labels.SystemShortName + "u, na záznam v Orisu nemá vliv"}/>
          </Col>
          <Col md>
            <FormField label={"Registrační číslo"} value={regNumber} setValue={setRegNumber} valueLimit={7} digits={"none"} controlId={"registration"}
                       isInvalid={regNumber === "" && ["A", "B", "E", "F"].includes(accountType)}/>
          </Col>
        </Row>
        <Row>
          <Col lg xs={7}>
            <FormFieldAccountType data={data} value={accountType} setValue={setAccountType} controlId={"accountField"} />
          </Col>
          <Col lg xs={5}>
            <FormSelectDict label={"Role"} value={role} setValue={setRole} options={fieldOptions.roles} controlId={"role"}
                            help={<FontAwesomeIcon className="pointing" icon={faQuestionCircle} onClick={() => setGeneralHelp(true)}/>}/>
          </Col>
          <Col lg xs={6}>
            <FormSelectDict label={"Členství"} value={membership} setValue={setMembership} options={fieldOptions.memberships} controlId={"membership"}
                            help={<FontAwesomeIcon className="pointing" icon={faQuestionCircle} onClick={() => setGeneralHelp(true)}/>}/>
          </Col>
          <Col lg xs={6}>
            <FormSelectDict label={"Skupina"} value={group} setValue={setGroup} options={fieldOptions.groups} controlId={"group"}
                            help={<FontAwesomeIcon className="pointing" icon={faQuestionCircle} onClick={() => setGeneralHelp(true)}/>}/>
          </Col>
        </Row>
      </>}
      <Row>
        <Col md>
          <FormField label={"Uživatelské jméno"} value={username} setValue={setUsername} valueLimit={150} digits={"none"} controlId={"username"}
                     disabled={["C", "D"].includes(accountType)} isInvalid={username === "" && ["A", "B", "E", "F"].includes(accountType)}/>
        </Col>
        <Col md>
          <FormFieldPassword label={"Nové heslo"} value={password} setValue={setPassword} showPassword={showPassword} setShowPassword={setShowPassword}
                             placeholder={(["B", "C", "D"].includes(data?.account) && ["A", "E", "F"].includes(accountType)) ? "(vygenerovat)" : "(nezměněno)"}
                             disabled={["B", "C", "D"].includes(accountType)} controlId={"password"}/>
        </Col>
        <Col md>
          <FormField label={"Potvrdit heslo"} value={password2} setValue={setPassword2} type={"password"} digits={"none"} controlId={"confirm"}
                     isInvalid={password !== password2} isValid={password2 !== "" && password === password2} disabled={["B", "C", "D"].includes(accountType)} />
        </Col>
        <hr/>
      </Row>
    </>)
  }

  const PersonalData = () => {
    return (<>
      <h4>Osobní údaje</h4>
      {data.superuser && <>
        <Row>
          <Col xs={6}>
            <FormField label={"Jméno oficiální"} value={officialName} setValue={setOfficialName} valueLimit={64} digits={"none"} controlId={"officialName"}
                       help={"Křestní jméno v oficiální podobě, použito v Orisu"}/>
          </Col>
          <Col xs={6}>
            <FormField label={"Příjmení oficiální"} value={officialSurname} setValue={setOfficialSurname} valueLimit={64} digits={"none"} controlId={"officialSurname"}
                       help={"Příjmení v oficiální podobě, použito v Orisu"}/>
          </Col>
        </Row>
      </>}
      <Row>
        <Col sm={5}>
          <FormField label={"Rodné číslo"} value={personalNumber} setValue={setPersonalNumber} type={"slashed"} valueLimit={11} digits={"none"} disabled={!data?.superuser} controlId={"personalNumber"}/>
        </Col>
        <Col sm={4} xs={7}>
          <FormField label={"Datum narození"} type={"date"} value={birthdate} setValue={setBirthdate} disabled={!data?.superuser} controlId={"birthdate"}/>
        </Col>
        <Col sm={3} xs={5}>
          <FormField label={"Národnost"} value={nationality} setValue={setNationality} valueLimit={2} digits={"none"} disabled={!data?.superuser} controlId={"nationality"}
                     help={"Dvoupísmenná zkratka státu národnosti uživatele"}/>
        </Col>
        <hr/>
      </Row>
    </>)
  }

  const ContactData = () => {
    return (<>
      <h4>Kontaktní údaje</h4>
      <Row>
        <Col>
          <FormField label={"Email 1"} type={"email"} value={mail1} setValue={setMail1} valueLimit={64} digits={"none"}
                     isInvalid={mail1 === "" && ["A", "B", "E", "F"].includes(accountType)} controlId={"email1"}
                     help={"Primární emailová adresa, slouží pro zasílání informací, upomínek, reset zapomenutého hesla apod."}/>
        </Col>
        <Col md>
          <FormField label={"Email 2"} type={"email"} value={mail2} setValue={setMail2} valueLimit={64} digits={"none"} controlId={"email2"}
                     help={"Sekundární emailová adresa, zobrazuje se pouze pro správce v seznamech přihlášek na akce"}/>
        </Col>
        <Col md>
          <FormField label={"Telefon"} value={phone} setValue={setPhone} valueLimit={16} digits={"none"} controlId={"phone"}/>
        </Col>
      </Row>
      <Row>
        <Col lg>
          <FormField label={"Ulice a ČP"} value={street} setValue={setStreet} valueLimit={128} digits={"none"} controlId={"street"}/>
        </Col>
        <Col lg={3} sm>
          <FormField label={"Město"} value={city} setValue={setCity} valueLimit={64} digits={"none"} controlId={"city"}/>
        </Col>
        <Col lg={3} sm={4} xs={7}>
          <FormField label={"PSČ"} value={zipCode} setValue={setZipCode} valueLimit={6} digits={"none"} controlId={"zipCode"}/>
        </Col>
        <Col lg={2} sm={3} xs={5}>
          <FormField label={"Stát (zkr.)"} value={country} setValue={setCountry} valueLimit={2} digits={"none"} controlId={"country"}/>
        </Col>
        <hr/>
      </Row>
    </>)
  }

  setBrowserTabText(user == null ? 'Nastavení' : 'Nastavení | ' + name + ' ' + surname);
  return (
    <PageContainer>
      <Row className="pb-1">
        <Col xs={8}>
          {user == null ? <h1>Můj {labels.SystemShortName} účet</h1> : <h1>Upravit: {name} {surname}</h1>}
        </Col>
        {data?.extended && <Col className="text-end">
          <Row>
            <Col>
              <Link to={`/ucet/${user == null ? "" : user + "/"}prava/`}>
                <Button className="mb-3" variant="secondary"><FontAwesomeIcon icon={faGavel}/> Práva</Button>
              </Link>
            </Col>
            {(data?.superuser && user != null) && <Col md="auto" sm={12} className="text-end">
              {data?.activated ? <Button variant="outline-secondary" title="Zamknout účet" onClick={() => setConfirmLockChange(true)}>
                <FontAwesomeIcon color="green" icon={faLockOpen}/> -> <FontAwesomeIcon icon={faLock}/>
              </Button> : <Button variant="outline-secondary" title="Odemknout účet" disabled={data?.account === "D"} onClick={() => setConfirmLockChange(true)}>
                <FontAwesomeIcon color="red" icon={faLock}/> -> <FontAwesomeIcon icon={faLockOpen}/>
              </Button>}
            </Col>}
          </Row>
        </Col>}
      </Row>
      <FormError/>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md>
            <h4>Závody</h4>
            <Row>
              <Col md={8}>
                <FormField label={"Čipy (oddělené čárkou)"} value={siCards} setValue={setSiCards} controlId={"si"}/>
              </Col>
              <Col md={4}>
                <FormField label={"IOF ID"} type={"number"} value={iofId} setValue={setIofId} valueLimit={10} digits={"none"} controlId={"iof"}/>
              </Col>
              <hr/>
            </Row>
            {user == null && <>
              <h4>Personalizace</h4>
              <Row className="align-items-end">
                <Col md={12}>
                  <FormField label={"Sledované účty (registračky oddělené čárkou)"} value={watched} setValue={setWatched} controlId={"watched"}
                             help={"Sledované účty (primárně členové jiných oddílů) jsou přidány do seznamu klubových přihlášek/startovek (obdobně jako účty s uděleným právem na přihlašování)"}/>
                </Col>
                <Col md>
                  <FormSelectDict label={"Oblast navíc k \"Doporučeným\" (ČR+Č+P+StČ)"} value={includedRegion} setValue={setIncludedRegion}
                                  options={RegionCustomizeOptions} controlId={"includedRegion"}
                                  help={"Oblast navíc bude zahrnuta k ostatním doporučeným oblastem"}/>
                </Col>
                <Col md>
                  <FormCheckbox label={"Barevné označení úrovně závodů v kalendáři"} value={levelColors} setValue={setLevelColors} controlId={"levelColors"}
                                tooltip={"<p>Řádek tabulky se závody tohoto typu bude doplněn o barevný kroužek, v případě více možných úrovní je zobrazena vyšší</p>" +
                                  "<p style='color: magenta'>Štafety/Družstva</p><p style='color: red'>Republikové</p><p style='color: blue'>Béčka</p>"}/>
                </Col>
                <hr/>
              </Row>
            </>}
            {data?.extended && <>{SystemData()}</>}
            {data?.extended && <>{PersonalData()}</>}
            {data?.extended && <>{ContactData()}</>}
          </Col>
          <Col xs={isMobileDevice() ? 12 : "auto"} className="text-end">
            <Button className="mb-3" variant="primary" disabled={!canBeSubmitted()} type="submit">Uložit změny</Button>
          </Col>
        </Row>
      </Form>
      <SuccessAlert title={success} handleClose={() => setSuccess("")}/>
      <LoadingOverlay loading={processing}/>
      <Modal show={generalHelp} onHide={()=>setGeneralHelp(false)}>
        <ModalContentAccount options={fieldOptions} />
      </Modal>
      <Modal show={confirmLockChange} onHide={() => setConfirmLockChange(false)}>
        <Modal.Header closeButton>
          <FontAwesomeIcon icon={faQuestionCircle} size='3x' bounce/> &nbsp;
          <Modal.Title>Skutečně {data?.activated ? "zamknout" : "odemknout"} účet?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Veškeré neuložené změny v nastavení budou zapomenuty
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setConfirmLockChange(false)}>Zavřít</Button>
          <Button variant="primary" onClick={handleChangeAccountLock}>Potvrdit</Button>
        </Modal.Footer>
      </Modal>
    </PageContainer>
  );
};

export default Settings;