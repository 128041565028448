import React, {useEffect} from "react";
import {setBrowserTabText} from "../../helpers/Functions";
import PageContainer from "../../layout/PageContainer";
import {labels} from "../../themeLabels";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";


const Changelog = () => {
  useEffect(() => {
    const scrollingElement = (document.scrollingElement || document.body);
    scrollingElement.scrollTop = 0;
  }, []);

  setBrowserTabText('Changelog');
  return (
    <PageContainer>
      <h1>Changelog</h1>
      <ChangelogContent />
    </PageContainer>
  )

  function ChangelogContent() {
    const ChangeBlock = ({children, title, show=true}) => {
      if (!show)
        return;
      return <div>
        <h4 className="mb-3">{title}</h4>
        <div style={{marginLeft: "3%", marginRight: "3%"}}>{children}</div>
      </div>
    }

    const VersionBlock = ({children, title, date, show=true}) => {
      if (!show)
        return;
      return <div>
        <Row className="align-items-baseline">
          <Col xs="auto">
            <h3 className="mb-3">{title}</h3>
          </Col>
          <Col xs="auto">
            <i>{date}</i>
          </Col>
        </Row>
        <div style={{marginLeft: "3%", marginRight: "3%"}}>
          <ul>
            {children}
          </ul>
        </div>
        <hr/>
      </div>
    }

    return <div>
      <VersionBlock title={"1.14.6"} date={"30.3.2025"}>
        <li>doplněna možnost vynucené aktualizace dat Oris akce</li>
      </VersionBlock>
      <VersionBlock title={"1.14.5"} date={"19.3.2025"} show={labels.CondShowUserBilling}>
        <li>oprava chyby při vystavování potvrzení o platbě při úhradě v předchozím roce</li>
        <li>doplněna zpětná vazba kliknutí na export potvrzení o platbě</li>
      </VersionBlock>
      <VersionBlock title={"1.14.4"} date={"14.3.2025"}>
        <li>adaptována filtrace kategorií dle omezení možnosti přihlašování (verze Orisu 5.8.0)</li>
      </VersionBlock>
      <VersionBlock title={"1.14.3"} date={"7.3.2025"}>
        <li>přidána hromadná změna typu členství</li>
        <li>možnost oblasti Rozšířené (ČR+Č+místní oblasti v Čechách)</li>
        {labels.Theme === "PGP" && <li>domovskou stránkou je dle požadavku Klubová nástěnka</li>}
      </VersionBlock>
      <VersionBlock title={"1.14.2"} date={"5.3.2025"}>
        <li>přidáno vlákno komentářů k BugReportům</li>
      </VersionBlock>
      <VersionBlock title={"1.14.1"} date={"22.2.2025"}>
        <li>optimalizace načítání domovské stránky</li>
      </VersionBlock>
      <VersionBlock title={"1.14.0"} date={"14.2.2025"}>
        <ChangeBlock title={"Rozhraní pro rozesílání emailů"}>
          <p>
            Přímo ze {labels.SystemShortName}u jde nyní odesílat emaily. Přes „Administrace > Emaily > Napsat“ lze posílat emaily mezi členy klubu, přes stránku akce v „Administrace akce > Emaily > Napsat“ lze poslat email přihlášeným na danou akci. Nový email lze napsat s možnostmi základního formátování textu. Nový nebo i již existující email jde poslat libovolné podskupině členů / přihlášených, u již existujících mailů je indikováno, kdo už daný email dostal.
          </p>
        </ChangeBlock>
        <ChangeBlock title={"Export potvrzení o platbě"} show={labels.CondShowUserBilling}>
          <p>
            Na stránce detailu vyúčtování přibyla možnost exportu potvrzení o platbě za danou pohledávku pro konkrétního člena. Export je možný, pokud má daný člen vše uhrazeno, na stránce „jméno člena > Vyúčtování > Detail“ pro klubovou pohledávku v daném roce (kliknutím na pdf ikonu). Pro nadřazené účty je tato možnost k dispozici taky, na detail se dostanout ze svého vyúčtování přes ikonu „účtenky“.
          </p>
        </ChangeBlock>
        <ChangeBlock title={"Změna kategorie a přihlášených etap"} show={labels.CondApplyToOris}>
          <p>
            Díky úpravám v Oris API je už možné bezpečně provádět změnu kategorie a přihlášených etap přímo z Tapisu. Změna je prováděna jako aktualizace, tedy v případě zaplnění akce nedojde k přeřazení mezi náhradníky.
          </p>
        </ChangeBlock>
      </VersionBlock>
      <VersionBlock title={"1.13.4"} date={"30.1.2025"}>
        <li>do kalendáře akcí přidána možnost "celý rok" pro měsíc</li>
      </VersionBlock>
      <VersionBlock title={"1.13.3"} date={"28.1.2025"}>
        <li>optimalizace načítání domovské stránky</li>
      </VersionBlock>
      <VersionBlock title={"1.13.2"} date={"22.1.2025"}>
        <li>přidána kontrola možnosti změny typu účtu pro správce</li>
      </VersionBlock>
      <VersionBlock title={"1.13.1"} date={"19.1.2025"}>
        <li>opravy bugů z verze 1.13.0</li>
      </VersionBlock>
      <VersionBlock title={"1.13.0"} date={"18.1.2025"}>
        <ChangeBlock title={"Sjednocení konceptu anonymní přihlášky"}>
          <p>
            Pro všechny typy klubových akcí (nově pro typ Trénink) je k dispozici anonymní přiháška přístupná přes příslušný odkaz i pro lidi bez přístupu do systému
          </p>
        </ChangeBlock>
        <ChangeBlock title={"Doplněná správa rolí, skupin a členství"}>
          <p>
            Pro role, skupiny a členství přidána možnost mazání prázných entit
          </p>
        </ChangeBlock>
        <ChangeBlock title={"Častější aktualizace seznamu přihlášených"} show={!labels.CondApplyToOris}>
          <p>
            Systém nyní při každém načtení stránky akce odstraňuje ze své paměti přihlášky zrušené v Orisu
          </p>
        </ChangeBlock>
        <li>různé opravy menších chyb</li>
      </VersionBlock>
      <i>Starší verze a změny v nich zatím nemají sepsanou dokumentaci v češtině...</i>
    </div>
  }
}

export default Changelog;