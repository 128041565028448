import {Link, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {YearOptions, handleErrorLoading, parseDate, setBrowserTabText} from "../../helpers/Functions";
import {getBillingSummary, getPaymentQRCode, getUserPayments} from "../../dao";
import Loader from "../../components/overlays/Loader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCoins, faMoneyBills, faReceipt} from "@fortawesome/free-solid-svg-icons";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import {Image} from "react-bootstrap";
import ErrorAlertFullscreen from "../../components/overlays/ErrorAlertFullscreen";
import NoYesIndicator from "../../components/visual/NoYesIndicator";
import PageContainer from "../../layout/PageContainer";
import FormSelectArray from "../../components/form/FormSelectArray";
import {MobileWidthLimitSM} from "../../helpers/Constants";
import {labels} from "../../themeLabels";


const BillingSummary = () => {
  const {user} = useParams();
  const [errorRemote, setErrorRemote] = useState("");
  const [data, setData] = useState([]);
  const [paymentData, setPaymentData] = useState({payments: [], history: []});
  const [imageQRCode, setImageQRCode] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [year, setYear] = useState((new Date()).getFullYear());
  const [isMobile, setIsMobile] = useState(window.innerWidth <= MobileWidthLimitSM);

  useEffect(() => {
    const handleOrientationChange = () => {setIsMobile(window.innerWidth <= MobileWidthLimitSM)};
    window.addEventListener('resize', handleOrientationChange);
    return () => {window.removeEventListener('resize', handleOrientationChange)};
  }, []);

  useEffect(() => {
    setLoading(true);
    getBillingSummary(user)
      .then((response) => {
        if (!response.ok) {
          setErrorRemote(handleErrorLoading(response));
          return [];
        }
        return response.json();
      })
      .then((response) => {
        setData(response);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setErrorRemote("Připojení neúspěšné...");
      });
    getPaymentQRCode(user)
      .then((response) => response.blob())
      .then((blob) => {
        // Convert the blob into a data URL
        const imageUrl = URL.createObjectURL(blob);
        setImageQRCode(imageUrl);
      });
  }, [user]);

  useEffect(() => {
    getUserPayments(user, year)
      .then((response) => {
        if (!response.ok) {
          setErrorRemote(handleErrorLoading(response));
          return {};
        }
        return response.json();
      })
      .then((response) => {
        setPaymentData(response);
      })
      .catch((err) => {
        console.log(err);
        setErrorRemote("Připojení neúspěšné...");
      })
  }, [user, year]);

  if (errorRemote !== "")
    return <ErrorAlertFullscreen error={errorRemote} />;
  if (loading)
    return <Loader/>;

  setBrowserTabText(user == null ? 'Vyúčtování' : 'Vyúčtování | ' + data?.profile?.full_name);
  return (
    <PageContainer>
      {user == null ? <h1><FontAwesomeIcon icon={faCoins}/> Vyúčtování</h1> :
        <h1><FontAwesomeIcon icon={faCoins}/> Vyúčtování: {data?.profile?.full_name}</h1>}
      <br/>
      <Summary />
      <br/>
      <TableSection />
      <hr/>
      <Row className="align-items-center mb-3">
        <Col className="mb-0"><h3><FontAwesomeIcon icon={faMoneyBills}/> Platby a historie </h3></Col>
        <Col md={5}>
          <FormSelectArray size="sm" value={year} setValue={setYear} options={YearOptions()} className="mb-1" controlId={"year0"} />
        </Col>
      </Row>
      <Row>
        <Col md>
          <h4>Platby</h4>
          <p>Poslední nahrané platby jsou ze dne <b>{parseDate(data?.summary?.last_added)}</b>.</p>
          <PaymentTable/>
        </Col>
        <Col md>
          <h4>Historie účtování</h4>
          <BilledHistoryTable />
        </Col>
      </Row>
    </PageContainer>
  );

  function Summary() {
    const DebtCard = () => {
      const debtColorStyle = data?.summary?.difference > 0 ? {color: "red"} : {color: "green"};
      return (
        <Card className="mb-3" style={{height: "155px"}}>
          <Card.Body style={{paddingTop: "20px"}}>
            <Row>
              <Col><Card.Title>Aktuální dluh:</Card.Title></Col>
              <Col><Card.Title style={debtColorStyle}>{data?.summary?.difference} Kč</Card.Title></Col>
            </Row>
            <hr/>
            <Row><Col>Číslo účtu:</Col><Col>{labels.HomeClubBankAccount}</Col></Row>
            <Row><Col>Variabilní symbol:</Col><Col>{data?.profile?.variable_symbol}</Col></Row>
            <Card.Text></Card.Text>
          </Card.Body>
        </Card>
      )
    }
    const ButtonCard = () => {
      const link = user == null ? "/ucet/vyuctovani/detail" : "/ucet/"+user+"/vyuctovani/detail";
      const showingQR = imageQRCode !== undefined && data.summary.difference > 0;
      return (
        <Card className="mb-3" style={{height: "155px"}}>
          <Card.Body style={{padding: 0}}>
            <Row className="align-items-center">
              <Col>
                {showingQR && <Image src={imageQRCode} height="148px" width="148px" alt="QR kod" style={{marginTop: "3px", marginLeft: "3px"}} title="QR kód pro zaplacení aktuálního dluhu"/>}
              </Col>
              <Col className="align-items-end" style={{marginTop: showingQR ? "0px" : "30px", marginRight: "15px"}}>
                <p>Vyúčtování po položkách:</p>
                <Link to={link}><Button className="mb-3 tapis-95"><FontAwesomeIcon icon={faReceipt}/> Detail {!isMobile && "vyúčtování"}</Button></Link>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      )
    }
    return <Row><Col md><DebtCard/></Col><Col md><ButtonCard/></Col></Row>;
  }

  function TableSection() {
    if (data.years[year]?.wards === undefined)
      return <SummaryTable />;
    if (data?.years[year].wards.length < 1)
      return <SummaryTable />;
    return <><SummaryTable /><WardContent /></>;
  }

  function SummaryTable() {
    const TableRow = ({element}) => {
      return (
        <tr>
          <td>{element?.year}</td>
          {isMobile ? <>
            <td>
              {element?.sum_oris + element?.sum_event_fines}
              <hr className="mt-1 mb-1"/>
              {element?.sum_services}
            </td>
            <td>
              {element?.sum_camps}
              <hr className="mt-1 mb-1"/>
              {element?.sum_club}
            </td>
          </> : <>
            <td>{element?.sum_oris + element?.sum_event_fines}</td>
            <td>{element?.sum_services}</td>
            <td>{element?.sum_camps}</td>
            <td>{element?.sum_club}</td>
          </>}
          <td><b>{element?.expenses}</b> {isMobile && <NoYesIndicator condition={element?.paid}/>}</td>
          {!isMobile && <td><NoYesIndicator condition={element?.paid}/></td>}
        </tr>
      )
    }

    const rows = [];
    if (data?.years !== undefined) {
      for (let y in data?.years)
        rows.push(<TableRow element={data?.years[y]} key={y}/>);
    }

    return (
      <>
        <h4 className="mb-2">Výdaje po letech</h4>
        <p className="mb-2"><i>Sečtené výdaje v roce po kategoriích, rozpis po položkách najdeš v Detailu vyúčtování.</i></p>
        <Table bordered responsive striped hover>
          <thead>
          <tr>
            <th>Rok</th>
            {isMobile ? <>
              <th>Start. | Služ.</th>
              <th>Sous. | Klub.</th>
            </> : <>
              <th title="startovné + doplatky">Startovné</th>
              <th>Služby</th>
              <th>Soustředění</th>
              <th title="Ostatní klubové položky = členské příspěvky, nákupy oblečení apod.">Ost. klub.</th>
            </>}
            <th>Σ za rok</th>
            {!isMobile && <th title="Zaplaceno vše za daný rok a předchozí roky">Zapl. vše</th>}
          </tr>
          </thead>
          <tbody>
          {rows}
          </tbody>
        </Table>
      </>
    )
  }

  function WardContent() {
    return (<>
      <Row className="align-items-center">
        <Col className="mb-0"><h4>Rozpočtené závazky za rok </h4></Col>
        <Col md={5}>
          <FormSelectArray size="sm" value={year} setValue={setYear} options={YearOptions()} className="mb-1" controlId={"year"}/>
        </Col>
      </Row>
      <p><i>Závazky rozdělené mezi nadřazený účet a přidružené účty</i></p>
      <WardTable/>
    </>);

    function WardTable() {
      const rows = [];
      data?.years[year].wards.forEach((row) => {
        rows.push(<tr key={row.id}>
          {isMobile ?
            (<td>{row.full_name}<br/><i>{row.reg_number}</i></td>) :
            (<>
              <td>{row.reg_number}</td>
              <td>{row.full_name}</td>
            </>)
          }
          {isMobile ? <>
            <td>{row?.sum_oris + row?.sum_event_fines + row?.sum_services}</td>
          </> : <>
            <td>{row?.sum_oris}</td>
            <td>{row?.sum_event_fines}</td>
            <td>{row?.sum_services}</td>
          </>}
          {isMobile ? <>
            <td>{row?.sum_camps}<hr className="mt-1 mb-1"/>{row?.sum_club}</td>
          </> : <>
            <td>{row?.sum_camps}</td>
            <td>{row?.sum_club}</td>
          </>}
          <td><b>{row?.expenses}</b></td>
          <td className="td-w-icon"><Link to={'/ucet/' + row.id + '/vyuctovani/detail/' + year}><FontAwesomeIcon icon={faReceipt} size="lg"/></Link></td>
        </tr>);
      });

      return (
        <Table striped bordered hover={labels.CondElemTableHover} responsive>
          <thead>
          <tr>
            {isMobile ?
              <th>Osoba</th> : <>
                <th>Reg.</th>
                <th>Jméno</th>
              </>
            }
            {isMobile ? <>
              <th>Závody</th>
            </> : <>
              <th title="startovné">Start.</th>
              <th title="doplatky">Dopl.</th>
              <th title="doplňkové služby">Služby</th>
            </>}
            {isMobile ? <>
              <th>Sous. | Klub.</th>
            </> : <>
              <th>Sous.</th>
              <th>Klub.</th>
            </>}
            <th>{isMobile ? <>Σ</> : <>Celkem</>}</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          {rows}
          </tbody>
        </Table>
      );
    }
  }

  function PaymentTable() {
    const rows = [];
    for (const i in paymentData?.payments) {
      const element = paymentData?.payments[i];
      rows.push(
        <tr key={element.id}>
          <td>{parseDate(element.date)}</td>
          <td>{element.amount}</td>
        </tr>);
    }

    if (rows.length > 0) {
      return (<>
        <Table striped bordered hover={labels.CondElemTableHover} responsive>
          <thead>
          <tr>
            <th>Datum</th>
            <th>Částka</th>
          </tr>
          </thead>
          <tbody>
          {rows}
          </tbody>
        </Table>
        <p><i>Pokud ve výpisu některá z plateb chybí, pak zatím nebyla nahrána nebo v platbě nebyl uveden správný
          variabilní symbol. Pro opravu platby se špatným variabilním symbolem kontaktuj
          administrátora.</i></p>
      </>);
    }
    return (<p><i>Žádné platby</i></p>);
  }

  function BilledHistoryTable() {
    const rows = [];
    for (const i in paymentData?.history) {
      const element = paymentData?.history[i];
      rows.push(
        <tr key={element.id}>
          <td>{element.title}</td>
          <td>{element.fee}</td>
          <td>{parseDate(element.date)}</td>
        </tr>);
    }

    if (rows.length > 0) {
      return (<>
        <p><i>Historie přičtení pohledávky do osobního vyúčtování</i></p>
        <Table striped bordered hover={labels.CondElemTableHover} responsive>
          <thead>
          <tr>
            <th>Pohledávka</th>
            <th>Výše</th>
            <th>Přičtena</th>
          </tr>
          </thead>
          <tbody>
          {rows}
          </tbody>
        </Table>
      </>);
    }
    return (<p><i>Žádná historie</i></p>);
  }
};

export default BillingSummary;
