import Table from "react-bootstrap/Table";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import React, {useEffect, useRef} from "react";
import { useState } from "react";
import {getFieldOptions, getMembersAdmin} from "../../dao";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faUserPlus, faCoins, faCog, faPeopleArrows, faChartSimple, faUserGear, faLock, faEnvelope} from '@fortawesome/free-solid-svg-icons';
import {isMobileDevice} from "../../helpers/Device";
import Card from "react-bootstrap/Card";
import {handleErrorLoading, setBrowserTabText} from "../../helpers/Functions";
import PageContainer from "../../layout/PageContainer";
import ErrorAlertFullscreen from "../../components/overlays/ErrorAlertFullscreen";
import {AdminAccountTypeOptions, API_URL} from "../../helpers/Constants";
import {handleCsvExport} from "../../helpers/Exports";
import LoadingCard from "../../components/overlays/LoadingCard";
import FormSelectDict from "../../components/form/FormSelectDict";
import SearchBar from "../../components/form/SearchBar";
import LoadingIcon from "../../components/overlays/LoadingIcon";
import CondElem from "../../components/parts/CondElem";
import {labels} from "../../themeLabels";
import Loader from "../../components/overlays/Loader";
import Modal from "react-bootstrap/Modal";
import FormCheckbox from "../../components/form/FormCheckbox";

const ClubAdmin = () => {
  const [data, setData] = useState({members: [], logged: {}});
  const [loading, setLoading] = useState(true);
  const [loadingData, setLoadingData] = useState(true);
  const [errorRemote, setErrorRemote] = useState("");
  const [fieldOptions, setFieldOptions] = useState({roles: {}, memberships: {}, groups: {}});
  const [accountType, setAccountType] = useState("*");
  const [role, setRole] = useState("*");
  const [officialNames, setOfficialNames] = useState(true);
  const [includeContact, setIncludeContact] = useState(true);
  const [includeSystem, setIncludeSystem] = useState(true);
  const [showExport, setShowExport] = useState(false);
  const [isExporting, setIsExporting] = useState(false);

  useEffect(() => {
    getFieldOptions()
      .then((response) => {
        if (!response.ok) {
          setErrorRemote(handleErrorLoading(response))
          return {roles: {}, memberships: {}, groups: {}}
        }
        return response.json();
      })
      .then((response) => {
        response.roles = {'*': 'vše', ...response.roles}
        setFieldOptions(response);
      })
  }, []);

  useEffect(() => {
    setLoadingData(true);
    getMembersAdmin(accountType, role)
      .then((response) => {
        if (!response.ok) {
          setErrorRemote(handleErrorLoading(response));
          return {members: [], logged: {}}
        }
        return response.json()
      })
      .then((response) => {
        setData(response);
        setLoading(false);
        setLoadingData(false);
      })
      .catch((err) => {
        console.log(err);
        setErrorRemote("Připojení neúspěšné...");
      })
  }, [accountType, role]);

  if (errorRemote !== "")
    return <ErrorAlertFullscreen error={errorRemote}/>;
  if (loading) {
    return <Loader/>;
  }
  if (isExporting)
    return <LoadingCard text={"Exportování dat"} />

  setBrowserTabText('Administrace')
  return (
    <PageContainer>
      <Row className="mb-2">
        <Col><h1>Administrace</h1></Col>
        {data.logged?.perms?.administrate && <Col xs="auto"><Link to={`/admin/server`}><Button variant="secondary"><FontAwesomeIcon icon={faCog}/> Server</Button></Link></Col>}
      </Row>
      <Row>
        {data.logged?.perms?.edit_events ? <Col lg className="mb-3"><EventCard/></Col> : <></>}
        {data.logged?.perms?.administrate ? <Col lg className="mb-3"><UserCard/></Col> : <></>}
        <Col lg className="mb-3"><TableCard/></Col>
      </Row>
      <MemberTable/>
      <Modal show={showExport} onHide={() => setShowExport(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Exportovat členy</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormSelectDict label={"Typ"} value={accountType} setValue={setAccountType} options={AdminAccountTypeOptions} inline={true} disabled={true} controlId={"accountExport"} />
          {(accountType === '*' || accountType === 'A') &&
            <FormSelectDict label={"Role"} value={role} setValue={setRole} options={fieldOptions.roles} inline={true} disabled={true} controlId={"roleExport"} />
          }
          <hr/>
          <p><b>Obsah CSV:</b></p>
          <FormCheckbox label={"Oficiální verze jmen"} value={officialNames} setValue={setOfficialNames} controlId={"officialNames"}/>
          <FormCheckbox label={"Kontaktní údaje"} value={includeContact} setValue={setIncludeContact} controlId={"includeContact"}/>
          <FormCheckbox label={"Systémová nastavení"} value={includeSystem} setValue={setIncludeSystem} controlId={"includeSystem"}/>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowExport(false)}>Zavřít</Button>
          <Button variant="primary" onClick={() => {
            handleCsvExport(API_URL + "export-club?account=" + accountType + "&role=" + role + "&official=" + (officialNames ? 1 : 0) +
              "&contact=" + (includeContact ? 1 : 0) + "&system=" + (includeSystem ? 1 : 0), 'ucty_' + (new Date()).toISOString() + '.csv', setIsExporting);
            setShowExport(false);
          }}>Exportovat</Button>
        </Modal.Footer>
      </Modal>
    </PageContainer>
  );

  function EventCard() {
    return (
      <Card className="mb-3 h-100">
        <Card.Body>
          <Card.Title>Nová akce</Card.Title>
          <Row>
            <Col xs="auto"><Button className="mb-2" href={`/admin/pridat/trenink`}>Trénink</Button></Col>
            <Col xs="auto"><Button className="mb-2" href={`/admin/pridat/soustredeni`} variant="secondary">Soustředění</Button></Col>
            <Col xs="auto"><Button className="mb-2" href={`/admin/pridat/jina`} variant="outline-secondary">Jiná</Button></Col>
          </Row>
        </Card.Body>
      </Card>
    )
  }

  function UserCard() {
    return (
      <Card className="mb-3 h-100">
        <Card.Body>
          <Row className="align-items-center">
            <Card.Title>Členové klubu</Card.Title>
            <Col xs="auto"><Button className="mb-2" href={`/admin/ucty/pridat`}><FontAwesomeIcon icon={faUserPlus} /> Přidat</Button></Col>
            <Col xs="auto"><Button className="mb-2" href={`/admin/ucty/rozsirene`} variant="secondary" title="Rozšířená správa účtů"><FontAwesomeIcon icon={faUserGear} /> Rozšířené</Button></Col>
            <Col xs="auto"><Button className="mb-2" href={`/admin/emaily`} variant="outline-primary"><FontAwesomeIcon icon={faEnvelope}/> Emaily</Button></Col>
            {/*<Col xs="auto"><Link to={`/admin/ucty/upravit`}>Upravit z csv</Link></Col>*/}
          </Row>
        </Card.Body>
      </Card>
    )
  }

  function TableCard() {
    const downloadLinkRef = useRef(null);
    return (
      <Card className="mb-3 h-100">
        <Card.Body>
          <Row className="align-items-end">
            <Col xs={4} lg={6} xl={4}>
              <FormSelectDict label={"Typ účtu"} value={accountType} setValue={setAccountType} options={AdminAccountTypeOptions} disabled={loadingData} className={"mb-2"} controlId={"account"} />
            </Col>
            <Col xs={4} lg={6} xl={4}>
              {(accountType === '*' || accountType === 'A') &&
                <FormSelectDict label={"Role"} value={role} setValue={setRole} options={fieldOptions.roles} disabled={loadingData} className={"mb-2"} controlId={"role"} />
              }
            </Col>
            <Col xs="auto">
              <a href="#" ref={downloadLinkRef} style={{display: 'none'}} download="file.csv">Download</a>
              <Button variant="link" className="ps-0" onClick={() => setShowExport(true)}>Export</Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    )
  }

  function MemberTable() {
    const [filteredPeople, setFilteredPeople] = useState(data.members);

    if (loadingData)
      return <LoadingIcon />;

    const TableRow = ({element}) => {
      return (
        <tr>
          <td><CondElem href={`/ucet/${element.id}`} linked={data.logged?.perms?.view_users && labels.CondClubAdminPageTableLinks}>{element.reg_number}</CondElem></td>
          <td><CondElem href={`/ucet/${element.id}/nastaveni/`} linked={labels.CondClubAdminPageTableLinks} hidden={true}
                        showIcon={!element?.activated && element.account !== "D"} suffixIcon={<FontAwesomeIcon color="red" icon={faLock}/>}>{element.full_name}</CondElem></td>
          {!isMobileDevice() && <td>{element.mail1}</td>}
          <td>{element.account}</td>
          <td>{element.role}</td>
          {!isMobileDevice() && <td>{element.membership}</td>}
          <td align={"center"}><Link to={`/ucet/${element.id}/nastaveni/`}><FontAwesomeIcon icon={faCog} size="lg"/></Link></td>
          <td align={"center"}>
            <Link to={`/ucet/${element.id}/opravneni/`}>
              <FontAwesomeIcon icon={faPeopleArrows} className={(element.account === "B" && element?.rights_to === 0) ? "link-like-marked" :"link-like"} size="lg"/>
            </Link>
          </td>
          {(!isMobileDevice() && data.logged?.perms?.use_billing) &&
            <td align={"center"}><Link to={`/ucet/${element.id}/vyuctovani/`}><FontAwesomeIcon icon={faCoins} size="lg"/></Link></td>}
        </tr>
      )
    }

    const rows = [];
    for (let i in filteredPeople) {
      const obj = filteredPeople[i];
      rows.push(<TableRow element={obj} key={i}/>);
    }

    return (<>
      <SearchBar data={data.members} setFilteredPeople={setFilteredPeople}/>
      <Table striped bordered hover={labels.CondElemTableHover} responsive>
        <thead>
        <tr>
          <th><CondElem showIcon={data.logged?.perms?.view_users && labels.CondClubAdminPageTableLinks} suffixIcon={<FontAwesomeIcon icon={faChartSimple}/>}>Reg.</CondElem></th>
          <th>Jméno</th>
          {!isMobileDevice() && <th>Email primární</th>}
          <th title="Typ účtu">T</th>
          <th title="Role">R</th>
          {!isMobileDevice() && <th title="Členství">Č</th>}
          <th title="Nastavení">Nast.</th>
          <th title="Oprávnění">O.</th>
          {(!isMobileDevice() && data.logged?.perms?.use_billing) && <th>Vyúč.</th>}
        </tr>
        </thead>
        <tbody>
        {rows}
        </tbody>
      </Table>
      <p><i>Filtru odpovídá <b>{rows.length}</b> záznamů</i></p>
    </>)
  }
};


export default ClubAdmin;