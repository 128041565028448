import React, {useEffect, useState} from "react";
import {getBugDetail, postBugComment} from "../../dao";
import {handleErrorLoading, handleErrorSubmit, parseDateTime, setBrowserTabText} from "../../helpers/Functions";
import ErrorAlertFullscreen from "../../components/overlays/ErrorAlertFullscreen";
import PageContainer from "../../layout/PageContainer";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import AutoFormat from "../../components/visual/AutoFormat";
import Card from "react-bootstrap/Card";
import {useParams} from "react-router-dom";
import Loader from "../../components/overlays/Loader";
import PageHeading from "../../layout/PageHeading";
import Button from "react-bootstrap/Button";
import {Modal} from "react-bootstrap";
import {isMobileDevice} from "../../helpers/Device";
import Form from "react-bootstrap/Form";
import ErrorAlert from "../../components/overlays/ErrorAlert";
import SuccessAlert from "../../components/overlays/SuccessAlert";
import FormCheckbox from "../../components/form/FormCheckbox";
import LoadingOverlay from "../../components/overlays/LoadingOverlay";


const BugDetail = () => {
  const {bugId} = useParams();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState("");
  const [errorRemote, setErrorRemote] = useState("");
  const [success, setSuccess] = useState("");
  const [showAddComment, setShowAddComment] = useState(false);
  const [comment, setComment] = useState("");
  const [sendEmail, setSendEmail] = useState(false);

  useEffect(() => {
    getBugDetail(bugId)
      .then((response) => {
        if (!response.ok) {
          setErrorRemote(handleErrorLoading(response));
          return {};
        }
        return response.json();
      })
      .then((response) => {
        setData(response);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setErrorRemote("Připojení neúspěšné...");
      })
  }, []);

  let handleSubmit = async (e) => {
    e.preventDefault();
    setProcessing(true);
    setShowAddComment(false);
    postBugComment(bugId, comment, sendEmail)
      .then((response) => {
        if(response.ok){
          setSuccess("Komentář přidán");
          setComment("");
          response.json().then((r) => setData(r));
        }else{
          setError(handleErrorSubmit(response, "Nezdařilo se přidat"));
        }
        setProcessing(false);
      })
  };

  if (errorRemote !== "")
    return <ErrorAlertFullscreen error={errorRemote} />;
  if (loading)
    return <Loader />;

  setBrowserTabText('Nahlášení | ' + data?.title);
  return (
    <PageContainer>
      <PageHeading heading={"Nahlášení: " + data?.title} label={!data?.owner ? "Správa nahlášení" : "Moje nahlášení"}
                   link={!data?.owner ? `/admin/bugy` : `/bug/nahlasene`} />
      <CommentsSection />
      <AddCommentButton />
      <Modal show={showAddComment} onHide={() => setShowAddComment(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Přidat komentář</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId={"comment"}>
              <Form.Control type="text" as="textarea" rows={isMobileDevice() ? 9 : 6} value={comment} onChange={(e) => setComment(e.target.value)} />
            </Form.Group>
            {!data?.owner && <Form.Group controlId={"sendEmail"}>
              <FormCheckbox label={"Odeslat email notifikaci o komentáři"} value={sendEmail} setValue={setSendEmail} tooltip={"Autor/ka hlášení obdrží email se zněním komentáře"} />
            </Form.Group>}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowAddComment(false)}>Zavřít</Button>
          <Button variant="primary" onClick={handleSubmit}>Přidat</Button>
        </Modal.Footer>
      </Modal>
      <ErrorAlert error={error} handleClose={()=> setError("")}/>
      <SuccessAlert title={success} handleClose={()=> setSuccess("")}/>
      <LoadingOverlay loading={processing}/>
    </PageContainer>
  )

  function AddCommentButton() {
    if (data?.archived)
      return;
    return <Row>
      <Col className="text-center">
        <Button onClick={() => setShowAddComment(true)} disabled={processing}>Přidat komentář</Button>
      </Col>
    </Row>
  }

  function CommentsSection() {
    const rows = [];
    const CommentContent = ({author, content, timestamp, align="left"}) => {
      return <Row className="mb-2">
        {align === "right" && <Col xs={3} sm={4} md={5} lg={6} />}
        <Col xs={9} sm={8} md={7} lg={6}>
          <Card>
            <Card.Body style={{textAlign: align}}>
              <Row className="mb-2">
                <Col><i>{author.reg_number} – {author.full_name}</i></Col>
              </Row>
              <Row className="mb-2">
                <Col><AutoFormat text={content}/></Col>
              </Row>
              <Row>
                <Col>{parseDateTime(timestamp)}</Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        {align === "left" && <Col xs={3} sm={4} md={5} lg={6} />}
      </Row>
    }

    rows.push(<div key={"0"}>
      <CommentContent author={data.reporter} content={data.detail} timestamp={data.timestamp}/>
    </div>)

    for (const i in data?.comments) {
      const comment = data.comments[i];
      rows.push(<div key={comment.id}>
        <CommentContent author={comment.author} content={comment.body} timestamp={comment.timestamp}
                        align={comment.author.id === data.reporter.id ? "left" : "right"}/>
      </div>)
    }

    // if archived then show appropriate message
    if (data?.archived) {
      rows.push(<div key={"arch"}>
        <Row className="mb-2">
          <Col xs={3} sm={4} md={5} lg={6} />
          <Col xs={9} sm={8} md={7} lg={6}>
            <Card>
              <Card.Body style={{textAlign: "right"}}>
                <b><i>Nahlášení označeno jako zpracované a uzavřené</i></b>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>)
      return rows;
    }

    // if only report without any reactions
    if (rows.length === 1) {
      rows.push(<div key={"1"}>
        <Row className="mb-2">
          <Col xs={3} sm={4} md={5} lg={6} />
          <Col xs={9} sm={8} md={7} lg={6}>
            <Card>
              <Card.Body style={{textAlign: "right"}}>
                <i>Komentář zatím nepřidán...</i>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>)
    }

    return rows;
  }
}

export default BugDetail;