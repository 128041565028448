import PageContainer from "../../layout/PageContainer";
import {handleErrorLoading, handleErrorSubmit, parseDateTime, setBrowserTabText} from "../../helpers/Functions";
import {deleteBugReport, getBugReports, putBugReport} from "../../dao";
import React, {useEffect, useState} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import SuccessAlert from "../../components/overlays/SuccessAlert";
import ErrorAlert from "../../components/overlays/ErrorAlert";
import ErrorAlertFullscreen from "../../components/overlays/ErrorAlertFullscreen";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBoxArchive, faBoxOpen, faComments, faQuestionCircle, faTrash} from "@fortawesome/free-solid-svg-icons";
import Modal from "react-bootstrap/Modal";
import AutoFormat from "../../components/visual/AutoFormat";
import FormCheckbox from "../../components/form/FormCheckbox";
import Table from "react-bootstrap/Table";


const BugsReported = () => {
  const [data, setData] = useState([]);
  const [error, setError] = useState("");
  const [errorRemote, setErrorRemote] = useState("");
  const [success, setSuccess] = useState("");
  const [archived, setArchived] = useState(false);
  const [toDelete, setToDelete] = useState(null);
  const [toShow, setToShow] = useState(null);

  useEffect(() => {
    loadData();
  }, [archived]);

  function loadData() {
    getBugReports(archived)
      .then((response) => {
        if (!response.ok) {
          setErrorRemote(handleErrorLoading(response));
          return [];
        }
        return response.json();
      })
      .then((response) => {
        setData(response);
      })
      .catch((err) => {
        console.log(err);
        setErrorRemote("Připojení neúspěšné...");
      })
  }

  function deleteReport(id) {
    deleteBugReport(id)
      .then((response) => {
        if (response.ok) {
          setSuccess("Smazáno / Archivováno");
          loadData();
        } else {
          setError(handleErrorSubmit(response));
        }
        setToDelete(null);
      })
  }

  function unarchiveReport(id) {
    putBugReport(id)
      .then((response) => {
        if (response.ok) {
          setSuccess("Archivovace zrušena");
          loadData();
        } else {
          setError(handleErrorSubmit(response));
        }
        setToDelete(null);
      })
  }

  if (errorRemote !== "")
    return <ErrorAlertFullscreen error={errorRemote} />;

  setBrowserTabText('Nahlášené chyby');
  return (
    <PageContainer>
      <h1>Nahlášené chyby</h1>
      <Row className="mb-2">
        <Col>
          <FormCheckbox type={"switch"} label={"Archivované"} value={archived} setValue={setArchived}/>
        </Col>
      </Row>
      <Table striped>
        <tbody>
        <ReportsSection />
        </tbody>
      </Table>
      <Modal show={toDelete != null} onHide={()=>setToDelete(null)}>
        <Modal.Header closeButton>
          <FontAwesomeIcon icon={faQuestionCircle} size='3x' bounce /> &nbsp;
          <Modal.Title>{toDelete?.archived ? "Smazat" : "Archivovat"}?</Modal.Title>
        </Modal.Header>
        <Modal.Body>{toDelete != null && <>{toDelete.title}</>}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=>setToDelete(null)}>
            Zavřít
          </Button>
          <Button variant="primary" onClick={()=>deleteReport(toDelete.id)}>
            {toDelete?.archived ? "Smazat" : "Archivovat"}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={toShow != null} onHide={()=>setToShow(null)}>
        <Modal.Header closeButton>
          <Modal.Title>{toShow?.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped>
            <tbody>
            <tr key={"brc"+toShow?.id}>
              <td>
                {parseDateTime(toShow?.timestamp)} <i>{toShow?.reporter?.reg_number} – {toShow?.reporter?.full_name}</i><br/>
                <AutoFormat text={toShow?.detail}/>
              </td>
            </tr>
            {toShow?.comments.map((comment) => {
              return <tr key={"bc"+comment.id}>
                <td>
                  {parseDateTime(comment?.timestamp)} <i>{comment?.author?.reg_number} – {comment?.author?.full_name}</i><br/>
                  <AutoFormat text={comment.body}/>
                </td>
              </tr>
            })}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
      <ErrorAlert error={error} handleClose={()=> setError("")}/>
      <SuccessAlert title={success} handleClose={()=> setSuccess("")}/>
    </PageContainer>
  )

  function ReportsSection() {
    const rows = [];
    data.forEach((record)=>{
      rows.push(
        <tr key={record.id}>
          <td>
            <Row>
              <Col>{record?.archived && <><FontAwesomeIcon icon={faBoxArchive} title="Archivováno"/> </>} <a href={`/bug/${record.id}`}><b>{record.title}</b></a></Col>
              <Col xs="auto"><FontAwesomeIcon icon={faComments} className="pointing" title="Komentáře" color={(record.comments.length === 0 && !record?.archived) ? "red" : "inherit"} onClick={() => setToShow(record)}/> {record.comments.length}</Col>
            </Row>
            <Row className="mb-2"><Col><AutoFormat text={record.detail}/></Col></Row>
            <Row>
              <Col><i>{record.reporter.reg_number} – {record.reporter.full_name}</i></Col>
            </Row>
            <Row>
              <Col>{parseDateTime(record.timestamp)} {record?.archived && <><FontAwesomeIcon className="pointing" icon={faBoxOpen}
                                                                                             onClick={() => unarchiveReport(record.id)}/> </>}<FontAwesomeIcon
                className="pointing" icon={record?.archived ? faTrash : faBoxArchive} onClick={() => setToDelete(record)}/></Col>
            </Row>
          </td>
        </tr>
      );
    });
    return rows;
  }
}

export default BugsReported;