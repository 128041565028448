import {labels, currentTheme, ApiUrl} from "../themeLabels";

const MAP_API_KEY = 'g7MvvtrQRW8QQf4r4NIrvxuJX68RSCvu9cyRxqnsGds';
const API_URL = ApiUrl;
const ORIS_URL = "https://oris.orientacnisporty.cz/"
const TRACKING_URL = "https://tulospalvelu.fi/gps/"
const Theme = currentTheme;
const StartYearSystem = labels.SystemStartYear;
const StartYearCalendar = labels.SystemStartYearCalendar;
const HomeClub = labels.HomeClub;

const MonthOptions = {
  "1": "Leden",
  "2": "Únor",
  "3": "Březen",
  "4": "Duben",
  "5": "Květen",
  "6": "Červen",
  "7": "Červenec",
  "8": "Srpen",
  "9": "Září",
  "10": "Říjen",
  "11": "Listopad",
  "12": "Prosinec",
}

const MonthOptionsExtended = {
  "13": "celý rok",
  ...MonthOptions
}

const MonthOptionsExtendedCurrent = {
  "0": "Aktuální",
  "13": "celý rok",
  ...MonthOptions
}

const MonthOptionsPrevious = {
  "0": "Posledních 30 dní",
  ...MonthOptions
}

const AccountTypeBasicOptions = {
  "A": "A – Aktivní",
  "B": "B – Bez přístupu",
};

const AccountTypeOtherOptions = {
  "C": "C – Lokální",
  "D": "D – Deaktivovaný",
  "E": "E – Externí připojený",
  "F": "F – Externí lokální",
};

const AccountTypeOptions = {
  ...AccountTypeBasicOptions,
  ...AccountTypeOtherOptions,
};

const AdminAccountTypeOptions = {
  "*": "A + B",
  ...AccountTypeOptions
}

const RegionShortOptions = {
  "" : "Doporučená",
  "#" : "Rozšířená",
  "*" : "Všechny",
}

const RegionOptions = {
  ...RegionShortOptions,
  "ČR" : "ČR - celá ČR",
  "Č" : "Č - Čechy",
  "M" : "M - Morava",
  "HA" : "HA - Hanácká",
  "JČ" : "JČ - Jihočeská",
  "JE" : "JE - Ještědská",
  "JM" : "JM - Jihomoravská",
  "MSK": "MSK - MS kraj",
  "P" : "P - Pražská",
  "StČ" : "StČ - Středočeská",
  "VA" : "VA - Valašská",
  "VČ" : "VČ - Východočeská",
  "VY" : "VY - Vysočina",
  "ZČ" : "ZČ - Západočeská",
}

const RegionCustomizeOptions = {
  "" : "žádná",
  "M" : "M - Morava",
  "HA" : "HA - Hanácká",
  "JČ" : "JČ - Jihočeská",
  "JE" : "JE - Ještědská",
  "JM" : "JM - Jihomoravská",
  "MSK": "MSK - MS kraj",
  "VA" : "VA - Valašská",
  "VČ" : "VČ - Východočeská",
  "VY" : "VY - Vysočina",
  "ZČ" : "ZČ - Západočeská",
}

const SportTypeOptions = {
  "0": "OB",
  "1": "LOB",
  "2": "MTBO",
  "3": "Trail",
}

const CancelledOptions = {
  "0" : "Ne",
  "1" : "Ano",
}

const EventTypeOptions = {
  "O" : "Oficiální a klubové",
  "*" : "Všechny",
  "K" : "Pouze klubové",
}

const EventTypeOptionsAlt = {
  "K" : "Klubové",
  "*" : "Všechny",
}

const ReqEventTypeOptions = {
  "10" : "Soustředění",
  "11" : "Členský příspěvek",
  "12" : "Jiný",
}

const DisciplineBasicOptions = {
  "KL" : "klasika",
  "KT" : "krátká",
  "SP" : "sprint",
  "ST" : "štafety",
  "SS" : "sprintové štafety",
  "SC" : "scorelauf",
  "MS" : "hromadný start",
  "NOB" : "noční OB",
  "AK" : "jiná akce",
  "MT": "mapový trénink",
  "SO": "soustředění"
}

const DisciplineAllOptions = {
  ...DisciplineBasicOptions,
  "DT" : "dlouhá trať",
  "DR" : "družstva",
  "S" : "školení, seminář",
  "ET" : "etapový",
  "KO" : "knock-out sprint",
  "STK" : "stacionární tréninkový kemp",
  "Z" : "dlouhodobý žebříček",
  "D" : "dráha",
}

const TernaryOptionsTransport = {
  "-1" : "Není",
  "0" : "Je možná",
  "1" : "Je povinná",
}

const TernaryOptionsNeutral = {
  "-1" : "Není",
  "0" : "Je možné",
  "1" : "Je povinné",
}

const TernaryOptionsReduced = {
  "-1" : "Není",
  "0" : "Je možné",
}

const InterestBasicOptions = {
  "0" : "Nemám zájem",
  "1" : "Mám zájem",
}

const InterestExtendedOptions = {
  ...InterestBasicOptions,
  "2" : "Vezmu auto, když bude třeba",
  "3" : "Vezmu auto",
}

const InterestSemiExtendedOptions = {
  "1" : "Mám zájem",
  "2" : "Vezmu auto, když bude třeba",
  "3" : "Vezmu auto",
}

const PunchingOptions = {
  "1" : "SI kontaktní",
  "2" : "SI bezkontaktní",
  "4" : "manuální",
}

const BillingModeOptions = {
  "0" : "klasický (pouze P)",
  "1" : "placeno přímo členy",
  "2" : "placeno klubem",
  "3" : "pořádáno klubem",
}

const QuestionTypeOptions = {
  "0" : "Otevřená",
  "1" : "Jedna možnost",
  "2" : "Více možností"
}

const StatsMetrics = {
  "UV" : "účast >=",
  "UM" : "účast <=",
  "OV" : "oficiální >=",
  "OM" : "oficiální <=",
  "DV" : "DNS >=",
  "DM" : "DNS <=",
}

const PaymentsViews = (unassigned=0, thirdParty=0) => {
  return {
    "*": "vše",
    "P": "Přiřazené",
    "N": "Nepřiřazené (" + unassigned + ")",
    "T": "Třetí strany (" + thirdParty + ")"
  }
}

const RequisitionEventsModes = {
  "*": "vše",
  "K": "Klubové",
  "O": "Oris akce",
}

const EmailsPerMin = 5;

const MobileWidthLimitSM = 767;
const MobileWidthLimitXS = 576;

export {
  API_URL, MAP_API_KEY, ORIS_URL, TRACKING_URL, MobileWidthLimitSM, MobileWidthLimitXS, EmailsPerMin, StartYearSystem, StartYearCalendar, Theme,
  HomeClub, MonthOptionsExtended, MonthOptionsExtendedCurrent, MonthOptionsPrevious, MonthOptions, AccountTypeOptions,
  AccountTypeBasicOptions, AccountTypeOtherOptions, AdminAccountTypeOptions, BillingModeOptions, QuestionTypeOptions, StatsMetrics,
  RegionShortOptions, RegionOptions, RegionCustomizeOptions, SportTypeOptions, CancelledOptions, EventTypeOptions, EventTypeOptionsAlt, ReqEventTypeOptions,
  DisciplineBasicOptions, DisciplineAllOptions, PaymentsViews, RequisitionEventsModes,
  TernaryOptionsTransport, TernaryOptionsNeutral, TernaryOptionsReduced, PunchingOptions, InterestBasicOptions, InterestExtendedOptions, InterestSemiExtendedOptions};