import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {getEnrollAnonymInfo} from "../../dao";
import {handleErrorLoading, setBrowserTabText} from "../../helpers/Functions";
import Loader from "../../components/overlays/Loader";
import ErrorAlertFullscreen from "../../components/overlays/ErrorAlertFullscreen";
import EnrollTrainingAnonym from "./EnrollTrainingAnonym";
import EnrollCampAnonym from "./EnrollCampAnonym";


const EnrollAnonym = () => {
  const { eventId } = useParams();
  const { userId } = useParams();
  const [loading, setLoading] = useState(true);
  const [errorRemote, setErrorRemote] = useState("");
  const [data, setData] = useState([]);

  useEffect(() => {
    setLoading(true);
    getEnrollAnonymInfo(eventId, userId)
      .then((response) => {
        if (!response.ok) {
          setErrorRemote(handleErrorLoading(response));
          return [];
        }
        return response.json()})
      .then((response) => {
        setData(response);
        if (data?.event?.term < 0)
          setErrorRemote("Přihlášky nejsou otevřeny...");
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setErrorRemote("Připojení neúspěšné...");
      })
  }, [userId, eventId]);

  if (errorRemote !== "")
    return <ErrorAlertFullscreen error={errorRemote} />;
  if (loading)
    return <Loader />;


  setBrowserTabText('Přihláška | ' + data?.event?.title);
  if (data?.event?.type === 1)
    return <EnrollTrainingAnonym eventId={eventId} userId={userId} data={data} />;
  if (data?.event?.type === 2)
    return <EnrollCampAnonym />;
  return <ErrorAlertFullscreen error={"Přihlášku nelze realizovat"} />;
}

export default EnrollAnonym;