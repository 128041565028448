import  Row from "react-bootstrap/Row";
import  Col from "react-bootstrap/Col";
import Card from 'react-bootstrap/Card';
import Alert from 'react-bootstrap/Alert';
import { useParams, Link } from "react-router-dom";
import {getEvent, getEventApplyRights, getEventSync} from "../../dao";
import React, {useState, useEffect} from 'react';
import 'leaflet/dist/leaflet.css';
import {faFile, faPencil} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loader from "../../components/overlays/Loader";
import ErrorAlertFullscreen from "../../components/overlays/ErrorAlertFullscreen";
import { useLocation } from "react-router-dom";
import SelectUserModal from "../../components/parts/SelectUserModal";
import EventEnrollCard from "../../components/parts/EventEnrollCard";
import {handleErrorLoading, parseDate, parseDateBreakYear, setBrowserTabText} from "../../helpers/Functions";
import PageContainer from "../../layout/PageContainer";
import AutoFormat from "../../components/visual/AutoFormat";
import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";
import EventClassesTable from "../../components/parts/EventClassesTable";
import EventInformationCard from "../../components/parts/EventInformationCard";
import EventMap from "../../components/parts/EventMap";
import Button from "react-bootstrap/Button";
import {ORIS_URL} from "../../helpers/Constants";
import SyncRunningIndicator from "../../components/visual/SyncRunningIndicator";
import EventClubCard from "../../components/parts/EventClubCard";

const EventDetail = () => {
  const { eventId } = useParams();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [errorRemote, setErrorRemote] = useState("");
  const [event, setEvent] = useState({news: [], links: [], links_templated: []});
  const [synced, setSynced] = useState(true);
  const [rightData, setRightData] = useState({count:-1, rights: []});
  const [showEnrollList, setShowEnrollList] = useState(Boolean(location.state?.showEnrollList));
  const [showClasses, setShowClasses] = useState(false);
  const [showNews, setShowNews] = useState(false);

  useEffect(() => {
    setLoading(true);
    getEvent(eventId)
      .then((response) => {
        if (!response.ok) {
          setErrorRemote(handleErrorLoading(response));
          return {news: [], links: [], links_templated: []};
        }
        return response.json();
      })
      .then((response) => {
        setEvent(response);
        setLoading(false);
        if (response?.type === 0) {
          setSynced(false);
          getEventSync(eventId)
            .then((response) => {
              if (!response.ok) {
                setErrorRemote(handleErrorLoading(response));
                return {news: [], links: [], links_templated: []};
              }
              return response.json();
            })
            .then((response) => {
              setEvent(response);
              setSynced(true);
            })
        }
      })
      .catch((err) => {
        console.log(err);
        setErrorRemote("Připojení neúspěšné...");
      });
    getEventApplyRights(eventId)
      .then((response) => {
        if (!response.ok) {
          return {count:0, rights: []}
        }
        return response.json()
      })
      .then((response) => {
        setRightData(response);
      })
  }, [eventId]);

  if (errorRemote !== "")
    return <ErrorAlertFullscreen error={errorRemote} />;
  if (loading)
    return <Loader loading={loading} />;

  setBrowserTabText(event.title);
  return (
    <PageContainer>
      <SelectUserModal
        id={eventId}
        show={showEnrollList} eventType={event?.type}
        onHide={() => {setShowEnrollList(false)}}
        title="Vybrat uživatele pro přihlášení"
        people={rightData.rights}
        loading={rightData.count === -1}
      />
      <h1>{event?.discipline === "Z" ? "Soutěž" : "Akce"}: {event.title}</h1>
      <Row className="m-auto"><EventCancelled /></Row>
      <Row className="m-auto"><EventWarning /></Row>
      <Row className="m-auto"><EventInfo /></Row>
      <Row>
        <Col lg={7}>
          <EventInformationCard event={event} setShowClasses={setShowClasses} synced={synced} />
          <EventNews />
          <EventMap event={event} />
          {event?.discipline === "Z" && <ClassLinksTable />}
        </Col>
        <Col>
          <EventEnrollCard event={event} showEnrollList={()=>setShowEnrollList(true)} entryTransport={event?.entry?.transport}/>
          <EventClubCard event={event} />
          <LinkSectionCard />
        </Col>
      </Row>
      <Modal show={showClasses} onHide={()=> setShowClasses(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Kategorie</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EventClassesTable event={event} />
        </Modal.Body>
      </Modal>
      <Modal show={showNews} onHide={()=> setShowNews(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Novinky</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <NewsTable />
        </Modal.Body>
      </Modal>
    </PageContainer>
  );

  function EventCancelled(){
    if(event?.cancelled){
      return(<Alert variant="danger"><b>Akce zrušena!</b></Alert>);
    }
  }

  function EventWarning(){
    if(event?.event_warning != null && event?.event_warning !== ""){
      return(<Alert variant="warning"><AutoFormat text={event.event_warning}/></Alert>);
    }
  }

  function EventInfo(){
    if(event?.event_info != null && event?.event_info !== ""){
      return(<Alert variant="info"><AutoFormat text={event.event_info}/></Alert>);
    }
  }

  function EventNews() {
    if (!event?.news)
      return;
    if (event?.news.length === 0)
      return;
    const latest = event.news[0];
    const nearestSpace = latest.text.indexOf(' ', 60) > -1 ? latest.text.indexOf(' ', 60) : latest.text.length;
    return(
      <Card className="mb-3">
        <Card.Body>
          <Card.Title>Novinky</Card.Title>
          <Table className="mb-1" responsive borderless>
            <tbody>
            <tr>
              <td className="ps-0">{parseDateBreakYear(latest.date)}</td>
              <td className="pe-0">{latest.text.substring(0, nearestSpace)}{latest.text.length > nearestSpace && "..."}</td>
            </tr>
            </tbody>
          </Table>
          <Row className="mt-1">
            <Col className="text-center">
              <Button variant="outline-info" onClick={() => setShowNews(true)}>Zobrazit novinky ({event?.news.length})</Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  }

  function LinkSectionCard() {
    if (!event?.links)
      return;
    return (
      <Card className="mb-3">
        <Card.Body>
          <Card.Title><FontAwesomeIcon icon={faFile} /> Dokumenty a odkazy {event.type !== 0 && event.editor && <Link to={`/akce/${eventId}/odkazy`}><FontAwesomeIcon icon={faPencil}/></Link>}</Card.Title>
          <LinkSection event={event} />
        </Card.Body>
      </Card>
    );
    function LinkSection() {
      if (event.links_templated.length === 0 && event.links.length === 0)
        return synced ? <p>Žádné dostupné odkazy ani dokumenty.</p> : <SyncRunningIndicator synced={synced} />;

      const LinksList = ({links}) => {
        const Link = ({link}) => {
          return <p className="mb-1"><a href={link.url} target="_blank" rel="noreferrer">{link.title}</a></p>;
        }

        let res = [];
        for (const linkKey in links) {
          res.push(<Link key={linkKey} link={links[linkKey]}/>);
        }
        return res;
      }

      return (<>
        <LinksList links={event.links_templated} />
        {(event.links_templated.length !== 0 && event.links.length !== 0) && <hr />}
        <LinksList links={event.links} />
      </>)
    }
  }

  function NewsTable() {
    const NewsRow = ({element}) => {
      return <tr>
        <td>{parseDate(element.date)}</td>
        <td>{element.text}</td>
      </tr>
    }

    const rows = [];
    for (const i in event.news)
      rows.push(<NewsRow element={event.news[i]} key={i}/>);

    return (
      <Table striped responsive>
        <thead>
        </thead>
        <tbody>
        {rows}
        </tbody>
      </Table>
    )
  }

  function ClassLinksTable() {
    return <Card className="mb-3">
      <Card.Body>
        <Card.Title>Výsledky po kategoriích</Card.Title>
        <Table className="mb-0" striped bordered responsive>
          <tbody>
          <ClassLinksTableContent />
          </tbody>
        </Table>
      </Card.Body>
    </Card>

    function ClassLinksTableContent() {
      const count = Object.keys(event.class_data).length;
      const half = Math.ceil(count / 2);

      let rows = [];
      for (let i = 0; i < half; i++) {
        const rightIdx = count % 2 === 0 ? half + i : half + i - 1;
        const leftItem = event.class_data[i];
        const rightItem = event.class_data[rightIdx];
        rows.push(<tr key={i}>
          <td align="center">
            {(i + 1 !== half || count % 2 === 0) && <Link to={ORIS_URL + "Vysledky?id=" + event.oris_id + "&class=" + leftItem.id}>{leftItem.name}</Link>}
          </td>
          <td align="center"><Link to={ORIS_URL + "Vysledky?id=" + event.oris_id + "&class=" + rightItem.id}>{rightItem.name}</Link>
          </td>
        </tr>);
      }
      return rows;
    }
  }
};
export default EventDetail;