import {getEditEvent} from "../../dao";
import React, { useState, useEffect } from 'react';
import {faPencil } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loader from "../../components/overlays/Loader";
import EditTraining from "./EditTraining";
import EditCamp from "./EditCamp";
import EditOrisEvent from "./EditOrisEvent";
import { useParams } from "react-router-dom";
import {handleErrorLoading, setBrowserTabText} from "../../helpers/Functions";
import PageContainer from "../../layout/PageContainer";
import PageHeading from "../../layout/PageHeading";
import ErrorAlertFullscreen from "../../components/overlays/ErrorAlertFullscreen";

const EditEvent = () => {
  const { eventId } = useParams();
  const [loading, setLoading] = useState(true);
  const [errorRemote, setErrorRemote] = useState("");
  const [event, setEvent] = useState({});

  useEffect(() => {
    setErrorRemote("");
    setLoading(true);
    getEditEvent(eventId)
      .then((response) => {
        if(!response.ok){
          setErrorRemote(handleErrorLoading(response));
          return {}
        }
        return response.json();
      })
      .then((response) => {
        setEvent(response);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setErrorRemote("Připojení neúspěšné...");
      })
  }, [eventId]);

  if (loading)
    return <Loader />;


  const EditEventBody = () => {
    switch (event?.type) {
      case 0:
        return <EditOrisEvent eventId={eventId} event={event} />;
      case 1:
        return <EditTraining eventId={eventId} event={event}/>;
      case 2:
        return <EditCamp eventId={eventId} event={event}/>;
      default:
        return <></>;
    }
  }

  setBrowserTabText('Upravit | ' + event?.title);
  return (
    <PageContainer>
      <PageHeading heading={<><FontAwesomeIcon icon={faPencil}/> Upravit: {event?.title}</>} id={eventId} />
      <br/>
      <EditEventBody />
    </PageContainer>
  );
};
    
export default EditEvent;