import PageContainer from "../../layout/PageContainer";
import Loader from "../../components/overlays/Loader";
import ErrorAlertFullscreen from "../../components/overlays/ErrorAlertFullscreen";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {deleteLink, getEditLinks, postPutLink,} from "../../dao";
import {handleErrorLoading, handleErrorSubmit, setBrowserTabText} from "../../helpers/Functions";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import ErrorAlert from "../../components/overlays/ErrorAlert";
import SuccessAlert from "../../components/overlays/SuccessAlert";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencil, faQuestionCircle, faTrash} from "@fortawesome/free-solid-svg-icons";
import Table from "react-bootstrap/Table";
import PageHeading from "../../layout/PageHeading";
import {labels} from "../../themeLabels";


const EventLinks = () => {
  const { eventId } = useParams();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [errorRemote, setErrorRemote] = useState("");
  const [success, setSuccess] = useState("");
  const [addEdit, setAddEdit] = useState(false);
  const [remove, setRemove] = useState(false);
  const [linkId, setLinkId] = useState(null);
  const [linkTitle, setLinkTitle] = useState("");
  const [linkUrl, setLinkUrl] = useState("");

  useEffect(() => {
    loadData();
  }, [eventId]);

  function loadData(){
    setLoading(true);
    getEditLinks(eventId)
      .then((response) => {
        if (!response.ok) {
          setErrorRemote(handleErrorLoading(response))
          return []
        }
        return response.json();
      })
      .then((response) => {
        setData(response);
        setLoading(false);
      });
  }

  function addEditLink() {
    if (linkId == null)
      sendData(eventId, linkTitle, linkUrl, "POST");
    else
      sendData(linkId, linkTitle, linkUrl, "PUT");
    setLinkId(null);
    setAddEdit(false);
  }

  function removeLink() {
    deleteLink(linkId)
      .then((res) => {
        if (res.ok) {
          setSuccess("Uloženo");
          loadData();
          setLinkId(null);
          setRemove(false);
        } else {
          setError(handleErrorSubmit(res, "Nezdařilo se smazat"));
        }
      });
  }

  function sendData(id, title, url, method) {
    postPutLink(id, title, url, method)
      .then((res) => {
        if (res.ok) {
          setSuccess("Uloženo");
          loadData();
        } else {
          setError(handleErrorSubmit(res, "Nezdařilo se uložit"));
        }
      });
  }

  if (errorRemote !== "")
    return <ErrorAlertFullscreen error={errorRemote} />;
  if (loading)
    return <Loader/>;

  setBrowserTabText('Odkazy | ' + data?.event?.title);
  return (
    <PageContainer>
      <PageHeading heading={"Odkazy: " + data?.event?.title} id={eventId} />
      <br/>
      <Button className="mb-3" onClick={() => {
        setLinkId(null);
        setLinkTitle("");
        setLinkUrl("");
        setAddEdit(true);
      }}>Přidat odkaz</Button>
      <br/>
      <LinkTable />
      <Modal show={addEdit} onHide={() => {
        setLinkId(null);
        setAddEdit(false);
      }}>
        <Modal.Header closeButton>
          <Modal.Title>{linkId == null ? "Přidat" : "Editovat"} odkaz</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="form">
              <Form.Label>Popisek:</Form.Label>
              <Form.Control type="text" value={linkTitle}
                            onChange={(e) => setLinkTitle(e.target.value)}/>
            </Form.Group>
            <Form.Group className="mb-3" controlId="form">
              <Form.Label>URL:</Form.Label>
              <Form.Control type="url" value={linkUrl}
                            onChange={(e) => setLinkUrl(e.target.value)}/>
          </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => {
            setLinkId(null);
            setAddEdit(false);
          }}>
            Zavřít
          </Button>
          <Button variant="primary" onClick={addEditLink}>
            {linkId == null ? "Přidat" : "Změnit"}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={remove} onHide={() => setRemove(false)}>
        <Modal.Header closeButton>
          <FontAwesomeIcon icon={faQuestionCircle} size='3x' bounce/> &nbsp;
          <Modal.Title>Smazat odkaz?</Modal.Title>
          <Modal.Body>{linkTitle}</Modal.Body>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setRemove(false)}>
            Zavřít
          </Button>
          <Button variant="primary" onClick={removeLink}>
            Smazat
          </Button>
        </Modal.Footer>
      </Modal>
      <ErrorAlert error={error} handleClose={() => setError("")}/>
      <SuccessAlert title={success} handleClose={() => setSuccess("")}/>
    </PageContainer>
  );

  function LinkTable(){
    if(data.links === undefined){
      return(<p><i>K této akci nejsou žádné odkazy</i></p>)
    }
    if(data.links.length < 1){
      return(<p><i>K této akci nejsou žádné odkazy</i></p>)
    }
    const rows = [];
    data.links.forEach((link)=>{
      rows.push(<tr key={link.id}>
        <td>{link.title}</td>
        <td>{link.url}</td>
        <td onClick={() => {
          setLinkId(link.id);
          setLinkTitle(link.title);
          setLinkUrl(link.url);
          setAddEdit(true);
        }} className="text-center pointing"><FontAwesomeIcon icon={faPencil}/></td>
        <td onClick={() => {
          setLinkId(link.id);
          setLinkTitle(link.title);
          setLinkUrl(link.url);
          setRemove(true);
        }} className="text-center pointing"><FontAwesomeIcon icon={faTrash}/></td>
      </tr>);
    });

    return (
      <Table striped bordered hover={labels.CondElemTableHover} responsive>
        <thead>
        <tr>
          <th>Popisek</th>
          <th>URL</th>
          <th></th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        {rows}
        </tbody>
      </Table>
    );
  }
}

export default EventLinks;