import Table from "react-bootstrap/Table";
import React, { useState, useEffect } from 'react'
import {getClubEnrolls, getEventApplyRights} from "../../dao";
import { useParams } from "react-router-dom";
import {faPencil, faSort, faSortDown} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SelectUserModal from "../../components/parts/SelectUserModal";
import {czechAlphabetComparator, handleErrorLoading, setBrowserTabText} from "../../helpers/Functions";
import ErrorAlertFullscreen from "../../components/overlays/ErrorAlertFullscreen";
import PageContainer from "../../layout/PageContainer";
import {Form} from "react-bootstrap";
import LoadingIcon from "../../components/overlays/LoadingIcon";
import PageHeading from "../../layout/PageHeading";
import {HomeClub, MobileWidthLimitSM, ORIS_URL} from "../../helpers/Constants";
import {labels} from "../../themeLabels";
import WarningCard from "../../components/overlays/WarningCard";

const ClubEnrolls = () => {
  const {eventId} = useParams();
  const [errorRemote, setErrorRemote] = useState("");
  const [data, setData] = useState([]);
  const [event, setEvent] = useState([]);
  const [rightData, setRightData] = useState({count:-1, rights: []});
  const [order, setOrder] = useState('K');
  const [loadingData, setLoadingData] = useState(true);
  const [splitByClasses, setSplitByClasses] = useState(false);
  const [highlightedOnly, setHighlightedOnly] = useState(false);
  const [selectUsersShow, setSelectUsersShow] = useState(false);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= MobileWidthLimitSM);

  useEffect(() => {
    const handleOrientationChange = () => {setIsMobile(window.innerWidth <= MobileWidthLimitSM)};
    window.addEventListener('resize', handleOrientationChange);
    return () => {window.removeEventListener('resize', handleOrientationChange)};
  }, []);

  useEffect(() => {
    setLoadingData(true);
    getClubEnrolls(eventId, splitByClasses)
      .then((response) => {
        if (!response.ok) {
          setErrorRemote(handleErrorLoading(response));
          return []
        }
        return response.json()
      })
      .then((response) => {
        setData(response);
        setEvent(response?.event);
        if (response?.event?.startlists)
          setOrder('S');
        if (response?.event?.type === 2)
          setOrder('R');
        setLoadingData(false);
      })
      .catch((err) => {
        console.log(err);
        setErrorRemote("Připojení neúspěšné...");
      });
    getEventApplyRights(eventId)
      .then((response) => {
        if (!response.ok) {
          setErrorRemote(handleErrorLoading(response));
          return {count:0, rights: []}
        }
        return response.json()
      })
      .then((response) => {
        setRightData(response);
      })
  }, [eventId, splitByClasses]);

  if (errorRemote !== "")
    return <ErrorAlertFullscreen error={errorRemote} />;

  const title = event?.title !== undefined ? event?.title : "";
  setBrowserTabText(event?.startlists ? "Startovky | " + title : 'Přihlášky | ' + title);
  return (
    <PageContainer>
      <PageHeading heading={event?.startlists ? "Startovky: " + title : "Přihlášky: " + title} id={eventId} />
      <Row className="mb-3 align-items-center">
        {event?.term >= 0 &&
          <>
            {(!data?.applied && !event?.disable_entries) &&
              <Col xs="auto">
                <Link to={`/akce/${eventId}/prihlaska`}>{(event.type === 0 && !event?.is_relay) ? labels.LinkEntryRemoteCreate : labels.LinkEntryLocalCreate}</Link>
              </Col>
            }
            {!event?.disable_entries &&
              <Col xs="auto">
                <Link to="#" onClick={() => setSelectUsersShow(true)}>{(event.type === 0 && !event?.is_relay) ? labels.LinkEntryOthersRemote : labels.LinkEntryOthersLocal}</Link>
              </Col>
            }
            {event?.type === 1 &&
              <Col xs="auto">
                <Link to={`/anonym/akce/${eventId}/prihlaska`}>Jednorázová přihl{isMobile ? "." : "áška"}</Link>
              </Col>
            }
          </>
        }
        {event.type !== 2 && <Col xs="auto">
          <Form.Check className="mt-2" type="switch" id="visible-switch" label={isMobile ? "Po kategoriích" : "Rozdělit po kategoriích"}
                      defaultChecked={splitByClasses} disabled={loadingData} onChange={(e) => {
            setSplitByClasses(e.target.checked);
            setLoadingData(true);
          }}/>
        </Col>}
        <Col xs="auto">
          <Form.Check className="mt-2" type="switch" id="visible-switch" label={"Jen zvýraznění"}
                      defaultChecked={highlightedOnly} disabled={loadingData} onChange={(e) => {
            setHighlightedOnly(e.target.checked);
          }}/>
        </Col>
      </Row>
      <ClubEnrollsTable enrolls={data.entries}/>
      {event?.in_reserves &&
        <Row className="table-warning"><p style={{backgroundColor: "#fff3cd"}}>Žlutou barvou jsou označeni náhradníci. Poslední aktualizace náhradníků proběhla dnes ve 2:00.
          Seznam aktuální k tomuto momentu najdeš v <Link to={ORIS_URL + "PrehledPrihlasenych?id=" + event.oris_id + "&club=" + HomeClub}>ORISu</Link>.</p></Row>
      }
      <SelectUserModal
        id={eventId}
        show={selectUsersShow} eventType={event?.type}
        onHide={() => {setSelectUsersShow(false)}}
        title="Vybrat uživatele pro přihlášení"
        people={rightData.rights}
        loading={rightData.count === -1}
      />
    </PageContainer>
  );

  function ClubEnrollsTable(props) {
    if (loadingData)
      return <LoadingIcon />;

    const SortIndicator = ({condition}) => {
      return condition ? <> <FontAwesomeIcon icon={faSortDown}/></> : <> <FontAwesomeIcon icon={faSort} color="gray"/></>;
    }

    const SortTwoIndicator = ({condition1, condition2}) => {
      return <><SortIndicator condition={condition1}/><SortIndicator condition={condition2}/></>
    }

    if (splitByClasses) {
      const tables = [];
      for (let element in props.enrolls) {
        tables.push(<>
          <Row key={'R'+element}>
            <Col><h4>{element}</h4></Col>
            <Col className="text-end">({props.enrolls[element].length})</Col>
          </Row>
          <Table striped bordered hover={labels.CondElemTableHover} responsive key={'T'+element}>
            <tbody>
            <TableContent enrolls={props.enrolls[element]} key={element}/>
            </tbody>
          </Table>
          </>
        )
      }
      return (tables.length === 0 ? <WarningCard text="Žádné přihlášky" /> : tables);
    }
    else if (props.enrolls.length > 0) {
      return (
        <Table striped bordered hover={labels.CondElemTableHover} responsive>
          <thead>
          <tr>
            {event.type !== 2 && (event.startlists ?
              (isMobile ? <th className="pointing" onClick={() => {order === 'S' ? setOrder('K') : setOrder('S')}}>Start<SortTwoIndicator condition1={order === 'S'} condition2={order === 'K'}/></th> : (<>
                <th className="pointing" onClick={() => setOrder('S')}>Start<SortIndicator condition={order === 'S'}/></th>
                <th className="pointing" onClick={() => setOrder('K')}>Kat.<SortIndicator condition={order === 'K'}/></th>
              </>)) :
              (<th className="pointing" onClick={() => setOrder('K')}>Kat.<SortIndicator condition={order === 'K'}/></th>))
            }
            {isMobile ?
              <th className="pointing" onClick={() => {order === 'J' ? setOrder('R') : setOrder('J')}}>Osoba<SortTwoIndicator condition1={order === 'J'} condition2={order === 'R'}/></th> :
              (<>
                <th className="pointing" onClick={() => setOrder('R')}>Reg.<SortIndicator condition={order === 'R'}/></th>
                <th className="pointing" onClick={() => setOrder('J')}>Jméno<SortIndicator condition={order === 'J'}/></th>
                <th>Čip</th>
              </>)
            }
            <th style={{textAlign: "center"}}>({props.enrolls.length})</th>
          </tr>
          </thead>
          <tbody>
          <TableContent enrolls={props.enrolls}/>
          </tbody>
        </Table>
      );
    }
    return <WarningCard text="Žádné přihlášky" />;

    function TableContent(props) {
      const TableRow = ({element}) => {
        const url_entry = "/akce/" + eventId + "/prihlaska/" + element.id;
        const url_oris = (event.startlists ?
          ORIS_URL + "Startovka?id=" + event.oris_id + "&class=" + element.class_id :
          ORIS_URL + "PrehledPrihlasenych?id=" + event.oris_id + "&class=" + element.class_id)
        const OrisLink  = ({italics}) => {
          if (event?.type !== 0)
            return <>{element.class_name}</>;
          return <Link target="_blank" to={url_oris} className="no-decoration">{italics ? <i>{element.class_name}</i> : <>{element.class_name}</>}</Link>;
        }

        if (isMobile) {
          return (<tr className={element?.reserve_idx > 0 ? "table-warning" : (element?.highlight ? "table-success" : "")}>
            {event.type !== 2 && (event.startlists ?
              <td className="td-w-25">{element.start_time}<br/><OrisLink italics={true}/></td> :
              <td className="td-w-20"><OrisLink italics={false}/></td>)
            }
            <td><Link to={element.id ? "/ucet/" + element.id + "/zavody" : "#"} className="hide-link">{element.full_name}</Link><br/><i>{element.reg_number}</i></td>
            <td className="td-w-icon">
              {(element.can_edit && !event?.disable_entries) && <Link to={url_entry}><FontAwesomeIcon icon={faPencil} size="lg"/></Link>}
            </td>
          </tr>)
        }
        return (
          <tr className={element?.reserve_idx > 0 ? "table-warning" : (element?.highlight ? "table-success" : "")}>
            {event.type !== 2 ? (event.startlists ?
              <>
                <td className="td-w-12">{element.start_time}</td>
                <td className="td-w-12"><OrisLink italics={false}/></td>
                <td className="td-w-15">{element.reg_number}</td>
              </> : <>
                <td className="td-w-15"><OrisLink italics={false}/></td>
                <td className="td-w-20">{element.reg_number}</td>
              </>) : <td className="td-w-20">{element.reg_number}</td>
            }
            <td><Link to={element.id ? "/ucet/" + element.id + "/zavody" : "#"} className="hide-link">{element.full_name}</Link></td>
            <td>{element.si}</td>
            <td className="td-w-icon">
              {(element.can_edit && !event?.disable_entries) && <Link to={url_entry}><FontAwesomeIcon icon={faPencil} size="lg"/></Link>}
            </td>
          </tr>
        )
      }
      if (props.enrolls != null) {
        const rows = [];
        const ordered = sortClubEnrolls([...props.enrolls])
        for (const element in ordered) {
          if (!ordered[element].highlight && highlightedOnly)
            continue;
          rows.push(<TableRow element={ordered[element]} key={element}/>);
        }
        return (rows);
      }
    }

    function sortClubEnrolls(array)
    {
      if ((order === 'K' && !event.startlists) || (order === 'S' && event.startlists)) {
        return array;
      }
      if (order === 'J') {
        return array.sort(czechAlphabetComparator);
      }
      if (order === 'K') {
        return array.sort(function(a, b)
        {
          const x1 = a.class_name; const y1 = b.class_name;
          const x2 = a.start_time; const y2 = b.start_time;
          return ((x1 < y1) ? -1 : ((x1 > y1) ? 1 : ((x2 < y2) ? -1 : ((x2 > y2) ? 1 : 0))));
        });
      }
      if (order === 'R') {
        return array.sort(function(a, b)
        {
          const x1 = a.reg_number; const y1 = b.reg_number;
          return ((x1 < y1) ? -1 : ((x1 > y1) ? 1 : 0));
        });
      }
    }
  }
}

export default ClubEnrolls;