import {StartYearCalendar, StartYearSystem} from "./Constants";
import {labels} from "../themeLabels";
import React from "react";


function HomeYearOptions() {
  let date = new Date();
  let to = date.getFullYear() + 2;
  const options = [];
  for (let i = to; i >= StartYearCalendar; i--) {
    const dict = {}
    dict[String(i)] = String(i);
    options.push(dict);
  }
  return options;
}

function YearOptions(withPrev=false) {
  let date = new Date();
  let from = withPrev ? StartYearSystem - 1 : StartYearSystem;
  let to = date.getFullYear();
  const options = [];
  for (let i = to; i >= from; i--) {
    const dict = {}
    dict[String(i)] = String(i);
    options.push(dict);
  }
  return options;
}

function parseDate(rawDate) {
  if (rawDate != null) {
    let dateObject = new Date(rawDate);
    return dateObject.getDate() + ".\u00A0" + (dateObject.getMonth() + 1) + ".\u00A0" + dateObject.getFullYear();
  }
  return null;
}

function parseDateShortYear(rawDate) {
  if (rawDate != null) {
    let dateObject = new Date(rawDate);
    return dateObject.getDate() + ".\u00A0" + (dateObject.getMonth() + 1) + ".\u00A0" + (dateObject.getFullYear() % 2000);
  }
  return null;
}

function parseDateBreakYear(rawDate) {
  if (rawDate != null) {
    let dateObject = new Date(rawDate);
    return dateObject.getDate() + ".\u00A0" + (dateObject.getMonth() + 1) + ". " + dateObject.getFullYear();
  }
  return null;
}

function parseDateWithoutYear(rawDate) {
  if (rawDate != null) {
    let dateObject = new Date(rawDate);
    return dateObject.getDate() + ".\u00A0" + (dateObject.getMonth() + 1) + ".";
  }
  return null;
}

function parseDateTime(rawDate) {
  if (rawDate !== undefined && rawDate !== null) {
    let dateObject = new Date(rawDate);
    return dateObject.getDate() + ". " + (dateObject.getMonth() + 1) + ". " + dateObject.getFullYear() + " " +
        (dateObject.getHours() + "").padStart(2, "0") + ":" + (dateObject.getMinutes() + "").padStart(2, "0");
  }
  return null;
}

function parseDateTimeSec(rawDate, withYear=true) {
  if (rawDate !== undefined && rawDate !== null) {
    let dateObject = new Date(rawDate);
    const year = withYear ? dateObject.getFullYear() + " " : ""
    return dateObject.getDate() + ". " + (dateObject.getMonth() + 1) + ". " + year +
      (dateObject.getHours() + "").padStart(2, "0") + ":" + (dateObject.getMinutes() + "").padStart(2, "0") + ":" + (dateObject.getSeconds() + "").padStart(2, "0");
  }
  return null;
}

function parseTime(rawTime) {
  if (rawTime !== undefined && rawTime !== null) {
    const split = rawTime.split(':')
    return (split[0]) + ":" + (split[1]);
  }
  return null;
}

function IndicesArray(maxIdx) {
  const options = [];
  for (let i = 1; i <= maxIdx; i++) {
    const dict = {}
    dict[String(i)] = String(i);
    options.push(dict);
  }
  return options;
}

function nullUserData () {
  localStorage.setItem('userData', null);
  window.location.reload();
}


function handleErrorLoading(response, genericText) {
  switch (response.status) {
    case 400:
      return (genericText !== undefined ? genericText : "Něco se pokazilo...");
    case 401:
      nullUserData();
      return "Nepřihlášen/a...";
    case 403:
      return "Nelze načíst...";
    case 404:
      return "Nenalezeno...";
    case 503:
      return "ORIS nepřijal požadavek..."
    case 507:
      return "Databáze neodpovídá..."
    default:
      return "Chybička...";
  }
}

function handleErrorSubmit(response, genericText, conflictResource) {
  switch (response.status) {
    case 401:
      nullUserData();
      return "Nepřihlášen/a...";
    case 403:
      return "Nelze provést operaci...";
    case 404:
      return "Zdroj nenalezen...";
    case 405:
      return "Operace nepovolena...";
    case 409:
      return (conflictResource !== undefined ? conflictResource : "Zdroj") + " již existuje";
    case 412:
      return "Zakázáno omezující podmínkou..."
    case 503:
      return "ORIS nepřijal požadavek..."
    case 507:
      return "Databáze neodpovídá..."
    default:
      return genericText;
  }
}

function filterByFullName(mask, all, topLevel=true) {
  if (mask === "") {
    return all;
  }
  const parsedStr = mask.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toUpperCase();
  const filtered = [];
  for (let i in all) {
    const obj = all[i];
    const subject = topLevel ? obj.full_name : obj.user?.full_name;
    if (subject == null)
      continue;
    const parsedName = subject.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toUpperCase();
    if (parsedName.includes(parsedStr))
      filtered.push(obj);
  }
  return filtered;
}

function setBrowserTabText(section) {
  if (section === undefined || section === "")
    document.title = labels.TabTitle;
  else
    document.title = section + ' | ' + labels.TabTitle;
}

function isDarkMode() {
  if(localStorage.getItem('colormode') != null){
    return localStorage.getItem('colormode') === 'dark';
  }
  return false;
}

function selectEnrollVariant(element, prefix="") {
  if (element?.entry?.id == null)
    return "";
  if (element?.entry?.term === 0)
    return prefix + "info";
  if (element?.entry?.reserve_idx !== 0 && element?.entry?.reserve_idx !== undefined)
    return prefix + "warning";
  return prefix + "success";
}

function getClassName(id, classes) {
  for (const c in classes) {
    const cls = classes[c];
    if (String(cls.id) === id)
      return cls.name;
  }
  return ''
}

function preselectClass(classes) {
  for (const c in classes) {
    const cls = classes[c];
    if (cls?.preselect) {
      return cls.id;
    }
  }
  if (classes.length === 0)
    return "";
  return classes[0].id;
}

function siCardArrayDict(siCards){
  const options = [];
  for (const i in siCards) {
    const card = siCards[i];
    const dict = {}
    dict[card.si_number] = card.si_number;
    options.push(dict);
  }
  options.push({"rent": "Půjčit čip"});
  options.push({"custom": "Neuložený čip"});
  return options;
}

function classDict(classes, type) {
  let classDict = {};
  for (const c in classes) {
    const cls = classes[c];
    const classId = type === 0 ? String(cls.id) : cls.name
    classDict[classId] = (type !== 0 && cls?.note) ? cls.name + " (" + cls?.note + ")" : cls.name;
  }
  return classDict;
}

function replaceTabsWithSpaces(content) {
  if (!content)
    return "";
  return content.replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;');
}

// Define the Czech alphabet order
const czechAlphabetOrder = "AÁBCČDĎEÉĚFGHIÍJKLMNŇOÓPQRŘSŠTŤUÚŮVWXYÝZŽ";

// Function to get the index of a character in the Czech alphabet
function getCzechAlphabetIndex(char) {
  return czechAlphabetOrder.indexOf(char.toUpperCase());
}

// Custom comparison function for sorting
function czechAlphabetComparator(a, b) {
  const compareStrings = (x, y) => {
    for (let i = 0; i < Math.min(x.length, y.length); i++) {
      if (x[i] !== y[i]) {
        return getCzechAlphabetIndex(x[i]) - getCzechAlphabetIndex(y[i]);
      }
    }
    return x.length - y.length;
  };

  const x1 = a.surname.toUpperCase();
  const y1 = b.surname.toUpperCase();
  const x2 = a.name.toUpperCase();
  const y2 = b.name.toUpperCase();

  // Compare surnames first
  const surnameComparison = compareStrings(x1, y1);
  if (surnameComparison !== 0) {
    return surnameComparison;
  }

  // If surnames are equal, compare names
  return compareStrings(x2, y2);
}

function verifyFileIsCsv(file) {
  const split = file.name.split('.')
  if (split.length < 2)
    return false;
  return split[split.length - 1] === 'csv';
}

function textBreakLine(text) {
  const rows = [];
  const splitText = text.split('<br/>');
  for (const i in splitText) {
    const part = splitText[i];
    if (part.includes('/>') || part.includes('</'))
      rows.push(<div key={i} dangerouslySetInnerHTML={{__html: part}}/>)
    else
      rows.push(<p key={i}>{splitText[i]}</p>)
  }
  return rows;
}

function getNextDate(date) {
  let nextDate = new Date(date);
  nextDate.setDate(date.getDate() + 1);
  return nextDate.toISOString().split('T')[0];
}

function durationTimeToFloat(duration="") {
  if (duration === "")
    return NaN;
  if (!duration.includes(':'))
    return Number(duration);
  const durationSplit = duration.split(':');
  const minutes = Number(durationSplit[0]);
  const seconds = (Number(durationSplit[1]) / 60.);
  return minutes + seconds;
}

export {HomeYearOptions, YearOptions, IndicesArray,
  parseDate, parseDateShortYear, parseDateBreakYear, parseDateWithoutYear, parseDateTime, parseTime, parseDateTimeSec,
  handleErrorLoading, handleErrorSubmit, filterByFullName, setBrowserTabText, isDarkMode, selectEnrollVariant, getClassName, preselectClass, classDict, siCardArrayDict,
  replaceTabsWithSpaces, czechAlphabetComparator, verifyFileIsCsv, getNextDate, durationTimeToFloat, textBreakLine}