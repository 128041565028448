import React, {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDown, faAngleUp, faQuestionCircle, faTrash} from "@fortawesome/free-solid-svg-icons";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {handleErrorSubmit, parseDate} from "../../helpers/Functions";
import FormField from "../form/FormField";
import ErrorAlert from "../overlays/ErrorAlert";
import SuccessAlert from "../overlays/SuccessAlert";
import {useNavigate} from "react-router-dom";
import {deleteEvent, forceDataUpdate} from "../../dao";
import LoadingOverlay from "../overlays/LoadingOverlay";


const EventDangerZone = ({event}) => {
  const [showContent, setShowContent] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [showUpdateConfirm, setShowUpdateConfirm] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [confirmText, setConfirmText] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const navigate = useNavigate();

  function handleRemove() {
    setShowDeleteConfirm(false);
    deleteEvent(event?.id)
      .then((response) => {
        if (response.ok) {
          setSuccess("Smazáno");
        } else {
          setError(handleErrorSubmit(response, "Nezdařilo se smazat"));
        }
      });
  }

  function handleForceUpdate() {
    setShowUpdateConfirm(false);
    setProcessing(true);
    forceDataUpdate(event?.id)
      .then((response) => {
        if (response.ok) {
          setSuccess("Data akce aktualizovány");
        } else {
          setError(handleErrorSubmit(response, "Nezdařilo se aktualizovat"));
        }
        setProcessing(false);
      });
  }

  return (
    <div>
      <hr/>
      <div>
        <h4 className="pointing" style={{color: "red"}} onClick={() => setShowContent(!showContent)}>
          <FontAwesomeIcon icon={showContent ? faAngleUp : faAngleDown}/> Danger zone</h4>
        {showContent && <Row>
          <Col className="text-end">
            {event.type === 0 ?
              <Button variant="warning" onClick={() => setShowUpdateConfirm(true)}>Aktualizovat všechna data</Button> :
              <Button variant="danger" onClick={() => setShowDeleteConfirm(true)}><FontAwesomeIcon icon={faTrash}/> Smazat akci</Button>
            }
          </Col>
        </Row>}
      </div>
      <Modal show={showDeleteConfirm} onHide={() => setShowDeleteConfirm(false)}>
        <Modal.Header closeButton>
          <FontAwesomeIcon icon={faQuestionCircle} size='3x' bounce/> &nbsp;
          <Modal.Title>Skutečně smazat akci?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="prevent-select">Název: <b>{event?.title}</b></p>
          <p>Datum: {parseDate(event?.date)} {event?.date_to ? " – " + parseDate(event?.date_to) : ""}</p>
          <hr/>
          <p>Spolu s akcí dojde ke smazání <b style={{color: "red"}}>všech</b> propojených entit - přihlášek, pohledávek, vozidel apod.</p>
          <p>Tuto akci <b style={{color: "red"}}>nelze vrátit zpět</b>!</p>
          <FormField label={"Pro potvrzení opiš přesný název akce"} value={confirmText} setValue={setConfirmText} controlId={"confirmText"} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteConfirm(false)}>Zavřít</Button>
          <Button variant="danger" disabled={event?.title !== confirmText} onClick={handleRemove}>Smazat</Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showUpdateConfirm} onHide={() => setShowUpdateConfirm(false)}>
        <Modal.Header closeButton>
          <FontAwesomeIcon icon={faQuestionCircle} size='3x' bounce/> &nbsp;
          <Modal.Title>Skutečně aktualizovat data akce?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Název: <b>{event?.title}</b></p>
          <p>U akce budou aktualizována všechna data akce - přihlášky, informace o vkladech, rankingové výsledky... Provedení operace je nevratné, nicméně nedojde k poškození jakýchkoliv dat akce.</p>
          <p>Tato operace chvíli trvá, vyčkej na potvrzení dokončení.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowUpdateConfirm(false)}>Zavřít</Button>
          <Button variant="primary" onClick={handleForceUpdate}>Potvrdit</Button>
        </Modal.Footer>
      </Modal>
      <LoadingOverlay loading={processing} />
      <ErrorAlert error={error} handleClose={()=>setError("")}/>
      <SuccessAlert title={success} handleClose={()=> {
        setSuccess("");
        navigate("/");
      }}/>
    </div>
  );
}

export default EventDangerZone;