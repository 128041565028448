import Table from "react-bootstrap/Table";
import React, { useState, useEffect } from 'react'
import {getClubStatistics} from "../../dao";
import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faChartSimple, faMedal} from '@fortawesome/free-solid-svg-icons';
import Col from "react-bootstrap/Col";
import {handleErrorLoading, setBrowserTabText, YearOptions} from "../../helpers/Functions";
import PageContainer from "../../layout/PageContainer";
import SearchBar from "../../components/form/SearchBar";
import Form from "react-bootstrap/Form";
import Loader from "../../components/overlays/Loader";
import ErrorAlertFullscreen from "../../components/overlays/ErrorAlertFullscreen";
import {StatsMetrics} from "../../helpers/Constants";
import FormSelectDict from "../../components/form/FormSelectDict";
import FormSelectArray from "../../components/form/FormSelectArray";

const ClubStats = () => {
  const [loading, setLoading] = useState(true);
  const [errorRemote, setErrorRemote] = useState("");
  const [year, setYear] = useState((new Date()).getFullYear());
  const [data, setData] = useState([]);
  const [metricType, setMetricType] = useState("UV");
  const [metricValue, setMetricValue] = useState(0);
  useEffect(() => {
    setLoading(true);
    getClubStatistics(year)
      .then((response) => {
        if (!response.ok) {
          setErrorRemote(handleErrorLoading(response));
          return {};
        }
        return response.json();
      })
      .then((response) => {
        setData(response?.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setErrorRemote("Připojení neúspěšné...");
      })
  }, [year]);

  if (errorRemote !== "")
    return <ErrorAlertFullscreen error={errorRemote}/>;
  if (loading)
    return <Loader loading={loading}/>;

  setBrowserTabText('Klubové statistiky')
  return (
    <PageContainer>
      <h1>Klubové statistiky</h1>
      <Row className="align-items-center">
        <Col>
          <Form.Group className="mb-2" controlId="year">
            <Row className="align-items-baseline">
              <Col md>
                <FormSelectArray label={"Rok"} inline={true} size={"sm"} value={year} setValue={setYear} options={YearOptions()} className={"mb-2"} controlId={"year"} />
              </Col>
              <Col md>
                <Row className="align-items-center">
                  <Col>
                    <FormSelectDict label={""} value={metricType} setValue={setMetricType} options={StatsMetrics} size={"sm"} controlId={"metricType"} />
                  </Col>
                  <Col xs="auto" className="mb-2">{metricValue !== 10 && "  "}{metricValue}{(metricType === "UV" || metricType === "UM") && "0 %"}</Col>
                  <Col>
                    <Form.Group className="mb-2 pt-1" controlId="metricValue">
                      <Form.Range value={metricValue * 10} onChange={(e) => setMetricValue(Math.floor(Number(e.target.value) / 10))} />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form.Group>
        </Col>
      </Row>
      <MemberTable />
    </PageContainer>
  );

  function MemberTable() {
    const [filteredPeople, setFilteredPeople] = useState(data);

    const rows = [];

    for (const i in filteredPeople) {
      const element = filteredPeople[i];
      switch (metricType) {
        case "UV":
          if (metricValue > 0) {
            if (element.stats.presence == null)
              continue;
            if (element.stats.presence < (metricValue * 10))
              continue;
          }
          break;
        case "UM":
          if (element.stats.presence != null) {
            if (element.stats.presence > (metricValue * 10))
              continue;
          }
          break;
        case "OV":
          if (element.stats.official < metricValue)
            continue;
          break;
        case "OM":
          if (element.stats.official > metricValue)
            continue;
          break;
        case "DV":
          if (element.stats.not_started < metricValue)
            continue;
          break;
        case "DM":
          if (element.stats.not_started > metricValue)
            continue;
          break;
        default:
          break;
      }

      rows.push(<tr key={element.id}>
        <td><Link to={`/ucet/${element.id}`}>{element.reg_number}</Link></td>
        <td><Link to={`/ucet/${element.id}/zavody/`}>{element.full_name}</Link></td>
        <td className="td-sep"></td>
        <td>{element.stats.races}</td>
        <td>{element.stats.official}</td>
        <td>{element.stats.unofficial}</td>
        <td className="td-sep"></td>
        <td>{element.stats.presence != null && element.stats.presence + " %"}</td>
        <td>{element.stats.not_started}</td>
        <td className="td-sep"></td>
        <td>{element.stats.club}</td>
      </tr>);
    }

    return (<>
    <SearchBar data={data} setFilteredPeople={setFilteredPeople}/>
      <Table striped bordered responsive>
        <thead>
        <tr>
          <th>Reg. <FontAwesomeIcon icon={faChartSimple}/></th>
          <th>Jméno <FontAwesomeIcon icon={faMedal}/></th>
          <th className="td-sep"></th>
          <th>Závody</th>
          <th>OF</th>
          <th>OST</th>
          <th className="td-sep"></th>
          <th>Účast</th>
          <th>DNS</th>
          <th className="td-sep"></th>
          <th>Klub</th>
        </tr>
        </thead>
        <tbody>
        {rows}
        </tbody>
      </Table>
      <p><i>Filtru odpovídá <b>{rows.length}</b> záznamů</i></p>
    </>)
  }
};


export default ClubStats;