import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleLeft, faAngleRight, faAnglesLeft, faAnglesRight} from "@fortawesome/free-solid-svg-icons";
import React from "react";

/**
 * page - current page number from 1 till x
 * setPage - setter for page
 * rowsPerPage - number of items on single page
 * rowCount - total number of items
 */
const PageSwitch = ({page, setPage, rowsPerPage, rowCount}) => {
  const lastPageNumber = Math.ceil(rowCount / rowsPerPage);
  if (lastPageNumber <= 1)
    return;
  return (
    <Row className="mb-2">
      <Col lg={3} md={2} sm={1} xs={0}/>
      <Col className="text-center">
        <Button variant="outline-primary" onClick={() => {setPage(1)}} disabled={page <= 1}><FontAwesomeIcon icon={faAnglesLeft}/></Button>
        <Button className="ms-1 me-1" onClick={() => {if (page > 1) setPage(page - 1)}} disabled={page <= 1}><FontAwesomeIcon icon={faAngleLeft}/></Button>
        <Button variant="secondary">{rowCount < rowsPerPage ? 1 : page}</Button>
        <Button className="ms-1 me-1" onClick={() => {if (page < lastPageNumber) setPage(page + 1)}} disabled={page >= lastPageNumber}><FontAwesomeIcon icon={faAngleRight}/></Button>
        <Button variant="outline-primary" onClick={() => {setPage(lastPageNumber)}} disabled={page >= lastPageNumber}><FontAwesomeIcon icon={faAnglesRight}/></Button>
      </Col>
      <Col lg={3} md={2} sm={1} xs={0}/>
    </Row>
  )
}

export default PageSwitch;