import React, {useEffect, useState} from "react";
import {getMyBugReports} from "../../dao";
import {handleErrorLoading, parseDateTime, setBrowserTabText} from "../../helpers/Functions";
import ErrorAlertFullscreen from "../../components/overlays/ErrorAlertFullscreen";
import PageContainer from "../../layout/PageContainer";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import AutoFormat from "../../components/visual/AutoFormat";
import WarningCard from "../../components/overlays/WarningCard";
import Card from "react-bootstrap/Card";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faComments} from "@fortawesome/free-solid-svg-icons";
import Loader from "../../components/overlays/Loader";


const MyBugReports = () => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [errorRemote, setErrorRemote] = useState("");

  useEffect(() => {
    getMyBugReports()
      .then((response) => {
        if (!response.ok) {
          setErrorRemote(handleErrorLoading(response));
          return {records: []};
        }
        return response.json();
      })
      .then((response) => {
        setData(response);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setErrorRemote("Připojení neúspěšné...");
      })
  }, []);

  if (errorRemote !== "")
    return <ErrorAlertFullscreen error={errorRemote} />;
  if (loading)
    return <Loader />;

  setBrowserTabText('Moje nahlášení');
  return (
    <PageContainer>
      <h1>Moje nahlášení</h1>
      <br/>
      <ReportsSection />
    </PageContainer>
  )

  function ReportsSection() {
    const rows = [];
    for (const i in data.records) {
      const record = data.records[i];
      rows.push(
        <div key={record.id}>
          <Row className="mb-1">
            <Col>
              <Card>
                <Card.Body>
                  <Row className="mb-1">
                    <Col><a href={`/bug/${record.id}`}><h4>{record.title}</h4></a></Col>
                    <Col xs="auto"><FontAwesomeIcon icon={faComments}/> {record.comments.length}</Col>
                  </Row>
                  <Row className="mb-2">
                    <Col><AutoFormat text={record.detail}/></Col>
                  </Row>
                  <Row>
                    <Col>{parseDateTime(record.timestamp)}</Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      );
    }

    if (rows.length === 0)
      return <WarningCard text={"Žádná nahlášení"}/>
    return rows;
  }
}

export default MyBugReports;