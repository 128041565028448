import Table from "react-bootstrap/Table";
import React, {useState, useEffect, useRef} from 'react'
import {getClubBillingDetail, getPeriods} from "../../dao";
import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faArrowsDownToPeople, faCircleUser, faRotate} from '@fortawesome/free-solid-svg-icons';
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import {
  YearOptions, handleErrorLoading, parseDateBreakYear, setBrowserTabText, IndicesArray,
} from "../../helpers/Functions";
import Card from "react-bootstrap/Card";
import {SelectOptionsArray} from "../../components/form/SelectOptions";
import Button from "react-bootstrap/Button";
import ErrorAlertFullscreen from "../../components/overlays/ErrorAlertFullscreen";
import ColorChangingText from "../../components/visual/ColorChangingText";
import {handleCsvExport} from "../../helpers/Exports";
import {API_URL, MonthOptions} from "../../helpers/Constants";
import PageContainer from "../../layout/PageContainer";
import LoadingIcon from "../../components/overlays/LoadingIcon";
import SearchBar from "../../components/form/SearchBar";
import Loader from "../../components/overlays/Loader";
import ExportOnBackground from "../../components/overlays/ExportOnBackground";
import PageHeading from "../../layout/PageHeading";
import FormSelectDict from "../../components/form/FormSelectDict";
import FormSelectArray from "../../components/form/FormSelectArray";
import {labels} from "../../themeLabels";

const ClubBillingDetail = () => {
  const [loading, setLoading] = useState(true);
  const [loadingTable, setLoadingTable] = useState(false);
  const [errorRemote, setErrorRemote] = useState("");
  const [dataEvents, setDataEvents] = useState({data: []});
  const [dataMembers, setDataMembers] = useState({data: []});
  const [billingYears, setBillingYears] = useState({});
  const [year, setYear] = useState((new Date()).getFullYear());
  const [month, setMonth] = useState((new Date()).getMonth() + 1);
  const [period, setPeriod] = useState(1);
  const [eventMode, setEventMode] = useState(true);
  const [periodCount, setPeriodCount] = useState(0);
  const [isExporting, setIsExporting] = useState(false);
  const [showIsExporting, setShowIsExporting] = useState(false);
  const [dataEventsLoaded, setDataEventsLoaded] = useState(false);
  const [dataMembersLoaded, setDataMembersLoaded] = useState(false);

  useEffect(() => {
    setLoading(true);
    getPeriods()
      .then((response) => {
        if (!response.ok) {
          setErrorRemote(handleErrorLoading(response));
          return []
        }
        return response.json()
      })
      .then((response) => {
        setBillingYears(response);
        setPeriodCount(response?.years[(new Date()).getFullYear()]);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setErrorRemote("Připojení neúspěšné...");
      })
  }, []);

  function loadData() {
    setLoadingTable(true);
    getClubBillingDetail(eventMode, year, month, period)
      .then((response) => {
        if (response.ok)
          return response.json();
        setErrorRemote(handleErrorLoading(response));
      })
      .then((response) => {
        setLoadingTable(false);
        if (eventMode) {
          setDataEvents(response);
          setDataEventsLoaded(true);
        } else {
          setDataMembers(response);
          setDataMembersLoaded(true);
        }
      })
      .catch((err) => {
        console.log(err);
        setErrorRemote("Připojení neúspěšné...");
      })
  }

  const TableContentInfo = ({data}) => {
    return <Row className="mb-1">
      <Col>Zobrazeno: <b>{data?.header?.shown}</b></Col>
      <Col>Aktuální: {data?.header?.current}</Col>
    </Row>
  }

  if (errorRemote !== "")
    return <ErrorAlertFullscreen error={errorRemote}/>;
  if (loading) {
    return <Loader loading={loading}/>;
  }

  setBrowserTabText('Účetnictví – detail')
  return (
    <PageContainer>
      <PageHeading heading={"Účetnictví – detail období"} link={"/ucetnictvi/"} label={"Účetnictví"} secondLink={"/ucetnictvi/obdobi"} secondLabel={"Správa období"}/>
      <TableControlPanel/>
      {eventMode ? <EventTable /> : <MemberTable />}
      <ExportOnBackground
        show={showIsExporting && isExporting}
        onHide={() => {setShowIsExporting(false)}}
      />
    </PageContainer>
  );

  function TableControlPanel() {
    const downloadLinkRef = useRef(null);

    return (<Card className="mb-3">
      <Card.Body>
        <Row>
          <Col>
            <Form.Group controlId="mode">
              <Form.Label size="sm">Zobrazení:</Form.Label>
              <Form.Select className="mb-2" size="sm" onChange={(e) => {
                setEventMode(e.target.value === '1');
              }} value={+eventMode}>
                <option value={1}>Akce</option>
                <option value={0}>Členové</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="year">
              <Form.Label size="sm">Rok:</Form.Label>
              <Form.Select className="mb-2" size="sm" onChange={(e) => {
                setYear(e.target.value); setPeriodCount(billingYears.years[e.target.value]); setPeriod(1);
              }} value={year}>
                <SelectOptionsArray options={YearOptions()}/>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col>
            {eventMode ?
              <FormSelectDict label={"Měsíc"} size="sm" value={month} setValue={setMonth} options={MonthOptions} className={"mb-2"} controlId={"month"} /> :
              <FormSelectArray label={"Část"} size="sm" value={period} setValue={setPeriod} options={IndicesArray(periodCount)} className={"mb-2"} controlId={"period"} />
            }
          </Col>
        </Row>
        <Row>
          <Col>
            <a href="#" ref={downloadLinkRef} style={{display: 'none'}} download="file.csv">Download</a>
            {eventMode ? <Button variant="link" disabled={isExporting} onClick={() => {
              handleCsvExport(API_URL + "export-billing-detail?mode=event&year=" + year + "&month=" + month, 'vyuctovani_akce_' + year + '_' + month + '.csv', setIsExporting);
              setShowIsExporting(true);
            }}>Exportovat</Button> : <>
              <Button variant="link" disabled={isExporting} onClick={() => {
                const currentTimestamp = (new Date()).toISOString().split('.')[0].replace('T', '_');
                handleCsvExport(API_URL + "export-billing-detail?mode=member&year=" + year + "&period=" + period, 'vyuctovani_clenove_' + year + 'P' + period + '_' + currentTimestamp + '.csv', setIsExporting);
                setShowIsExporting(true);
              }}>Export souhrn</Button>
              <Button variant="link" disabled={isExporting} onClick={() => {
                const currentTimestamp = (new Date()).toISOString().split('.')[0].replace('T', '_');
                handleCsvExport(API_URL + "export-billing-detail?mode=member&split=1&year=" + year + "&period=" + period, 'vyuctovani_clenove_rozpad_' + year + 'P' + period + '_' + currentTimestamp + '.csv', setIsExporting);
                setShowIsExporting(true);
              }}>Export rozpad</Button>
            </>}
            {isExporting && <FontAwesomeIcon icon={faRotate} spin/>}
          </Col>
          <Col className="text-end">
            <Button className="mb-0" disabled={loadingTable} onClick={loadData}>Načíst</Button>
          </Col>
        </Row>
      </Card.Body>
    </Card>)
  }

  function NoData () {
    if ((dataEventsLoaded && eventMode) || (dataMembersLoaded && !eventMode))
      return <p><i>Žádné záznamy v historii dle filtru</i></p>;
    return <Row><Col className="text-center"><Button className="mt-3" variant="outline-primary" onClick={loadData}>Načíst záznamy dle parametrů</Button></Col></Row>

  }

  function EventTable() {
    if (loadingTable)
      return <LoadingIcon />;

    const rows = [];
    for(const i in dataEvents.data) {
      const event = dataEvents.data[i];
      rows.push(<tr key={event.id}>
        <td>{parseDateBreakYear(event.date)}</td>
        <td><Link to={`/akce/${event.id}/pohledavky`}>{event.title}</Link></td>
        <td>{event.billing_mode}</td>
        <td>{event.discipline}</td>
        <td>{event.organiser}</td>
        <td><b>{event.sum}</b></td>
        <td>{event.fee_sum}</td>
        <td>{event.services_sum}</td>
        <td><i>{event.flat_rates}</i></td>
      </tr>);
    }
    if (rows.length < 1){
      return <NoData />
    }

    return (<>
        <TableContentInfo data={dataEvents}/>
        <Table striped bordered hover={labels.CondElemTableHover} responsive>
          <thead>
          <tr>
            <th>Datum</th>
            <th>Název</th>
            <th title="režim vyúčtování">R</th>
            <th>Dis.</th>
            <th>Poř.</th>
            <th>Σ</th>
            <th title="Σ startovné">Start.</th>
            <th title="Σ služby">Služ.</th>
            <th title="Klubem zajištěná doprava a ubytování - placeno členy">Kl. D&U</th>
          </tr>
          </thead>
          <tbody>
          {rows}
          </tbody>
        </Table>
        <h4>Režimy vyúčtování (R):</h4>
        <p className="mb-0">0 – klasický (pouze P)</p>
        <p className="mb-0">1 – placeno přímo členy</p>
        <p className="mb-0">2 – placeno klubem</p>
        <p className="mb-0">3 – pořádáno klubem</p>
      </>
    );
  }

  function MemberTable() {
    const [filteredPeople, setFilteredPeople] = useState(dataMembers.data);
    if (loadingTable)
      return <LoadingIcon />;

    const TableRow = ({element}) => {
      let style = {paddingBottom: 2, paddingTop: 2}
      if (element?.wardship?.guardian != null)
        style.fontStyle = 'italic';
      const billElement = element?.bill ? element.bill : element;  // made for backwards compatibility

      return <>
        <tr>
          <td rowSpan={2} style={style}><Link to={`/ucet/${element.id}/vyuctovani/detail/`} target="_blank">{element.full_name}</Link>
            {element?.wardship?.wards && <FontAwesomeIcon icon={faArrowsDownToPeople} style={{marginLeft: "5px"}} title={element?.wardship?.wards?.string}/>}
            {element?.wardship?.guardian && <FontAwesomeIcon icon={faCircleUser} style={{marginLeft: "5px"}} color="gray" title={element?.wardship?.guardian?.string}/>}
            <br/><i>{element.reg_number} – {billElement.type}</i></td>
          <td style={style}>{billElement.sum_oris}</td>
          <td style={style}><ColorChangingText value={billElement.sum_dns_fines} border={1000}/></td>
          <td style={style}><ColorChangingText value={billElement.sum_late_term} border={1000}/></td>
          <td style={style}>{billElement.sum_services}</td>
          <td style={style}>{billElement.sum_camps}</td>
          <td rowSpan={2} style={style}>{billElement.expenses}</td>
        </tr>
        <tr>
          <td colSpan={4} align="right" style={style}>{billElement.sum_oris + billElement.sum_dns_fines + billElement.sum_late_term + billElement.sum_services}</td>
          <td align="right" style={style}>{billElement.sum_club}</td>
        </tr>
      </>
    }

    const rows = [];
    for (const i in filteredPeople) {
      const element = filteredPeople[i];
      rows.push(<TableRow element={element} key={element.id}/>);
    }

    if (rows.length < 1){
      return <NoData />
    }

    return (<>
      <TableContentInfo data={dataMembers}/>
      <SearchBar data={dataMembers.data} setFilteredPeople={setFilteredPeople} />
      <Table bordered hover responsive>
        <thead>
        <tr>
          <th rowSpan={2}>Osoba</th>
          <th>Start.</th>
          <th>DNS</th>
          <th>T2/3</th>
          <th>Služ.</th>
          <th>Sous.</th>
          <th rowSpan={2}>Výdaje</th>
        </tr>
        <tr>
          <th colSpan={4} style={{textAlign: "right"}}>Σ Oris akce</th>
          <th style={{textAlign: "right"}}>Klub</th>
        </tr>
        </thead>
        <tbody>
        {rows}
        </tbody>
      </Table>
    </>)
  }
};


export default ClubBillingDetail;